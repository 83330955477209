<template>
	<b-card class="quoterCard additional-charges">
    <div class="button-with-alert w-100">
      <span :title="$t('Subir archivo')" v-b-tooltip.bottom.hover class="float-right"><b-button variant="warning" @click="openModal"> <feather-icon icon="UploadFileIcon" /> Nuevo cargo adicional </b-button></span>
    </div>
    <div class="w-100 d-block"> 
      <form-render
        ref="formRenderAdditionalCharges"
        :key="keyAdditionalCharges"
        :fields="fields"
        :form.sync="form"
        @send="getList"
        :buttonSend="buttonSend"
        containerButtonsClass="col-sm-12 col-md-3 col-lg-3 mt-2 mb-4 pl-0 pr-0"
      >
        <template #buttons>
          <b-button variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1 mr-1" @click="cleanFilter">
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </template>
      </form-render>
    </div>
		<div v-if="loading.additionalCharges && showTable">
			<b-skeleton height="40px" width="`calc(100vh - 2.5rem)`" class="mt-1 mb-1" />
			<div class="table-skeleton-tableQuoter">
				<b-skeleton-table :rows="5" :columns="6" :table-props="{ }" />
			</div>
		</div>
		<additional-charges-list
			v-if="!loading.additionalCharges && showTable"
			:rows="rows"
			:form="form"
			@activeLoading="activeLoading"
			@getList="getList"
		></additional-charges-list>
		<pagination :pagination="pagination" v-if="!loading.additionalCharges && showTable" />
		<modal-import-additional-charges @getList="getList" ref="ModalImportAdditionalCharges"></modal-import-additional-charges>
	</b-card>
</template>
<script>
import { mapGetters } from 'vuex'
import AdditionalChargesList from './components/AdditionalChargesList.vue'
import ModalImportAdditionalCharges from './components/ModalImportAdditionalCharges.vue'

export default {
  components: { AdditionalChargesList, ModalImportAdditionalCharges },
  name: 'PreferredClient',
  data () {
    return {
      keyAdditionalCharges: 0,
      form: {},
      fields: [],
      buttonSend: { icon: 'SearchIcon', title: 'Buscar', color: 'warning', disabled: false },
      loading: {
        countries: false,
        carriers: false,
        additionalCharges: true
      },
      showTable: false,
      rows: [],
      currencySimbol: {
        CL: '$',
        CO: '$',
        PE: 'S/',
        MX: '$'
      },
      pagination: {
        page: 1,
        total: 20,
        limit: 20
      }
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountriesOriginsModal',
      carriers: 'getCarriersOriginsModal',
      services: 'getServicesOrigins',
      additionaCharges: 'getAdditionalCharges',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    countries() {
      this.setCountries()
    },
    carriers() {
      this.setCarriers()
    },
    services() {
      this.setServices()
    },
    additionaCharges() {
      this.pagination.page = this.additionaCharges.meta.current_page
      this.pagination.total = this.additionaCharges.meta.total_rows
      this.additionaCharges.rows.map(el => {
        Object.keys(el).map(key => {
          if (typeof el[key] === 'object' && (el[key]?.is_active === undefined || el[key]?.is_active === false || !(el[key]?.is_active))) {
            el[key][key] = 'No'
            el[key].disabled = true
          } else if (typeof el[key] === 'object') {
            el[key][key] = 'Si'
            el[key].disabled = false
          }
          if (typeof el[key] === 'object' && (el[key]?.is_percentage === undefined || el[key]?.is_percentage === false || !(el[key]?.is_percentage))) {
            el[key].is_percentage = '$'
          } else if (typeof el[key] === 'object') {
            el[key].is_percentage = '%'
          }
          if (typeof el[key] === 'object' && el[key]?.amount) {
            el[key].amount_not_format = parseFloat(el[key].amount?.replace(/[^0-9.-]/g, ''))
          }
        })
      })
      this.rows = this.additionaCharges.rows
    },
    'pagination.page' () {
      this.getList()
    },
    generalLoading: {
      handler () {
        this.loading.countries = !!this.generalLoading.getCountriesOriginsModal
        this.loading.carriers = !!this.generalLoading.getCarriersOriginsModal
        this.loading.additionalCharges = !!this.generalLoading.getAdditionalCharges
      },
      deep: true
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fields = [
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'country', disabled: false, label: 'País', containerClass: 'col-sm-12 col-md-3 col-lg-3 fieldSelect', change: this.changeCountry, addFlags: true, validation: 'required', sort: true },
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'carrier', label: 'Courier', containerClass: 'col-sm-12 col-md-3 col-lg-3', change: this.changeCarriers, dependency: 'country', sort: true},
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'service', label: 'Servicio', containerClass: 'col-sm-12 col-md-3 col-lg-3', change: this.changeService, dependency: 'carrier', sort: true}
      ]
      this.$store.dispatch('fetchService', { name: 'getCountriesOriginsModal' })
    },
    setCountries() {
      const index = this.fields.findIndex(el => el.name === 'country')
      this.fields[index].options = this.countries.rows.map(value => ({id: value.id, code: value.code, text: value.name}))
      const resetFields = ['country', 'carrier', 'service']
      resetFields.forEach(el => this.setSkeletonField(el, false))
    },
    setCarriers() {
      const index = this.fields.findIndex(el => el.name === 'carrier')
      if (index !== -1) this.fields[index].options = this.carriers.rows
      const resetFields = ['carrier', 'service']
      resetFields.forEach(el => this.setSkeletonField(el, false))
    },
    setServices() {
      const index = this.fields.findIndex(el => el.name === 'service')
      if (index !== -1) this.fields[index].options = this.services.rows
      this.setSkeletonField('service', false)
    },
    setSkeletonField (name, bool) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].useSlot = bool
      this.fields[index].useSkeleton = bool
      this.fields[index].useLabel = bool
    },
    changeCountry(name, value) {
      if (value) {
        this.setSkeletonField('carrier', true)
        this.setSkeletonField('service', true)
        this.$store.dispatch('fetchService', { name: 'getCarriersOriginsModal', params: { country_id: value.id } })
      }
      this.showTable = false
      this.changeCarriers('carrier', null)
    },
    changeCarriers(name, value) {
      if (!!value) {
        const params = {
          country_id: this.form.country.id,
          carrier_id: value.id
        }
        this.setSkeletonField('service', true)
        this.$store.dispatch('fetchService', { name: 'getServicesOrigins', params })
      } else {
        delete this.form.carrier
      }
      this.changeService('service', null)
      this.showTable = false
    },
    changeService(name, value) {
      if (!value) {
        delete this.form.service
      }
      this.showTable = false
    },
    getList() {
      this.showTable = true
      const queryParams = {
        country_id: this.form.country?.id,
        page: this.pagination.page
      }
      if (this.form.carrier?.id) queryParams.carrier_id = this.form.carrier?.id
      if (this.form.service?.id) queryParams.service_id = this.form.service?.id
      this.$store.dispatch('fetchService', { name: 'getAdditionalCharges', queryParams })
    },
    cleanFilter() {
      this.showTable = false
      this.form = {}
    },
    openModal() {
      this.$bvModal.show('ModalImportAdditionalCharges')
    },
    activeLoading() {
      this.getList()
      this.generateAlertSuccess()
    },
    generateAlertSuccess() {
      this.$newSuccess('¡Cambio realizado con éxito!')
    }
  }
}
</script>
<style lang="scss">
.additional-charges {
  padding: 0 !important;
}
</style>
