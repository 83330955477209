<template>
	<div>
		<b-modal
			centered
			id="ModalImportAdditionalCharges"
			size="lg"
			modal-class="custom-dialog dialog-600"
			title="Subir Cargos"
			@ok="ok"
			:ok-disabled="loading.updatingData"
			:ok-title="this.loading.updatingData ? 'Actualizando...' : 'Actualizar'"
			ok-variant="outline-primary"
			ok-only
			no-close-on-esc
			no-close-on-backdrop
			@close="close"
		>
			<form-render
				ref="formRenderImportAdditionalCharges"
				:key="keyImportAdditionalCharges"
				:fields="fields"
				:form.sync="form"
				disabledAll="loading.updatingData"
			>
				<template #info>
					<p>Esta acción puede tardar unos minutos</p>
					<div>
						<p>
							Archivo de importación (<b-button
								class="p-0"
								variant="link"
								size="sm"
								href="https://storage.googleapis.com/formato-archivos-subida-akatsuki/additional-charges-massive-managing-format.xlsx"
								>Descargar Formato</b-button
							>)
						</p>
					</div>
				</template>
				<template #file>
					<b-input-group class="file-form-area-collection d-flex">
						<b-form-file
							type="text"
							:class="['hide-button-browse-collection file-area-collection-collection']"
							name="fileCollection"
							v-model="file"
							@input="changeFile"
							drop-placeholder="Suelta el archivo aquí"
							accept=".xlsx"
							label=""
							ref="file-drop-area"
						>
							<template #placeholder>
								<label class="w-100 text-center cursor-pointer m-0 d-flex justify-content-center">
									<feather-icon icon="UploadCloudIcon" size="30" class="m-0 mr-1" />
									<p class="mb-0 align-self-center p-0">Subir archivo</p>
								</label>
							</template>
							<template #file-name>
								<div class="d-flex justify-content-between align-items-center bg-gray-input text-file-collection" v-if="file">
									<span class="text-truncate">{{ file.name }}</span>
								</div>
								<label class="w-100 text-center cursor-pointer m-0 d-flex justify-content-center" v-if="!file">
									<feather-icon icon="UploadCloudIcon" size="30" class="m-0 mr-1" />
									<p class="mb-0 align-self-center p-0">Subir archivo</p>
								</label>
							</template>
						</b-form-file>
						<b-input-group-append class="remove-botton-collection" v-if="file">
							<b-button variant="outline" class="border-0" size="sm" @click="file = null;">
								<feather-icon icon="XIcon" size="24" />
							</b-button>
						</b-input-group-append>
					</b-input-group>
					<b-form-invalid-feedback :state="!stateFile" class="pl-1 mt-1">
						Este campo es requerido y solo acepta archivos tipo .xslx no mayores a 2MB
					</b-form-invalid-feedback>
				</template>
			</form-render>
		</b-modal>
	</div>
</template>
<script>
import BaseServices from '@/store/services/index'

export default {
  name: 'ModalImportAdditionalCharges',
  props: ['getList'],
  data() {
    return {
      keyImportAdditionalCharges: 0,
      file: null,
      form: {},
      fields: [],
      stateFile: false,
      baseService: new BaseServices(this),
      loading: {
        updatingData: false
      }
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [
        {name: 'info', clearable: true, containerClass: 'container-info col-sm-12 col-md-12 col-lg-12', useSlot: true},
        {name: 'file', clearable: true, containerClass: 'container-info col-sm-12 col-md-12 col-lg-12', useSlot: true}
      ]
    },
    async ok(e) {
      e.preventDefault()
      this.stateFile = !this.file
      const html = '<p>Si existen registros duplicados, se van a sobre-escribir.</p>'
      const title = 'Advertencia'
      this.$confirm(html, () => this.sendForm(), title, 'Confirmar', '', '', '', '560px')
    },
    sendForm() {
      const data = {
        file: this.file
      }
      this.loading.updatingData = true
      this.baseService.callUploadFile('postAdditionalCharges', data)
        .then((resp) => {
          this.$success(`¡${resp.message}!`, '¡Éxito!')
          this.close()
          this.$emit('getList')
        })
        .catch((err) => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err.message}))
        })
        .finally(() => {
          this.loading.updatingData = false
        })
    },
    changeFile(val) {
      this.validateFile(val)
    },
    validateFile(val) {
      if (val?.name.includes('xlsx') && val.size < 2 * 1024 * 1024) {
        this.stateFile = false
      } else {
        this.file = null
        this.stateFile = true
      }
    },
    removeFile() {
      this.file = null
    },
    close() {
      this.removeFile()
      this.$bvModal.hide('ModalImportAdditionalCharges')
    }
  }
}
</script>

<style lang="scss">
.hide-button-browse-collection > label {
  &::after {
    display: none !important;
    cursor: pointer !important;
  }
}
.file-form-area-collection > .file-area-collection {
  height: 3.4rem !important;
}
.file-form-area-collection > .hide-button-browse-collection > label {
  cursor: pointer !important;
  height: 3.4rem !important;
  align-content: center;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 10 !important;
  width: 100% !important;
}
.bg-gray-input {
  background-color: #f7f7f9 !important;
}
.remove-botton-collection {
  background-color: #f7f7f9 !important;
  position: absolute;
  right: 1px;
  z-index: 10;
  top: 0.4rem;
  right: 1rem;
  border-radius: 5px;
}
.text-file-collection {
  border-radius: 5px;
  padding: 0.6rem;
}
</style>
