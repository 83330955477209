<template>
  <div>
    <b-modal 
      centered 
      id="ModalCoverages" 
      size="lg"
      modal-class="custom-dialog dialog-700"
      hide-footer
      :title="$t(data.isCopy ? 'Tarifa Programada' : 'Versión Programada')"
      @close="close"
      no-close-on-esc
      no-close-on-backdrop
    >
      <b-table :items="rows" :fields="schema" responsive="sm" ref="tableModalCoverages" class="table_modal_coverages" ></b-table>
      <form-render :form.sync="form" :fields="fields" ref="formRenderCovaerages" containerButtonsClass="col-sm-4 mt-2" :key="keyFormRender" >
        <template #msgAditionalInfo v-if="!data.isCopy">
          <feather-icon icon="InfoIcon" size="16" class="icon-aditional-info"/>
          <span>La acción que se ejecutará de forma automática al subir un archivo de información adicional es la de "Reemplazar".</span>
        </template>
        <template #msgOptions v-if="!data.isCopy">
          <ul class="msg-options"><li>Seleccione la acción que desea tomar respecto a la tarifa previamente subida:</li></ul>
        </template>
        <template #infoFiles>
          <div class="msg-infoFiles"><feather-icon icon="InfoIcon" class="text-primary ml-1" size="20" /> <span class="mr-1 ml-0">{{ data.isCopy ? msgCopy : msgStandard }}</span></div>
        </template>
      </form-render>
      <div class="footer-modal-coverage">
        <b-button class="float-right mt-1 mb-1" variant="outline-success" @click="ok" :disabled="loading.updatingData">
          <span v-if="!loading.updatingData" class="mb-1">Confirmar</span>
          <span class="mt-1 p-2" v-if="loading.updatingData"><b-spinner small  /> </span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BaseServices from '@/store/services/index'
import { mapGetters } from 'vuex'
import { utcToZonedTime } from 'date-fns-tz'
import addDays from 'date-fns/addDays'

export default {
  name: 'ModalCoverages',
  props: ['data', 'keyModalCoverages', 'hideModal'],
  data() {
    return {
      schema: [],
      rows: [],
      tableModalCoverages: 0,
      form: {},
      fields: [],
      keyFormRender: 0,
      baseService: new BaseServices(this),
      loading: { updatingData: false },
      timeZones: {
        CL: 'America/Santiago',
        MX: 'America/Bahia_Banderas',
        CO: 'America/Bogota',
        PE :'America/Lima',
        AR: 'America/Argentina/Buenos_Aires',
        EC: 'America/Guayaquil'
      },
      msgCopy: 'A continuación puedes cargar lo que deseas sobrescribir de la copia de la tarifa actual.',
      msgStandard: 'A continuación, podrás subir un archivo nuevo o modificado.',
      line: {name: 'line', containerClass: 'col-sm-12 col-md-12 col-lg-12 mt-3 mb-3 container-info line', useSlot: true},
      separation: {name: 'separation', containerClass: 'col-sm-12 col-md-12 col-lg-12', useSlot: true},
      ApplyDiscountOrIncrease: {name: 'applyDiscountOrIncrease', containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info', useSlot: true},
      estimatedExpirationDate: { fieldType: 'FieldDatepicker', name: 'estimatedExpirationDate', label: 'Fecha de fin aproximada', containerClass: 'container-info col-sm-6 col-md-6', clearable: true, description: 'Fecha referencial, la tarifa expirará sólo al subir una nueva tarifa', useSkeleton: false },
      expirationDate: { fieldType: 'FieldDatepicker', name: 'expirationDate', label: 'Fecha de Fin Real', containerClass: 'container-info col-sm-6 col-md-6', clearable: true, description: 'Cumplida la fecha, la tarifa expirará y quedará vigente la tarifa base del courier', useSkeleton: false, validation: 'required', change: this.changeDate },
      validityDate: { fieldType: 'FieldDatepicker', name: 'validityDate', label: 'Fecha de Inicio', containerClass: 'container-info col-sm-6 col-md-6', clearable: true, useSkeleton: false, change: this.changeValidityDate, validation: 'required' },
      upDate : { fieldType: 'FieldCheckbox', name: 'upDate', containerClass: 'col-12 container-info', options: [{ id: 1, text: 'Actualizar en este momento' }], change: this.changeCheckboxUpdate, multiple: true },
      infofiles: { name: 'infoFiles', containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info line mt-2', useSlot: true },
      fileCoverage: { fieldType: 'FieldFileDropArea', name:'fileCoverage', labelFormFile: 'Subir Tarifa', containerClass: 'col-12 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true, change: this.changeFile },
      fileFreight: { fieldType: 'FieldFileDropArea', name:'fileFreight', labelFormFile: 'Subir Fletes', containerClass: 'col-6 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true, change: this.changeFile },
      fileAdditionalInfo: { fieldType: 'FieldFileDropArea', name:'fileAdditionalInfo', labelFormFile: 'Subir Información Adicional', containerClass: 'col-12 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true, change: this.changeAdditionalInfo },
      msgAditionalInfo : { name: 'msgAditionalInfo', containerClass: 'col-12 container-info', useSlot: false },
      msgOptions: { name: 'msgOptions', containerClass: 'col-12 container-info', useSlot: false },
      fieldReplace: { fieldType: 'FieldRadio', align: 'h', name: 'replace', containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info center-radio pb-2', options: [{ id: 1, text: 'Reemplazar' }, { id: 2, text: 'Actualizar'}], change: this.changeReplace, useSlot: true },
      fileShippingZone: { fieldType: 'FieldFileDropArea', name:'fileShippingZone', labelFormFile: 'Subir Zona de Envío', containerClass: 'col-6 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true, change: this.changeFile },
      fileCPSets: { fieldType: 'FieldFileDropArea', name:'fileCPSets', labelFormFile: 'Subir Conjuntos CP', containerClass: 'col-6 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true, change: this.changeFile },
      fileTransitDays: { fieldType: 'FieldFileDropArea', name:'fileTransitDays', labelFormFile: 'Subir Días de Transito por Grupos', containerClass: 'col-6 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true, change: this.changeFile },
      fileLimitCoverage: { fieldType: 'FieldFileDropArea', name:'fileLimitCoverage', labelFormFile: 'Subir CP Cobertura Límitada', containerClass: 'col-12 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true, change: this.changeFile }
    }
  },
  computed: {
    ...mapGetters({
      rates: 'getRatesOrigins'
    })
  },
  watch: {
    'keyModalCoverages'() {
      this.initializeData()
    }
  },
  methods: {
    initializeData() {
      if (this.data.isCopy) {
        this.fieldReplace.options = []
        delete this.form.replace
      } else {
        this.fieldReplace.options = [{ id: 1, text: 'Reemplazar' }, { id: 2, text: 'Actualizar'}]
        delete this.form.replace
      }
      this.rows = [{
        courier: this.formatRows(this.data.carrier.name),
        service: this.formatRows(this.data.service.name),
        rateCustomizationType: this.formatRows(this.getRateCustomizationType(this.data.item.rate_customization_type)),
        rateValidityType: this.formatRows(this.getRateValidityType(this.data.item.rate_validity_type))
      }]
      this.schema = [
        {label: 'Courier', key: 'courier', class: 'text-center'},
        {label: 'Servicio', key: 'service', class: 'text-center'},
        {label: 'Tipo de tarifa', key: 'rateCustomizationType', class: 'text-center'},
        {label: 'Vigencia de tarifa', key: 'rateValidityType', class: 'text-center'}
      ]
      this.tableModalCoverages++
      this.fields = this.getFieldsBaseGeneral()
      if (this.data.item.estimated_expiration_date) this.form.estimatedExpirationDate = new Date(this.$options.filters.moment(`${this.data.item.estimated_expiration_date}`, 'YYYY-MM-DDT00:00:00'))
      if (this.data.item.expiration_date) this.form.expirationDate = new Date(this.$options.filters.moment(`${this.data.item.expiration_date}`, 'YYYY-MM-DDT00:00:00'))
      if (this.data.item.validity_date && !this.data.isCopy) {
        delete this.validityDate.validation
        this.form.validityDate = new Date(this.$options.filters.moment(`${this.data.item.validity_date}`, 'YYYY-MM-DDT00:00:00'))
      } else if (this.data.isCopy) this.validityDate.validation = 'required'
      if (this.data.item.percentage) this.form.applyDiscountOrIncrease = `${Math.abs(this.data.item.percentage)}%`
      this.setMinDate('estimatedExpirationDate', new Date())
      this.setMinDate('expirationDate', new Date())
      this.setMinDate('validityDate', new Date())
    },
    formatRows(val) {
      return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()
    },
    getRateCustomizationType(val) {
      switch (val) {
      case 'general': return 'General'
      case 'differential': return 'Diferencial'
      }
    },
    getRateValidityType(val) {
      switch (val) {
      case 'base': return 'Base'
      case 'temporary': return 'Temporal'
      case 'indeterminate': return 'indeterminada'
      }
    },
    getFieldsBaseGeneral() {
      const fields = [ // Edicion caso Tarifa base general Chile - Perú
        this.line,
        this.ApplyDiscountOrIncrease,
        this.infofiles,
        this.fileCoverage,
        this.msgOptions,
        this.fieldReplace,
        this.fileAdditionalInfo,
        this.msgAditionalInfo
      ]
      const isBaseOrIndeterminate = this.data.item.rate_validity_type === 'base' || this.data.item.rate_validity_type === 'indeterminate'
      if (this.data.isCopy) {
        fields.splice(2, 0, this.validityDate, this.separation, this.estimatedExpirationDate)
      } else if (isBaseOrIndeterminate && this.data.item.type === 'future') {
        fields.splice(2, 0, this.validityDate, this.estimatedExpirationDate, this.upDate)
      } else if (this.data.item.rate_validity_type === 'temporary' && this.data.item.type === 'future') {
        fields.splice(2, 0, this.validityDate, this.expirationDate, this.upDate)
      }
      if (this.data.country.code.toLowerCase() === 'co') { // Edicion caso Tarifa base general Colombia
        this.fileCoverage.containerClass = 'col-6 container-info'
        const indexFileCoverage = fields.findIndex(el => el.name === 'fileCoverage')
        fields.splice(indexFileCoverage + 1, 0, this.fileFreight)
      } else if (this.data.country.code.toLowerCase() === 'mx') { // Edicion caso Tarifa base general Mexico
        const indexFileCoverage = fields.findIndex(el => el.name === 'fileCoverage')
        this.fileCoverage.containerClass = 'col-6 container-info'
        fields.splice(indexFileCoverage + 1, 0, this.fileShippingZone, this.fileCPSets, this.fileTransitDays, this.fileLimitCoverage) 
      } else {
        this.fileCoverage.containerClass = 'col-12 container-info'
      }
      return fields
    },
    changeApplyDiscountOrIncrease(name, value) {
      let numericValue = this.form.applyDiscountOrIncrease.replace('%', '')
      numericValue = parseInt(numericValue)
      if (isNaN(numericValue)) {
        numericValue = 0
      } else {
        numericValue = Math.min(Math.max(numericValue, 0), 100)
      }
      this.form.applyDiscountOrIncrease = numericValue += '%'
      // Mantener el cursor antes del símbolo %
      this.$nextTick(() => {
        const input = this.$refs.applyDiscountOrIncrease
        const cursorPosition = input.value.indexOf('%')
        if (cursorPosition >= 0) {
          input.setSelectionRange(cursorPosition, cursorPosition)
        }
      })
    },
    changeAdditionalInfo(name, value) {
      const index = this.fields.findIndex(el => el.name === 'msgAditionalInfo')
      if (value) {
        this.fields[index].useSlot = true
      } else if (index !== -1) {
        this.fields[index].useSlot = false
      }
    },
    changeFile(name, value) {
      const indexMsgOptions = this.fields.findIndex(el => el.name === 'msgOptions')
      const indexReplace = this.fields.findIndex(el => el.name === 'replace')
      if ((this.form.fileCoverage || this.form.fileFreight || this.form.fileShippingZone || this.form.fileCPSets || this.form.fileTransitDays || this.form.fileLimitCoverage) && !this.data.isCopy) {
        this.fieldReplace.validation = 'required'
        if (indexMsgOptions !== -1) this.fields[indexMsgOptions].useSlot = true
        if (indexReplace !== -1) this.fields[indexReplace].useSlot = false
      } else {
        if (indexMsgOptions !== -1) this.fields[indexMsgOptions].useSlot = false
        if (indexReplace !== -1) this.fields[indexReplace].useSlot = true
        delete this.fieldReplace.validation
        delete this.form.replace
      }
    },
    changeValidityDate(name, value) {
      if (value) {
        this.changeDate(name, value)
        this.setMinDate('expirationDate', value)
      }
    },
    changeDate(name, value) {
      if (value) delete this[name].validation
    },
    changeCheckboxUpdate(name, value) {
      if (value.length > 0) {
        delete this.form.validityDate
        delete this.validityDate.validation
        this.validityDate.disabled = true
      } else {
        this.validityDate.validation = 'required'
        this.validityDate.disabled = false
      }
    },
    setMinDate(name, value) {
      const today = value
      const timeZone = this.timeZones[this.data.country.code]
      const countryTime = utcToZonedTime(today, timeZone)
      const tomorrow = addDays(countryTime, 1)
      this[name].propsVCalendar = {'min-date': tomorrow }
    },
    async ok() {
      const isCheck = await this.$refs.formRenderCovaerages.onlyCheckForm()
      let params = {}
      if (isCheck) {
        let name = 'putUpdateRateCoverageNY'
        if (this.data.isCopy) {
          params = { rate_id: this.data.item.id }
        } else {
          params = {
            country_id: this.data.country.id,
            carrier_id: this.data.carrier.id,
            service_id: this.data.service.id,
            rate_id: this.data.item.id
          }
        }
        const dataForm = { user_id: this.$session.get('cas_user').id }
        if (this.data.item.rate_customization_type === 'differential' && this.data.company?.id && this.data.isCopy) dataForm.company_id = this.data.company?.id
        if (this.data.item.rate_customization_type === 'differential' && this.data.company?.id && !this.data.isCopy) params.company_id = this.data.company?.id
        if (this.form.replace?.text === 'Reemplazar') dataForm.replace = true
        if (this.form.estimatedExpirationDate) dataForm.estimated_expiration_date = this.$options.filters.moment(this.form.estimatedExpirationDate, 'YYYY-MM-DD')
        if (this.form.expirationDate) dataForm.expiration_date = this.$options.filters.moment(this.form.expirationDate, 'YYYY-MM-DD')
        if (this.form.validityDate) dataForm[`${this.data.isCopy ? 'validity_date' : 'new_date'}`] = this.$options.filters.moment(this.form.validityDate, 'YYYY-MM-DD')
        if (this.form.fileAdditionalInfo) dataForm.additional_info_file = this.form.fileAdditionalInfo
        if (this.form.applyDiscountOrIncrease && !this.data.isCopy) {
          this.form.increaseOrDiscount === 'Aumento' ? dataForm.percentage_increase = this.form.applyDiscountOrIncrease.replace('%', '') : dataForm.percentage_discount = this.form.applyDiscountOrIncrease.replace('%', '')
        }
        if (this.form.upDate?.length) dataForm.update_now = 'true'
        if (this.form.fileCoverage && this.data?.item?.carrier_type === 'big') {
          dataForm['file-cp-coverages'] = this.form.fileCoverage
        } else if (this.form.fileCoverage) {
          dataForm.file = this.form.fileCoverage
        }
        if (this.form.fileCPSets) dataForm['file-cp-ranges'] = this.form.fileCPSets
        if (this.form.fileShippingZone) dataForm['file-cp-zones'] = this.form.fileShippingZone
        if (this.form.fileTransitDays) dataForm['file-transit-days-group'] = this.form.fileTransitDays
        if (this.form.fileLimitCoverage) dataForm['file-cp-limit-coverage'] = this.form.fileLimitCoverage
        if (this.form.fileFreight) dataForm.freight_file = this.form.fileFreight
        if (this.data.isCopy && this.data.country.code === 'MX') {
          name = 'postCopyBigCovergaes'
        } else if (this.data.isCopy && this.data.country.code !== 'MX') {
          name = 'postCopyCovergaes'
        } else if (this.data?.item?.rate_type === 'km') {
          name = 'putUpdateCoveragekm'
        } else if (this.data.item.rate_customization_type !== 'differential' && this.data?.item?.carrier_type === 'big') {
          name = 'putBigCarriers'
        } else if (this.data.item.rate_customization_type === 'differential' && this.data?.item?.carrier_type === 'big') {
          name = 'putBigPreferentialCoverages'
        } else if (this.data.item.rate_customization_type === 'differential' && this.data?.item?.carrier_type !== 'big') {
          name = 'putPreferentialCoverage'
        }
        const dataSendForm = {
          params,
          data:  dataForm,
          name
        }
        this.sendData(dataSendForm)
      }
    },
    sendData(data) {
      let finish = false
      this.loading.updatingData = true
      this.baseService.callUploadFileCustomParams(data.name, data.data, data.params)
        .then(response => {
          finish = true
          this.$newSuccess(`${this.data.isCopy ? '¡Procesando copia de la tarifa!' : '¡Procesando Actualización de la tarifa!'}`, 'Al finalizar le notificaremos al correo.')
          this.close()
        })
        .catch((err) => {
          finish = true
          this.$alert(err.message, {code: err.code})
        })
        .finally(() => {
          this.loading.updatingData = false
          if (finish) this.getRates()
        })
    },
    getRates() {
      const paramsGetRates = {
        carrier_id: this.data.carrier.id,
        service_id: this.data.service.id
      }
      const queryParams = {}
      if (this.data.company?.id) queryParams.organization_id = this.data.company.id
      this.$store.dispatch('fetchService', { name: 'getRatesOrigins', params: paramsGetRates, queryParams })
    },
    cleanForm() {
      Object.keys(this.form).forEach(key => {
        if (this.form[key]) delete this.form[key]
      })
    },
    close() {
      this.cleanForm()
      this.changeFile()
      this.$bvModal.hide('ModalCoverages')
      this.$emit('hideModal')
    }
  }
}
</script>

<style lang="scss">
  div.file-drop-area {
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    input.file-input {
      position: absolute;
      height: 0;
    }
  }
  .table_modal_coverages {
    background: #FAFAFC;
  }
  .line > div {
    border-bottom: 1px solid #EBE9F1;
  }
  .icon-aditional-info {
    margin-right: 0.4rem;
    margin-bottom: 0.2rem;
  }
  .msg-options {
    margin: 0 1em 0 1em;
    padding-left: 0;
  }
  .center-radio fieldset div div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.msg-percentage-coverage {
  color: #044389;
}
.msg-infoFiles {
  font-size: 12px;
  height: 35px;
  width: max-content;
  border-radius: 8px;
  border: 0.86px solid #B9D5F4;
  background-color: #E6EFFA;
  align-content: center;
  margin-bottom: 0.5rem;
}
.msg-infoFiles > span {
  margin-left: 0.5rem !important;
}
</style>