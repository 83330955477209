<template>
  <div>
    <b-modal 
      centered 
      id="ModalEditOriginsDestinations" 
      hide-footer 
      size="lg"
      modal-class="custom-dialog dialog-800"
    >
      <template> 
        <div class="h2"><span> {{tabIndex === 0 ? 'Origen ' : 'Destino ' }}{{selection.name}}</span></div>
      </template>
      <filter-swapper
        :trigger="mySelectedRows.length === 0"
        id="ModalEditOriginsDestinations"
        :buttons="buttons"
        :controlHeightButtons="controlHeight"
      >
      <template #slot1>
        <ul class="navbar-nav my-4">
          <li>
            <div class="w-70 float-right">
              <b-input-group>
                <b-form-input class="border-secondary" v-model="seekerValue" :placeholder="tabIndex === 0 ? 'Ingrese el nombre del Destino' : 'ingrese el nombre del Origen'" cleareable></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    v-b-tooltip.hover
                    title="Buscar"
                    @click="search"
                    :disabled="seekerValue.length < 3"
                  >
                    <feather-icon icon="SearchIcon"/>
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    v-b-tooltip.hover
                    title="Limpiar"
                    @click="cleanSearch"
                  >
                    <feather-icon icon="XIcon"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <span v-if="seekerValue.length < 3 && seekerValue.length > 0" class="text-danger position-absolute bg-white">{{$t('msg-nchars', {key: 3})}} </span>
            </div>
          </li>
        </ul>
      </template>
      </filter-swapper>
      <b-table v-if="(!loading.toDestination && !loading.toOrigins || !loading.isHidden)" :items="rows" :fields="schema" responsive="sm" :ref="'origins_or_destinations'">
        <template #cell(showCheckbox)="row">
          <b-form-checkbox :id="`modalOriginsDestinations-${row.item.name}`" :name="`${row.item.name}`" :value="row.item.id" v-model="mySelectedRows"  @change="changeMySelectedRows"></b-form-checkbox>
        </template>
        <template #head(showCheckbox)>
          <b-form-checkbox v-model="selectedAll" @change="() => selectAll(selectedAll)"></b-form-checkbox>
        </template>
        <template #cell(actions)="row">
          <div class="d-flex justify-content-center">
            <a variant="none" size="sm" class="px-04" v-if="!!row.item.is_hidden" @click="showOrHideRow(row.item.id, row.item.is_hidden)">
              <feather-icon v-if="!loading.isHidden || !arraySelectedIds.includes(row.item.id)" icon="EyeOffIcon" class="text-primary" v-b-tooltip.hover title="Visualizar"/>
              <feather-icon v-if="loading.isHidden && arraySelectedIds.includes(row.item.id)" icon="LoaderIcon" class="spinnee"/>
            </a>
            <a variant="none" size="sm" class="px-04" v-if="!row.item.is_hidden" @click="showOrHideRow(row.item.id, row.item.is_hidden)">
              <feather-icon v-if="!loading.isHidden || !arraySelectedIds.includes(row.item.id)" icon="EyeIcon" class="text-primary" v-b-tooltip.hover title="Ocultar"/>
              <feather-icon v-if="loading.isHidden && arraySelectedIds.includes(row.item.id)" icon="LoaderIcon" class="spinner"/>
            </a>
          </div>
        </template>
      </b-table>
      <pagination :pagination="pagination" v-if="(!loading.toDestination && !loading.toOrigins || !loading.isHidden) && (form.country && form.country.code !== 'MX')"/>
      <div class="table-render-skeleton" v-if="(loading.isHidden && loading.toDestination || loading.toOrigins)">
        <b-skeleton-table :rows="20" :columns="(schema.length || 2)" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'

export default {
  name: 'ModalEditOriginsDestinations',
  props: ['selection', 'tabIndex', 'rate', 'form', 'getRows'],
  data() {
    return {
      schema: [],
      rows: [],
      actions: [],
      showTable: false,
      mySelectedRows: [],
      seekerValue: '',
      buttons: [],
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      arraySelected: [],
      arraySelectedIds: [],
      selectedAll: false,
      baseService: new BaseServices(this),
      pagination: {
        page: 1,
        total: 0,
        limit: 20
      },
      loading: {
        toDestination: true,
        toOrigins: true,
        isHidden: false
      }
    }
  },
  computed: {
    ...mapGetters({
      originsToDestinationNY: 'getDestinationToOriginsNY',
      destinationToOriginsNY: 'getOriginsToDestinationNY',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    selection() {
      this.mySelectedRows = []
      this.getDataTable()
      this.setInitialData()
    },
    originsToDestinationNY() {
      this.setOriginsToDestinationNY()
    },
    destinationToOriginsNY() {
      this.setDestinationToOriginsNY()
    },
    'pagination.page' () {
      this.getDataTable()
    },
    generalLoading: {
      handler () {
        this.loading.toDestination = !!this.generalLoading.getDestinationToOriginsNY
        this.loading.toOrigins = !!this.generalLoading.getOriginsToDestinationNY
      }
    }
  },
  methods: {
    setInitialData() {
      this.schema = [
        {label: '', key: 'showCheckbox', class: 'text-left'},
        {label: `Combinaciones ${this.tabIndex === 0 ? 'destinos' : 'orígenes'} desde ${this.selection.name}`, key: 'name', class: 'text-left w-100'},
        {label: 'Acciones', key: 'actions', class: 'text-right', style: { width: '10%'}, useSlot: true}
      ]
    },
    getDataTable() {
      const params = {
        country_id: this.form.country.id,
        carrier_id: this.form.carrier.id,
        service_id: this.form.service.id,
        rate_id: this.rate?.id,
        origin_or_destination_id: this.selection.id
      }
      const queryParams = {
        page: this.pagination.page,
        search: this.seekerValue
      }
      if (this.tabIndex === 0) this.$store.dispatch('fetchService', { name: 'getDestinationToOriginsNY', params, queryParams })
      if (this.tabIndex === 1) this.$store.dispatch('fetchService', { name: 'getOriginsToDestinationNY', params, queryParams })
    },
    setOriginsToDestinationNY() {
      this.pagination.page = this.originsToDestinationNY.meta.current_page
      this.pagination.total = this.originsToDestinationNY.meta.total_rows
      this.rows = this.originsToDestinationNY.rows
    },
    setDestinationToOriginsNY() {
      this.pagination.page = this.destinationToOriginsNY.meta.current_page
      this.pagination.total = this.destinationToOriginsNY.meta.total_rows
      this.rows = this.destinationToOriginsNY.rows
    },
    changeMySelectedRows() {
      this.arraySelected = this.mySelectedRows.map(el => {
        return this.rows.filter(row => row.id === el)
      })
      this.arraySelected = this.arraySelected.flat(2)
      this.arraySelectedIds = this.arraySelected.map(el => el.id)
      this.isHidden(false)
      this.selectedAll = false
    },
    selectAll (value, ogn) {
      this.selectedAll = value
      this.mySelectedRows = value === true ? this.rows.map(({id}) => id) : []
      this.isHidden(value)
    },
    isHidden(all) {
      this.buttons = [
        { name: 'show', text: all ? 'Visualizar todos' : 'Visualizar', color: 'primary', icon: 'EyeIcon', action: this.show, disabled: false },
        { name: 'hide', text: all ? 'Ocultar todos' : 'Ocultar', color: 'primary', containerClass: 'ml-1', icon: 'EyeOffIcon', action: this.hide, disabled: false }
      ]
    },
    showOrHideRow(id, isHidden) {
      this.selectedAll = false
      this.arraySelectedIds = [id]
      if (!!isHidden) this.isShow = 'show'
      if (!isHidden) this.isShow = 'hide'
      this.showOrHide()
    },
    show() {
      this.buttons[0].icon = 'LoaderIcon'
      this.buttons.forEach(el => el.disabled = true)
      this.isShow = 'show'
      this.showOrHide()
    },
    hide() {
      this.buttons[1].icon = 'LoaderIcon'
      this.buttons.forEach(el => el.disabled = true)
      this.isShow = 'hide'
      this.showOrHide()
    },
    showOrHide() {
      let data = {
        destination_ids: this.arraySelectedIds,
        all: this.selectedAll
      }
      this.loading.isHidden = true
      const params = {
        country_id: this.form.country.id,
        carrier_id: this.form.carrier.id,
        service_id: this.form.service.id,
        rate_id: this.rate?.id ? this.rate?.id : this.rateSelected.id,
        originsOrDestinations: 'origins',
        origin_or_destination_id: this.selection.id
      }
      const queryParams = {
        page: this.pagination.page
      }
      if (this.isShow === 'show') params.showOrHide = 'show'
      if (this.isShow === 'hide') params.showOrHide = 'hide'
      if (this.tabIndex === 1) {
        params.originsOrDestinations = 'destinations'
        data = {
          origin_ids: this.arraySelectedIds,
          all: this.selectedAll
        }
      }
      this.baseService.callService('putShowOrHiddenOrigenOrDestination', data, params)
        .then(() => {
          this.$newSuccess('Cambio realizado con exito')
        })
        .catch(err => {
          this.$alert('Error al realizar lo solicitud', {code: err})
        })
        .finally(() => {
          if (this.tabIndex === 0) {
            this.$emit('getRows', 'getOriginsNY')
            this.$store.dispatch('fetchService', { name: 'getDestinationToOriginsNY', params, queryParams })
          }
          if (this.tabIndex === 1) {
            this.$emit('getRows', 'getDestinationsNY')
            this.$store.dispatch('fetchService', { name: 'getOriginsToDestinationNY', params, queryParams })
          }
          this.mySelectedRows = []
          this.selectedAll = false
          this.loading.isHidden = false
          this.buttons.forEach(el => el.disabled = false)
          if (this.buttons[0]?.icon) this.buttons[0].icon = 'EyeIcon'
          if (this.buttons[1]?.icon) this.buttons[1].icon = 'EyeOffIcon'
        })
    },
    search() {
      if (this.seekerValue.length >= 3) {
        const params = {
          country_id: this.form.country.id,
          carrier_id: this.form.carrier.id,
          service_id: this.form.service.id,
          rate_id: this.rate?.id,
          origin_or_destination_id: this.selection.id
        }
        const queryParams = {
          page: this.pagination.page,
          search: this.seekerValue
        }
        if (this.tabIndex === 0) this.$store.dispatch('fetchService', { name: 'getDestinationToOriginsNY', params, queryParams })
        if (this.tabIndex === 1) this.$store.dispatch('fetchService', { name: 'getOriginsToDestinationNY', params, queryParams })
      }
    },
    cleanSearch() {
      this.seekerValue = ''
      const params = {
        country_id: this.form.country.id,
        carrier_id: this.form.carrier.id,
        service_id: this.form.service.id,
        rate_id: this.rate?.id,
        origin_or_destination_id: this.selection.id
      }
      const queryParams = {
        page: 1,
        search: this.seekerValue
      }
      if (this.tabIndex === 0) this.$store.dispatch('fetchService', { name: 'getDestinationToOriginsNY', params, queryParams })
      if (this.tabIndex === 1) this.$store.dispatch('fetchService', { name: 'getOriginsToDestinationNY', params, queryParams })
    }
  }
}
</script>
