<template>
  <div>
    <filter-swapper :trigger="selectedRows.length === 0" id="origin_destination_swapper" :buttons="buttons" :controlHeightButtons="controlHeight">
      <template #slot1>
        <form-render
          :key="keyFormRender"
          :fields="fields"
          :form="form"
          :buttonSend="buttonSend"
          @send="getRows('origin')"
          class="px-0 w-100"
          containerButtonsClass="col-sm-12 col-md-12 col-lg-2 mt-2 mb-4"
        >
          <template #newCoverage>
            <span :title="$t('Importar archivo')" v-b-tooltip.bottom.hover class="float-right button-with-alert" v-if="!showTable">
              <b-button variant="warning" @click="openModal('ModalNewImport')" class="ml-1"> <feather-icon icon="DollarSignIcon" /> Nueva tarifa </b-button>
            </span>
          </template>
          <template #buttons>
            <b-button variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1" @click="cleanFilter">
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
          </template>
        </form-render>
        <b-tabs align="left" class="tab-primary" v-model="tabIndex" v-if="showTable">
          <b-tab>
            <template #title>
              <div class="tab-title">
                <span> {{$t('Origen')}} </span>
              </div>
            </template>
          </b-tab>
          <b-tab lazy v-if="!isExpress">
            <template #title>
              <div>
                <span> {{$t('Destino')}} </span>
              </div>
            </template>
          </b-tab>
        </b-tabs>
        <div class="export-button pr-0" v-if="showTable && form.tariffType.id === 1">
          <div>
            <b-button variant="outline-light" @click="openModal('ModalShowHideBulk')">
              <feather-icon icon="EyeIcon" />
              <span class=""> Mostrar/Ocultar Masivo </span>
            </b-button>
          </div>
          <div class="export-excel-origins">
            <b-button
              class="ml-1"
              variant="outline-success"
              @click="exportExcel"
              v-if="tabIndex === 0 && form.tariffType.id === 1"
              :disabled="loading.donwLoadExcel || (loading.getOrigins || loading.getDestinations)"
            >
              <feather-icon v-if="!loading.donwLoadExcel" icon="DownloadIcon" />
              <b-spinner v-if="loading.donwLoadExcel" small />
              <span class=""> Orígenes </span>
            </b-button>
          </div>
        </div>
        <div v-if="showTable" class="section-search-versions-admin">
          <form-render :key="keyFormSearch" :fields="fieldsSearch" :form="formSearch" class="ml-1" ref="formSearch">
            <template #buttonSearch>
              <div class="d-flex pl-0">
                <b-button variant="outline-secondary" v-b-tooltip.hover title="Buscar" :disabled="disabledSeachButtons" @click="validateSearch" class="coverage-button-search" >
                  <feather-icon icon="SearchIcon"
                /></b-button>
                <b-button variant="outline-secondary" v-b-tooltip.hover title="Limpiar" :disabled="disabledCleanButtons" @click="cleanSearch" class="coverage-button-clean" >
                  <feather-icon icon="XIcon"
                /></b-button>
              </div>
            </template>
          </form-render>
          <div class="button-versions-admin">
            <a class="text-primary font-weight-bold" @click="openModal('ModalManageVersions')">
              <feather-icon icon="ClockIcon" class="iconClass" size="16" />
              Historial
            </a>
          </div>
        </div>
      </template>
    </filter-swapper>
    <TableOriginsDestination
      @changeTabs="changeTabs"
      @selectAll="selectAll"
      @changeSelectedRows="changeSelectedRows"
      :rows="rows"
      :form="form"
      :loading="loading"
      :isExpress="isExpress"
      :tabIndex="tabIndex"
      :rate="rateSelected"
      :rowSelected="selectedRows"
      :allSelection="selectedAll"
      v-if="(showTable && !loading.getOrigins && !loading.getDestinations || loading.notLoading)"
    >
    </TableOriginsDestination>
    <pagination
      v-if="(showTable && !loading.getOrigins && !loading.getDestinations || loading.notLoading) && ((form.country && form.country.code.includes !== 'MX') || !form.country)"
      :pagination="pagination"
      :showSize="true"
    />
    <div class="table-render-skeleton" v-if="(showTable && loading.getOrigins || loading.getDestinations)">
      <b-skeleton-table :rows="5" :columns="(5)" />
    </div>
    <ModalManageVersions @changeRateId="changeRateId" :rate="rateSelected" :rates="rates" :company="form.idSeller" :form="form" />
    <ModalShowHideBulk
      v-if="(showTable && !loading.getOrigins && !loading.getDestinations || loading.notLoading)"
      :search="form"
      :rate="rateSelected"
      :tabIndex="tabIndex"
      @getRows="getRows"
      :isExpress="isExpress"
    />
    <ModalNewImport />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalManageVersions from './components/ModalManageVersions.vue'
import BaseServices from '@/store/services/index'
import ModalShowHideBulk from './components/ModalShowHideBulk.vue'
import ModalNewImport from './components/ModalNewImport.vue'
import TableOriginsDestination from './components/TableOriginsDestinations.vue'

export default {
  name: 'FormGetNationalTariff',
  components: { ModalManageVersions, ModalShowHideBulk, ModalNewImport, TableOriginsDestination },
  data () {
    return {
      keyFormRender: 0,
      fields: [],
      form: {tariffType: { id: 1, text: 'Nacional' }},
      keyFormSearch: 0,
      formSearch: {},
      fieldsSearch: [],
      buttons: [],
      buttonSend: { icon: 'SearchIcon', title: 'Buscar', color: 'warning', disabled: true },
      showTable: false,
      rows: [],
      rateSelected: null,
      arraySelected: [],
      arraySelectedIds: [],
      selectedRow: {},
      selectedRows: [],
      tabIndex: 0,
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      rates: null,
      baseService: new BaseServices(this),
      isShow: 'show',
      selectedAll: false,
      pagination: {
        page: 1,
        total: 20,
        limit: 20
      },
      loading: {
        countries: true,
        carriers: true,
        services: true,
        getOrigins: true,
        getDestinations: true,
        notLoading: false,
        donwLoadExcel: false
      },
      isExpress: false,
      optionsTariffType: [
        { id: 1, text: 'Nacional' },
        { id: 2, text: 'Internacional' }
      ],
      FieldCountry: { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'country', label: 'País', validation: 'required', containerClass: 'col-12 col-md-6 col-lg-2 container-info', change: this.changeCountry, addFlags: true, sort: true },
      FieldCountrySearch: { fieldType: 'FieldSelect', name: 'countrySearch', containerClass: 'coverage-country-search', placeholder: 'País', change: this.changeCountrySearch, addFlags: true, sort: true, onlyFlag: true, validation: 'required' },
      FieldIdSeller: {
        fieldType: 'FieldSelect',
        useLabel: true,
        useSkeleton: false,
        type: 'text',
        name: 'idSeller',
        label: 'ID Usuario Tarifa Preferente',
        containerClass: 'col-12 col-md-6 col-lg-2 container-info min-width-idseller',
        dependency: 'country',
        clearable: true,
        change: this.changeIdSeller,
        searchOnType: {
          fx: this.setSeller,
          nChars: 2,
          debounce: 600,
          allowSearchAsValue: false,
          persistSearch: true
        },
        tooltip: {
          text: 'Dejar vacío para visualizar la cobertura General'
        }
      },
      isOrigin: true,
      disabledSeachButtons: true,
      disabledCleanButtons: true
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountriesOrigins',
      carriers: 'getCarriersForCountry',
      services: 'getServicesNY',
      origins: 'getOriginsNY',
      destinations: 'getDestinationsNY',
      allRates: 'getRatesOrigins',
      generalLoading: 'getLoading',
      internationalCarriers: 'getInternationalCarriers',
      internationalServices: 'getInternationalServices',
      internationalOriginsOrDestiantions: 'getInternationalOriginsOrDestiantions',
      internationalRates: 'getInternationalRates'
    })
  },
  watch: {
    countries() {
      this.setOptions('country', this.countries.rows)
    },
    carriers() {
      this.setOptions('carrier', this.carriers.rows)
    },
    services() {
      this.setOptions('service', this.services.rows)
    },
    origins() {
      this.setRows(this.origins)
      this.form.origins = this.origins
    },
    destinations() {
      this.setRows(this.destinations)
      delete this.form.origins
    },
    allRates() {
      this.rates = [...this.allRates.rows]
      this.rateSelected = (this.rates.some(el => el.id === this.rateSelected?.id) ? this.rateSelected : (this.rates.find(el => el.type === 'current') || this.rates[0]))
      this.buttonSend.disabled = false
      this.rateIsExpress(this.rateSelected.id)
    },
    internationalRates() {
      this.rates = [...this.internationalRates.rows]
      this.rateSelected = (this.rates.some(el => el.id === this.rateSelected?.id) ? this.rateSelected : (this.rates.find(el => el.type === 'current') || this.rates[0]))
      this.buttonSend.disabled = false
      this.rateIsExpress(this.rateSelected.id)
    },
    'pagination.page' () {
      this.selectAll(false)
      this.selectedRows = []
      this.changeTabs()
    },
    'pagination.limit' (response) {
      this.pagination.limit = response
      this.selectAll(false)
      this.selectedRows = []
      this.changeTabs()
    },
    generalLoading: {
      handler () {
        this.loading.countries = !!this.generalLoading.getCountriesOrigins
        this.loading.carriers = !!this.generalLoading.getCarriersForCountry
        this.loading.services = !!this.generalLoading.getServicesNY
        this.loading.getOrigins = !!this.generalLoading.getOriginsNY || !!this.generalLoading.getInternationalOriginsOrDestiantions
        this.loading.getDestinations = !!this.generalLoading.getDestinationsNY
        this.loading.donwLoadExcel = !!this.generalLoading.getFileOrigins
      }
    },
    tabIndex () {
      this.formSearch.searchPlace = ''
      this.changeTabs()
    },
    internationalCarriers() {
      this.setOptions('carrier', this.internationalCarriers.rows)
    },
    internationalServices() {
      this.setOptions('service', this.internationalServices.rows)
    },
    internationalOriginsOrDestiantions() {
      this.setRows(this.internationalOriginsOrDestiantions)
      if (this.isOrigin) this.form.origins = this.internationalOriginsOrDestiantions
      else delete this.form.origins
    }
  },
  mounted () {
    this.setInitialData()
    this.$store.dispatch('fetchService', { name: 'getCountriesOrigins' })
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldRadio', name: 'tariffType', containerClass: 'col-6 container-info mt-2 mb-2', align: 'h', change: this.changeTariffType, options: this.optionsTariffType },
        { name: 'newCoverage', containerClass: 'container-info col-6 justify-content-end', useSlot: true},
        { name: 'line', useSlot: true, skipLine: true, containerClass: 'container-info col-12' },
        this.FieldCountry,
        this.FieldIdSeller,
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'carrier', label: 'Courier', validation: 'required', containerClass: 'col-12 col-md-6 col-lg-2 container-info', change: this.changeCarrier, dependency: 'country', sort: true },
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'service', label: 'Servicio', validation: 'required', containerClass: 'col-12 col-md-6 col-lg-2 container-info', change: this.changeService, dependency: 'carrier', sort: true }
      ]
    },
    setFieldsSearch() {
      this.fieldsSearch = [
        { fieldType: 'FieldInput', name: 'searchPlace', id: 'searchPlace', containerClass: 'coverage-input-search', change: this.changeSearchPlace, placeholder: `${this.tabIndex === 0 ? 'Ingrese el nombre del Origen' : 'ingrese el nombre del Destino'}`},
        { name: 'buttonSearch', useSlot: 'true', containerClass: 'pl-0'}
      ]
      if (this.form.tariffType.id === 2 && this.m_getElementIndex(this.fieldsSearch, 'name', 'countrySearch') === -1) {
        this.fieldsSearch.splice(0, 0, this.FieldCountrySearch)
        this.fieldsSearch[this.m_getElementIndex(this.fieldsSearch, 'name', 'countrySearch')].options = this.countries.rows
      }
      if (this.form.tariffType.id === 1 && this.m_getElementIndex(this.fieldsSearch, 'name', 'countrySearch') !== -1) this.fieldsSearch.splice(this.m_getElementIndex(this.fieldsSearch, 'name', 'countrySearch'), 0)
    },
    setOptions(name, value) {
      this.fields[this.getIndex(name, this.fields)].options = value
      this.setSkeletonField(name, false)
    },
    setRows(response) {
      this.pagination.page = response.meta.current_page
      this.pagination.total = response.meta.total_rows
      this.rows = response.rows
      this.loading.notLoading = false
    },
    async setSeller(value) {
      const params = {
        country_id: this.form.country.id
      }
      const queryParams = {
        search: value,
        include_shippers: true
      }
      return await this.baseService
        .callService('getSeller', queryParams, params)
        .then((resp) => {
          return resp.data.organizations.map((el) => ({
            ...el,
            text: el.fullname,
            valid: true
          }))
        })
        .catch((err) => {
          return err
        })
    },
    changeTariffType (name, value) {
      Object.keys(this.form).forEach((el) => { if (el !== 'tariffType') delete this.form[el] })
      this.buttonSend.disabled = true
      this.showTable = false
      if (value.id === 1) {
        if (this.m_getElementIndex(this.fields, 'name', 'country') === -1) this.fields.splice((this.m_getElementIndex(this.fields, 'name', 'line') + 1), 0, this.FieldCountry)
        if (this.m_getElementIndex(this.fields, 'name', 'idSeller') === -1) this.fields.splice((this.m_getElementIndex(this.fields, 'name', 'line') + 2), 0, this.FieldIdSeller)
        this.fields[this.m_getElementIndex(this.fields, 'name', 'carrier')].dependency = 'country'
      } else {
        if (this.m_getElementIndex(this.fields, 'name', 'country') !== -1) this.fields.splice(this.m_getElementIndex(this.fields, 'name', 'country'), 1)
        if (this.m_getElementIndex(this.fields, 'name', 'idSeller') !== -1) this.fields.splice(this.m_getElementIndex(this.fields, 'name', 'idSeller'), 1)
        delete this.fields[this.m_getElementIndex(this.fields, 'name', 'carrier')].dependency
        this.setSkeletonField('carrier', true)
        this.$store.dispatch('fetchService', { name: 'getInternationalCarriers' })
      }
    },
    changeCountry(name, value) {
      this.buttonSend.disabled = true
      this.showTable = false
      Object.keys(this.form).forEach((el) => { if (el !== name && el !== 'tariffType') delete this.form[el] })
      if (value?.id) {
        this.setSkeletonField('carrier', true)
        this.$store.dispatch('fetchService', { name: 'getCarriersForCountry', params: {country_id: value.id} })
      } else {
        this.changeCarrier('carrier', null)
        this.setSkeletonField('service', null)
      }
    },
    changeCarrier(name, value) {
      this.buttonSend.disabled = true
      this.showTable = false
      this.formSearch.searchPlace = ''
      delete this.form.service
      if (value?.id) {
        const serviceName = this.form.tariffType?.id === 1 ? 'getServicesNY' : 'getInternationalServices'
        const params = {
          country_id: this.form.country?.id,
          carrier_id: this.form.carrier?.id
        }
        const queryParams = {}
        if (this.form.idSeller) queryParams.organization_id = this.form.idSeller.id
        this.setSkeletonField('service', true)
        this.$store.dispatch('fetchService', { name: serviceName, params, queryParams })
      } else {
        delete this.form.carrier
        this.changeService('service', null)
      }
    },
    changeService(name, value) {
      this.buttonSend.disabled = true
      this.rateSelected = null
      this.showTable = false
      if (value?.id) {
        this.getRates()
        this.buttonSend.disabled = true
      }
    },
    changeIdSeller(name, value) {
      this.setSkeletonField('carrier', true)
      this.showTable = false
      Object.keys(this.form).forEach((el) => {
        if (el !== 'country' && el !== 'idSeller' && el !== 'tariffType') delete this.form[el]
      })
      const params = {
        country_id: this.form.country.id
      }
      const queryParams = {}
      if (value) {
        queryParams.organization_id = value.id
        this.$store.dispatch('fetchService', { name: 'getCarriersForCountry', params, queryParams })
      } else {
        this.$store.dispatch('fetchService', { name: 'getCarriersForCountry', params })
      }
    },
    changeRateId(rate) {
      this.rateSelected = rate
      this.tabIndex = 0
      this.getRates()
      this.getRows('origin')
      this.rateIsExpress(rate.id)
    },
    changeSearchPlace() {
      this.disabledSeachButtons = !!(this.formSearch.searchPlace.length < 3)
      this.disabledCleanButtons = !(this.formSearch.searchPlace.length)
    },
    getRates() {
      let serviceName = 'getRatesOrigins'
      const params = {
        carrier_id: this.form.carrier.id,
        service_id: this.form.service.id
      }
      const queryParams = {}
      if (this.form.idSeller) queryParams.organization_id = this.form.idSeller.id
      if (this.form.tariffType.id === 2) serviceName = 'getInternationalRates'
      this.$store.dispatch('fetchService', { name: serviceName, params, queryParams })
    },
    changeTabs() {
      if (this.tabIndex === 0) this.getRows('origin')
      if (this.tabIndex === 1) this.getRows('destination')
    },
    rateIsExpress(rate) {
      const rateSelected = this.rates.find(el => el.id === rate)
      rateSelected?.rate_type === 'km' ? this.isExpress = true : this.isExpress = false
    },
    setSkeletonField (name, bool) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].useSlot = bool
      this.fields[index].useSkeleton = bool
      this.fields[index].useLabel = bool
    },
    getRows(name) {
      if (name === 'origin') {
        this.tabIndex = 0
        this.isOrigin = true
      } else {
        this.tabIndex = 1
        this.isOrigin = false
      }
      const serviceName = this.getServiceName()
      const params = this.getParams()
      this.$store.dispatch('fetchService', { name: serviceName, params: params.params, queryParams: params.queryParams })
      this.showTable = true
      this.setFieldsSearch()
    },
    getServiceName() {
      if (this.form.tariffType.id === 1 && this.isOrigin) {
        return 'getOriginsNY'
      } else if (this.form.tariffType.id === 1 && !this.isOrigin) {
        return 'getDestinationsNY'
      } else if (this.form.tariffType.id === 2) {
        return 'getInternationalOriginsOrDestiantions'
      }
    },
    getParams() {
      const params = {
        country_id: this.form.country?.id,
        carrier_id: this.form.carrier?.id,
        service_id: this.form.service?.id,
        rate_id: this.rateSelected?.id
      }
      const queryParams = {
        page: this.pagination.page,
        page_size: this.pagination.limit
      }
      if (this.formSearch.searchPlace) queryParams.search = this.formSearch.searchPlace
      if (this.formSearch.countrySearch) queryParams.country_code = this.formSearch.countrySearch?.code
      if (this.form.tariffType.id === 2 && this.isOrigin) {
        params.search_by = 'origin'
      } else if (this.form.tariffType.id === 2 && !this.isOrigin) {
        params.search_by = 'destination'
      } else {
        delete params.search_by
      }
      return {params, queryParams}
    },
    getIndex(name, array) {
      return array.findIndex(el => el.name === name)
    },
    openModalEditOriginsDestinations(id) {
      this.selectedRow = this.rows.find(el => el.id === id)
      this.$bvModal.show('ModalEditOriginsDestinations')
    },
    async validateSearch() {
      const check = await this.$refs.formSearch.onlyCheckForm()
      if (check) this.tabIndex === 0 ? this.getRows('origin') : this.getRows('destination')
    },
    cleanFilter() {
      this.buttonSend.disabled = true
      this.form = {tariffType: { id: 1, text: 'Nacional' }}
      this.showTable = false
      this.rows = []
      this.rateSelected = null
      this.setInitialData()
      this.keyFormRender++
    },
    cleanSearch() {
      this.formSearch = {}
      this.changeTabs()
    },
    openModal(name) {
      this.$bvModal.show(name)
    },
    changeSelectedRows(selectedRows) {
      this.selectedRows = selectedRows
      this.arraySelected = this.selectedRows.map(el => {
        return this.rows.filter(row => row.id === el)
      })
      this.arraySelected = this.arraySelected.flat(2)
      this.arraySelectedIds = this.arraySelected.map(el => el.id)
      this.isHidden(false)
      this.selectedAll = false
    },
    selectAll (value) {
      this.selectedAll = value
      this.selectedRows = value === true ? this.rows.map(({id}) => id) : []
      this.isHidden(value)
    },
    isHidden(all) {
      this.buttons = [
        { name: 'show', text: all ? 'Visualizar todos' : 'Visualizar', color: 'primary', icon: 'EyeIcon', action: () => this.show('show'), disabled: false },
        { name: 'hide', text: all ? 'Ocultar todos' : 'Ocultar', color: 'primary', containerClass: 'ml-1', icon: 'EyeOffIcon', action: () => this.show('hide'), disabled: false }
      ]
    },
    show(isShow) {
      if (this.buttons[isShow ? 0 : 1]?.icon) this.buttons[0].icon = 'LoaderIcon'
      this.buttons.forEach(el => el.disabled = true)
      this.isShow = isShow
      this.showOrHide()
    },
    showOrHide() {
      this.loading.notLoading = true
      let queryParams = {
        origin_ids: this.arraySelectedIds,
        all: this.selectedAll
      }
      const params = {
        country_id: this.form.country.id,
        carrier_id: this.form.carrier.id,
        service_id: this.form.service.id,
        rate_id: this.rateSelected.id,
        originsOrDestinations: 'origins'
      }
      if (this.isShow === 'show') params.showOrHide = 'show'
      if (this.isShow === 'hide') params.showOrHide = 'hide'
      if (this.tabIndex === 1) {
        params.originsOrDestinations = 'destinations'
        queryParams = {
          all: this.selectedAll,
          destination_ids: this.arraySelectedIds
        }
      }
      this.baseService.callService('putShowOrHidden', queryParams, params)
        .then(() => {
          this.$newSuccess('¡Cambio realizado con éxito!')
        })
        .catch(err => {
          this.$alert('Error al realizar lo solicitud', {code: err})
        })
        .finally(() => {
          this.changeTabs()
          this.selectedRows = []
          this.selectedAll = false
          this.buttons.forEach(el => el.disabled = false)
          if (this.buttons[0]?.icon) this.buttons[0].icon = 'EyeIcon'
          if (this.buttons[1]?.icon) this.buttons[1].icon = 'EyeOffIcon'
        })
    },
    exportExcel() {
      const params = {
        country_id: this.form.country?.id,
        country_name: this.form.country?.text,
        carrier_id: this.form.carrier.id,
        carrier_name: this.form.carrier.name,
        service_id: this.form.service.id,
        service_name: this.form.service.name,
        rate_id: this.rateSelected.id,
        originsOrDestinations: 'origins'
      }
      this.$store.dispatch('getFiles', { name: 'getFileOrigins', params })
    }
  }
}
</script>
<style scoped>
  .section-search-versions-admin {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;
    align-items: center;
  }
  .input-search {
    align-self: flex-start;
  }
  .button-versions-admin {
    align-self: center;
  }
  .export-button {
    display: flex;
    justify-content: flex-end;
  }
  .border-secondary {
    min-width: 20rem;
  }
  .export-excel-origins :hover {
    background: #0FA828 !important;
    color: #fff !important;
  }
</style>
<style lang="scss">
  .min-width-idseller {
    min-width: 215px !important;
  }
  .coverage-country-search div {
    min-width: 3.4rem;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .coverage-input-search {
    min-width: 20rem;
    fieldset div div input {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .coverage-button-search {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .coverage-button-clean {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
</style>
