<template>
  <div>
    <b-card class="quoterCard">
      <div  class="button-with-alert w-100">
        <span :title="$t('Subir archivo')" v-b-tooltip.bottom.hover class="float-right"><b-button variant="warning" @click="openModal" class="float-right"><feather-icon icon="DollarSignIcon"/> Nuevo recaudo</b-button></span>
      </div>
      <form-render
        ref="formRenderCollectionOnDelivery"
        :key="keyCollectionOnDelivery"
        class="fieldQuoter"
        :fields="fields"
        :form.sync="form"
        @send="getList"
        :buttonSend="buttonSend"
        :disableAll="loading.collectionOnDelivery"
        containerButtonsClass="col-sm-12 col-md-12 col-lg-6 mt-2 mb-4 pl-0 pr-0"
      >
        <template #buttons>
          <b-button  variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1 mr-1" @click="cleanFilter">
            <feather-icon icon="RefreshCwIcon"/>
          </b-button>
        </template>
      </form-render>
      <form-render :fields="fieldsRadioCollections" :form.sync="formRadio" containerButtonsClass="col-12 mt-2" v-if="showTable" ></form-render>
      <div v-if="loading.collectionOnDelivery && showTable">
        <b-skeleton height="40px" width="`calc(100vh - 2.5rem)`" class="mt-1 mb-1" />
        <div class="table-skeleton-tableQuoter">
          <b-skeleton-table :rows="5" :columns="6" :table-props="{ }" />
        </div>
      </div>
      <collection-on-delivery-list :dataList="dataList" :keyTableRender="keyTableRender" v-if="showTable && !loading.collectionOnDelivery"></collection-on-delivery-list>
      <modal-import-collection-on-delivery></modal-import-collection-on-delivery>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CollectionOnDeliveryList from './components/CollectionOnDeliveryList.vue'
import ModalImportCollectionOnDelivery from './components/ModalImportCollectionOnDelivery.vue'

export default {
  name: 'collection-on-delivery',
  components: { CollectionOnDeliveryList, ModalImportCollectionOnDelivery },
  props: ['cleanFilters'],
  data () {
    return {
      formRenderCollection: 0,
      keyCollectionOnDelivery: 0,
      form: {},
      formRadio: {radioCollections: {id: 'enviame', text: 'Envíame'}},
      fields: [],
      fieldsRadioCollections: [{ fieldType: 'FieldRadio', name: 'radioCollections', containerClass: 'col-sm-12 col-md-12 col-lg-12', options: [{id: 'enviame', text: 'Envíame'}, {id: 'carrier', text: 'Courier'}], change: this.changeRadio, align: 'h' }],
      buttonSend: { icon: 'SearchIcon', title: 'Buscar', color: 'warning', disabled: false },
      loading: {
        countries: true,
        carriers: true,
        collectionOnDelivery: true
      },
      showTable: false,
      keyTableRender: 0
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountriesCollection',
      carriers: 'getCarriersCollection',
      collectionOnDelivery: 'getCollectionOnDelivery',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    countries() {
      this.setCountries()
    },
    carriers() {
      this.setCarriers()
    },
    collectionOnDelivery() {
      this.setCollectionOnDelivery()
    },
    generalLoading: {
      handler () {
        this.loading.countries = !!this.generalLoading.getCountriesOrigins
        this.loading.carriers = !!this.generalLoading.getCarriersForCountry
        this.loading.collectionOnDelivery = !!this.generalLoading.getCollectionOnDelivery
      },
      deep: true
    },
    cleanFilters() {
      this.cleanFilter()
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fields = [
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'country', disabled: false, label: 'País', validation: 'required', containerClass: 'col-sm-8 col-md-3 col-lg-3 fieldSelect', change: this.changeCountry, addFlags: true, sort: true },
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'carriers', label: 'Courier', containerClass: 'col-sm-8 col-md-3 col-lg-3', change: this.changeCarriers, dependency: 'country', sort: true}
      ]
      this.$store.dispatch('fetchService', { name: 'getCountriesCollection' })
    },
    setCountries() {
      const index = this.fields.findIndex(el => el.name === 'country')
      this.fields[index].options = this.countries.rows
      this.fields.forEach(el => this.setSkeletonField(el.name, false))
    },
    setCarriers() {
      const index = this.fields.findIndex(el => el.name === 'carriers')
      if (index !== -1) this.fields[index].options = this.carriers.rows
      this.fields.forEach(el => { if (el.name !== 'country') this.setSkeletonField(el.name, false) })
    },
    setSkeletonField (name, bool) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].useSlot = bool
      this.fields[index].useSkeleton = bool
      this.fields[index].useLabel = bool
    },
    setCollectionOnDelivery() {
      const rowData = this.collectionOnDelivery.rows.map((item) => {
        return {
          carrier_name: item.carrier_name,
          min_val: item.min_val_formatted || '-',
          max_val: item.max_val_formatted || '-',
          percentage_cash: item.cash[this.formRadio.radioCollections.id].commission_percentage_formatted || '-',
          cash: item.cash[this.formRadio.radioCollections.id].min_payment_formatted || '-',
          percentage_instant_payment: item.instant_payment[this.formRadio.radioCollections.id].commission_percentage_formatted || '-',
          instant_payment: item.instant_payment[this.formRadio.radioCollections.id].min_payment_formatted || '-',
          percentage_credit_card: item.credit_card[this.formRadio.radioCollections.id].commission_percentage_formatted || '-',
          credit_card: item.credit_card[this.formRadio.radioCollections.id].min_payment_formatted || '-'
        }
      })
      this.dataList = {
        rows: rowData
      }
      this.keyTableRender++
    },
    changeCountry(name, value) {
      if (value) {
        this.setSkeletonField('carriers', true)
        this.$store.dispatch('fetchService', { name: 'getCarriersCollection', params: { country_id: value.id } })
      }
      this.showTable = false
      delete this.form.carriers
    },
    changeCarriers(name, value) {
      if (!value) {
        delete this.form.carriers
      }
      this.showTable = false
    },
    changeRadio(name, value) {
      this.loading.collectionOnDelivery = true
      this.setCollectionOnDelivery()
      setTimeout(() => {
        this.loading.collectionOnDelivery = false
      }, 100)
    },
    getList() {
      this.showTable = true
      const params = {
        country_id: this.form.country.id
      }
      const queryParams = {}
      if (this.form.carriers) queryParams.carrier_id = this.form.carriers.id
      this.$store.dispatch('fetchService', { name: 'getCollectionOnDelivery', params, queryParams })
    },
    cleanFilter() {
      this.showTable = false
      this.form = {}
    },
    openModal() {
      this.$bvModal.show('ModalImportCollectionOnDelivery')
    }
  }
}
</script>
<style scoped>
.quoterCard {
  padding-right: 0;  
  padding-left: 0;  
}
.assign--buttons--link {
  padding-left: 0;
  padding-right: 0;
}
</style>