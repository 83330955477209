<template>
  <div>
    <b-card>
      <div v-if="(tabs[tabIndex] === 'Tarifas' || tabs[tabIndex] === 'Tarifas y Costos') && carrier.text && carrier.code" class="rate-logo-carrier">
        <img :alt="carrier && carrier.text" :src="`https://storage.googleapis.com/enviame-misc/carriers/logos/color/${carrier.code.toLowerCase()}.png`" />
      </div>
      <div class="d-flex" v-if="loading.mySession">
        <b-skeleton height="40px" width="20%" class="m-2 spacing-label-field" />
        <b-skeleton height="40px" width="20%" class="m-2 spacing-label-field" />
        <b-skeleton height="40px" width="20%" class="m-2 spacing-label-field" />
      </div>
      <b-tabs align="left" class="tab-primary" v-model="tabIndex" :key="originsDestinationsKey" v-if="!loading.mySession">
        <b-tab v-if="isSuperadminEdition" title="Coberturas">
          <origins-destinations></origins-destinations>
        </b-tab>
        <b-tab v-if="isSuperadminEdition || isSuperadminVisualization || isAdmin" :title="isSuperadminEdition ? 'Tarifas y Costos' : 'Tarifas'">
          <rates-costs @getCarrier="getCarrier" v-if="isCountry && firstChargeRatesCosts"></rates-costs>
          <errorCountry v-if="!isCountry"></errorCountry>
        </b-tab>
        <b-tab v-if="isSuperadminEdition || isSuperadminVisualization" title="Recaudos">
          <collection v-if="firstChargeCollection" :cleanFilters="cleanFilters"></collection>
        </b-tab>
        <b-tab v-if="isSuperadminEdition" title="Clientes Preferentes">
          <preferred-clients></preferred-clients>
        </b-tab>
        <b-tab v-if="isSuperadminEdition" title="Cargos adicionales">
          <additional-charges v-if="firstChargeAdditionalCharges"></additional-charges>
        </b-tab>
        <b-tab v-if="isSuperadminEdition" title="Márgenes">
          <margins v-if="firstChargeMargins" />
        </b-tab>
        <b-tab v-if="isSuperadminEdition" title="Actividad">
          <activity v-if="firstChargeActivity" :dataUrl="dataUrl"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import OriginsDestinations from './origins-destinations/OriginsDestinations.vue'
import ratesCosts from './rates-costs/ratesCosts.vue'
import errorCountry from './ErrorCountry.vue'
import PreferredClients from './preferred-clients/PreferredClient.vue'
import collection from './collection-on-delivery/CollectionOnDelivery.vue'
import additionalCharges from './additional-charges/AdditionalCharges.vue'
import margins from './margins/Margins'
import activity from './activity/Activity.vue'

export default {
  name: 'ManageTariff',
  components: { OriginsDestinations, ratesCosts, errorCountry, PreferredClients, collection, additionalCharges, margins, activity },
  data () {
    return {
      form: {},
      tabIndex: 0,
      carrier: {},
      originsDestinationsKey: 0,
      isCountry: null,
      loading: {
        mySession: true
      },
      tabs: [],
      firstChargeRatesCosts: false,
      isSuperadminEdition: false,
      isSuperadminVisualization: false,
      firstChargeCollection: false,
      isAdmin: false,
      cleanFilters: 0,
      firstChargeAdditionalCharges: false,
      firstChargeMargins: false,
      firstChargeActivity: false,
      dataUrl: null
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.loading.mySession) {
        this.loading.mySession = false
        this.setInitialData()
      }
    },
    tabIndex () {
      if (this.tabs[this.tabIndex] === 'Tarifas y Costos' || this.tabs[this.tabIndex] === 'Tarifas') this.firstChargeRatesCosts = true
      if (this.tabs[this.tabIndex] === 'Recaudos') {
        this.firstChargeCollection = true
        this.cleanFilters++
      }
      if (this.tabs[this.tabIndex] === 'Cargos adicionales') this.firstChargeAdditionalCharges = true
      if (this.tabs[this.tabIndex] === 'Márgenes') this.firstChargeMargins = true
      if (this.tabs[this.tabIndex] === 'Actividad') this.firstChargeActivity = true
    }
  },
  mounted() {
    if (this.loading.mySession && this.$session.get('cas_user')) {
      this.loading.mySession = false
      this.setInitialData()
    }
  },
  methods: {
    getCarrier(carrier) {
      this.carrier = carrier
    },
    setInitialData() {
      this.isSuperadminEdition = this.$session.get('cas_user')?.permissions_v2['superadmin-edition']
      this.isSuperadminVisualization = this.$session.get('cas_user')?.permissions_v2['superadmin-visualization']
      this.isAdmin = ['admin', 'superadmin'].includes(this.$session.get('cas_user').role)
      if (this.isSuperadminEdition) {
        this.tabs = ['Coberturas', 'Tarifas y Costos', 'Recaudos', 'Clientes Preferentes', 'Cargos adicionales', 'Márgenes', 'Actividad']
      } else {
        this.firstChargeRatesCosts = true
        this.tabs = ['Tarifas', 'Recaudos']
      }
      if (this.$session.get('cas_user')?.country) this.isCountry = this.$session.get('cas_user')?.country
      this.getDataUrl()
    },
    getDataUrl() {
      const url = new URL(window.location.href)
      const params = new URLSearchParams(url.search)
      const paramsObj = {}
      for (const [key, value] of params.entries()) {
        paramsObj[key] = value
      }
      if (paramsObj.tab === 'activities') {
        this.tabs.findIndex(el => el === 'Actividad') === -1 ? this.tabIndex = 0 : this.tabIndex = this.tabs.findIndex(el => el === 'Actividad')
      }
      this.dataUrl = paramsObj
    }
  }
}
</script>