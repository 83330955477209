<template>
	<b-card class="quoterCard p-0">
    <div class="w-100 button-with-alert">
      <span :title="$t('Subir archivo')" v-b-tooltip.bottom.hover class="float-right"><b-button variant="warning" @click="openModal('ModalImportMargins')" class=""> <feather-icon icon="UploadFileIcon" /> Nuevo margen </b-button></span>
    </div>
		<form-render
			ref="formRenderMargins"
			:key="keyMargins"
			:fields="fields"
			:form.sync="form"
			@send="sendForm()"
			:buttonSend="buttonSend"
			containerButtonsClass="col-sm-12 col-md-3 col-lg-3 mt-2 mb-4 pl-0 pr-0"
		>
			<template #buttons>
				<b-button variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1 mr-1" @click="cleanFilter">
					<feather-icon icon="RefreshCwIcon" />
				</b-button>
			</template>
		</form-render>
		<div v-if="loading.margins && showTable">
			<b-skeleton height="40px" width="`calc(100vh - 2.5rem)`" class="mt-1 mb-1" />
			<div class="table-skeleton-tableQuoter">
				<b-skeleton-table :rows="5" :columns="6" :table-props="{ }" />
			</div>
		</div>
		<div class="section-search-versions-admin" v-if="!loading.margins && showTable">
			<div class="button-versions-admin account-button float-right">
				<b-button v-if="isSuperadminEdition && rows.length" variant="outline-success" @click="downloadMargins" class="mr-1"><feather-icon icon="DownloadIcon"/> Exportar Márgenes</b-button>
				<a class="text-primary font-weight-bold align-content-center" @click="showTableManageVersions">
					<feather-icon icon="ClockIcon" class="iconClass" size="16" />
					Historial Versiones
				</a>
				<table-manage-versions-margins
					:rowsHistory="rowsHistory"
					@close="showTableManageVersions"
					@getListById="getList"
					v-if="showTableVersions"
					:form="form"
          ref="tableManageVersionsMargins"
				/>
			</div>
		</div>
		<margins-list
			v-show="!loading.margins && showTable"
			:rows="rows"
			:form="form"
			:selectedCheckbox="selectedCheckbox"
			@getList="getList"
      @update:selectedCheckbox="updateSelectedCheckbox"
      :rowVersionId="rowVersionId"
		></margins-list>
		<pagination :pagination="pagination" v-if="!loading.margins && showTable" :noDigits="false" :showSize="true" class="pagination-margins"/>
		<modal-import-margins @getList="getList" :formMargins="form" ref="ModalImportMargins"></modal-import-margins>
	</b-card>
</template>
<script>
import { mapGetters } from 'vuex'
import MarginsList from './components/MarginsList.vue'
import ModalImportMargins from './components/ModalImportMargins.vue'
import TableManageVersionsMargins from './components/TableManageVersionsMargins.vue'
export default {
  components: { MarginsList, ModalImportMargins, TableManageVersionsMargins },
  name: 'Magins',
  data () {
    return {
      isSuperadminEdition: this.$session.get('cas_user')?.permissions_v2['superadmin-edition'],
      keyMargins: 0,
      form: {},
      fields: [],
      buttonSend: { icon: 'SearchIcon', title: 'Buscar', color: 'warning', disabled: false },
      loading: {
        countries: false,
        carriers: false,
        margins: true
      },
      showTable: false,
      showTableVersions: false,
      rows: [],
      pagination: {
        page: 1,
        total: 20,
        limit: 20
      },
      selectedCheckbox: ['service_flag'],
      renameSelectedCheckbox: ['CARRIER_SERVICE'],
      rowsHistory: [],
      rowVersionId: null,
      validityOrder: ['future', 'current', 'expired'],
      marginOrder: ['DEFAULT_COUNTRY', 'DEFAULT_CARRIER', 'CARRIER_SERVICE'],
      isExport: false
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountriesOriginsModal',
      carriers: 'getCarriersOriginsModal',
      services: 'getServicesOrigins',
      margins: 'getMargins',
      marginsHistory: 'getMarginsHistory',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    countries() {
      this.setOptions('countries', 'country')
      const resetFields = ['country', 'carrier', 'service']
      resetFields.forEach(el => this.setSkeletonField(el, false))
    },
    carriers() {
      this.setOptions('carriers', 'carrier')
      const resetFields = ['carrier', 'service']
      resetFields.forEach(el => this.setSkeletonField(el, false))
    },
    services() {
      this.setOptions('services', 'service')
      this.setSkeletonField('service', false)
    },
    margins() {
      this.setMargins()
    },
    marginsHistory() {
      this.marginsHistory.rows.sort(this.customSort)
      this.setMarginsHistory()
    },
    'pagination.page' () {
      this.getList()
    },
    'pagination.limit' () {
      this.getList()
    },
    generalLoading: {
      handler () {
        this.loading.countries = !!this.generalLoading.getCountriesOriginsModal
        this.loading.carriers = !!this.generalLoading.getCarriersOriginsModal
        this.loading.margins = !!this.generalLoading.getMargins
        this.loading.marginsHistory = !!this.generalLoading.getMarginsHistory
      },
      deep: true
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fields = [
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'country', disabled: false, label: 'País', containerClass: 'col-sm-12 col-md-3 col-lg-3 fieldSelect', change: this.changeCountry, addFlags: true, validation: 'required', sort: true },
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'carrier', label: 'Courier', containerClass: 'col-sm-12 col-md-3 col-lg-3', change: this.changeCarriers, dependency: 'country', sort: true},
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'service', label: 'Servicio', containerClass: 'col-sm-12 col-md-3 col-lg-3', change: this.changeService, dependency: 'carrier', sort: true}
      ]
      this.$store.dispatch('fetchService', { name: 'getCountriesOriginsModal' })
    },
    customSort(a, b) {
      const validityComparison = this.validityOrder.indexOf(a.validity_type) - this.validityOrder.indexOf(b.validity_type)
      if (validityComparison !== 0) {
        return validityComparison
      }
      const marginComparison = this.marginOrder.indexOf(a.margin_type) - this.marginOrder.indexOf(b.margin_type)
      return marginComparison
    },
    setOptions(name, fieldName) {
      this.fields[this.getIndexField(fieldName)].options = this[name].rows
    },
    setMargins() {
      this.rows = this.margins.rows
      this.pagination.total = this.margins.meta.total_rows
    },
    setMarginsHistory() {
      const rows = [...this.marginsHistory.rows]
      const countValidity = this.countPropValidity(rows, 'validity')
      this.addPropCountValidity(countValidity, 'validityCount', 'validity')
      const countCourierService = this.countPropCourierService(rows, 'validity_type', 'margin_type')
      this.addPropCountValidity(countCourierService, 'validityMarginCount', 'isCountCourierService')
      this.addCourierService()
      this.addPropHideRows()
      this.marginsHistory.rows.forEach(row => {
        if ((row.validity_type === 'current' && this.renameSelectedCheckbox.includes(row.margin_type)) || (row.validity_type === 'current' && !row.type)) row._rowVariant = 'primary'
      })
      this.rowsHistory = this.marginsHistory.rows
    },
    countPropValidity(rows, prop) {
      return rows.reduce((acc, obj) => {
        if (obj[prop] in acc) { 
          acc[obj[prop]]++
          const newObj = {id: obj[prop], validity: obj[prop], type: '', validity_date: '', user_email: '', icon: 'ChevronRightIcon', validityCount: acc[obj[prop]], validity_type: obj.validity_type}
          if (obj[prop] === 'Actual' && this.renameSelectedCheckbox.includes(obj.margin_type)) newObj._rowVariant = 'primary'
          const exists = this.marginsHistory.rows.some(item => item.id === newObj.id)
          if (!exists) this.marginsHistory.rows.splice(this.marginsHistory.rows.findIndex(el => el[prop] === obj[prop]), 0, newObj)
        } else {
          acc[obj[prop]] = 1
        }
        return acc
      }, {})
    },
    countPropCourierService(rows, prop1, prop2) {
      return rows.reduce((acc, obj) => {
        const key = `${obj[prop1]}/${obj[prop2]}`
        if (key in acc) {
          acc[key]++
          const newObj = {id: `${obj[prop1]}/${obj[prop2]}`, [`${obj[prop1]}/${obj[prop2]}`]: acc[key]++, validity: obj.validity, type: obj.type, courierService: '', validity_date: '', user_email: '', icon: 'ChevronRightIcon', validity_type: obj.validity_type, margin_type: obj.margin_type }
          if (obj.validity === 'Actual' && this.renameSelectedCheckbox.includes(obj.margin_type)) newObj._rowVariant = 'primary'
          const exists = this.marginsHistory.rows.some(item => item.id === newObj.id)
          if (!exists) this.marginsHistory.rows.splice(this.marginsHistory.rows.findIndex(el => `${el.validity_type}/${el.margin_type}` === `${obj[prop1]}/${obj[prop2]}`), 0, newObj)
        } else {
          acc[key] = 1
        }
        return acc
      }, {})
    },
    addCourierService() {
      this.marginsHistory.rows.map(obj => {
        let courierServiceName = ''
        if (obj.carrier_name && obj.service_name) courierServiceName = `${this.capitalizeFirstLetter(obj.carrier_name)}/${this.capitalizeFirstLetter(obj.service_name)}`
        if (obj.carrier_name && !obj.service_name) courierServiceName = this.capitalizeFirstLetter(obj.carrier_name)
        if (!obj.carrier_name && obj.service_name) courierServiceName = this.capitalizeFirstLetter(obj.service_name)
        obj.courierService = courierServiceName
      })
    },
    addPropCountValidity(countValidity, propName, propValue) {
      let prop_1 = propName
      let prop_2 = propValue
      this.marginsHistory.rows.map(obj => {
        if (propValue === 'isCountCourierService') prop_2 = `${obj.validity_type}/${obj.margin_type}`
        if (propValue === 'isCountCourierService') prop_1 = `${obj.validity_type}/${obj.margin_type}`
        obj[prop_1] = countValidity[obj[prop_2]]
      })
    },
    addPropHideRows() {
      const arrayIds = ['Programada', 'Actual', 'Expirada']
      this.marginsHistory.rows = this.marginsHistory.rows.map(obj => ({
        ...obj,
        hideRows: !((arrayIds.includes(obj.validity) && obj.validityCount === 1) || (obj.validityCount > 1 && !obj.margin_type))
      }))
    },
    capitalizeFirstLetter(str) {
      if (str.length === 0) {
        return str
      }
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    },
    setSkeletonField (name, bool) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].useSlot = bool
      this.fields[index].useSkeleton = bool
      this.fields[index].useLabel = bool
    },
    getIndexField(name) {
      return this.fields.findIndex(el => el.name === name)
    },
    changeCountry(name, value) {
      if (value) {
        this.setSkeletonField('carrier', true)
        this.setSkeletonField('service', true)
        this.$store.dispatch('fetchService', { name: 'getCarriersOriginsModal', params: { country_id: value.id } })
      }
      this.showTable = false
      this.changeCarriers('carrier', null)
    },
    changeCarriers(name, value) {
      if (!!value) {
        const params = {
          country_id: this.form.country.id,
          carrier_id: value.id
        }
        this.setSkeletonField('service', true)
        this.$store.dispatch('fetchService', { name: 'getServicesOrigins', params })
      } else {
        delete this.form.carrier
      }
      this.changeService('service', null)
      this.showTable = false
    },
    changeService(name, value) {
      if (!value) {
        delete this.form.service
      }
      this.showTable = false
    },
    sendForm() {
      this.rowVersionId = null
      this.getList()
      this.getHistory()
      if (this.showTableVersions) this.showTableManageVersions()
    },
    getHistory() {
      const queryParams = {
        country_code: this.form.country?.code.toLowerCase()
      }
      if (this.form.carrier?.code) queryParams.carrier_code = this.form.carrier?.code.toLowerCase()
      if (this.form.service?.id) queryParams.carrier_service_id = this.form.service?.id
      this.$store.dispatch('fetchService', { name: 'getMarginsHistory', queryParams })
    },
    getList(data) {
      this.showTable = true
      const queryParams = {
        page: data?.page || this.pagination.page,
        page_size: data?.limit || this.pagination.limit
      }
      if (data?.id) this.rowVersionId = data.id
      if (this.rowVersionId) queryParams.margin_configuration_id = this.rowVersionId
      if (!this.rowVersionId) {
        queryParams.country_code = this.form.country?.code.toLowerCase()
        this.selectedCheckbox.forEach((item) => {
          queryParams[item] = true
        })
        if (this.form.carrier?.code) queryParams.carrier_code = this.form.carrier?.code.toLowerCase()
        if (this.form.service?.id) queryParams.carrier_service_id = this.form.service?.id
      }
      if (this.isExport) {
        this.isExport = false
        delete queryParams.page
        delete queryParams.page_size
        const props = {
          name: 'getExportMargins',
          fileName: 'Margenes',
          queryParams,
          onSuccess: () => this.$newSuccess('¡Descarga Ok!', 'El archivo de Márgenes ha sido descargado con éxito.'),
          onError: (err) => this.$alert(this.$t('¡Error!, Ha ocurrido un error al descargar el archivo de Márgenes.', {code: err}))
        }
        this.$store.dispatch('fetchServiceGenerateExcel', props)
      } else {
        this.$store.dispatch('fetchService', { name: 'getMargins', queryParams })
      }
    },
    updateSelectedCheckbox(newValue) {
      this.selectedCheckbox = newValue
      this.renameSelectedCheckbox = newValue.map(item => {
        if (item === 'service_flag') return 'CARRIER_SERVICE'
        if (item === 'carrier_flag') return 'DEFAULT_CARRIER'
        if (item === 'country_flag') return 'DEFAULT_COUNTRY'
      })
      this.marginsHistory.rows.forEach(obj => {
        obj._rowVariant = 'none'
        if (obj.validity === 'Actual' && this.renameSelectedCheckbox.includes(obj.margin_type)) obj._rowVariant = 'primary'
      })
      this.rowsHistory = this.marginsHistory.rows
      this.getList()
    },
    downloadMargins() {
      this.isExport = true
      this.getList()
    },
    cleanFilter() {
      this.showTable = false
      this.form = {}
    },
    openModal(name) {
      this.$bvModal.show(name)
    },
    showTableManageVersions() {
      this.showTableVersions = !this.showTableVersions
    }
  }
}
</script>
<style scoped>
.section-search-versions-admin {
  display: flex;
  justify-content: right;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-items: center;
}
.button-versions-admin {
  align-self: center;
}
.account-button {
  position: relative !important;
  z-index: 9;
  display: flex;
  gap: 0.5rem;
}
.pagination-margins {
  z-index: 8 !important;
  position: relative;
}
</style>