<template>
  <div>
    <b-modal
      centered
      id="ModalManageVersions"
      hide-footer
      size="lg"
      modal-class="custom-dialog dialog-1000"
      title="Administrar versiones"
    >
      <b-table
        :items="rows"
        :fields="schema"
        :select-mode="'single'"
        responsive="sm"
        ref="selectableRatesTable1"
        selectable
        selected-variant="primary"
        @row-selected="onRowSelected"
        v-if="!loading.deleteRate"
      >
        <template #cell(actions)="row">
          <div class="d-flex">
            <b-button
              variant="none"
              v-if="$session.get('cas_user').permissions_v2['superadmin-edition'] && row.item.status === 'Programada' && row.item.rate_type !== 'int'"
              size="sm"
              @click="openModalCoverages(row.item)"
            >
              <feather-icon icon="Edit2Icon" v-b-tooltip.hover title="Editar" class="text-primary" />
            </b-button>
            <b-button
              variant="none"
              v-if="$session.get('cas_user').permissions_v2['superadmin-edition']"
              size="sm"
              @click="openModalHistory(row.item)"
            >
              <feather-icon icon="ClockIcon" v-b-tooltip.hover title="Historial archivos" class="text-primary" />
            </b-button>
            <b-button
              variant="none"
              v-if="$session.get('cas_user').permissions_v2['superadmin-edition'] && row.item.status === 'Versión actual' && row.item.rate_type !== 'int'"
              size="sm"
              @click="openModalCoverages(row.item, 'isCopy')"
            >
              <feather-icon icon="CopyIcon" v-b-tooltip.hover title="Crear una copia editable" class="text-primary" />
            </b-button>
            <b-button
              variant="none"
              v-if="$session.get('cas_user').permissions_v2['superadmin-edition'] && row.item.status === 'Programada'"
              size="sm"
              @click="confirmDeleteAlert(row.item)"
            >
              <feather-icon icon="TrashIcon" v-b-tooltip.hover title="Eliminar" class="text-danger" />
            </b-button>
          </div>
        </template>
      </b-table>
      <div v-if="loading.deleteRate" class="w-100 text-center"><b-spinner /></div>
    </b-modal>
    <ModalCoverages :data="dataModalCoverages" :keyModalCoverages="keyModalCoverages" v-show="openModalCoverage" @hideModal="hideModal"></ModalCoverages>
    <ModalHistory
      :carrier="form.carrier"
      :service="form.service"
      :form="form"
      :keyModalHistory="keyModalHistory"
      :isCurrent="isCurrent"
      :selectedRate="selectedRate"
    ></ModalHistory>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalCoverages from './ModalCoverages.vue'
import ModalHistory from './ModalHistory.vue'

export default {
  name: 'ModalManageVersions',
  components: { ModalHistory, ModalCoverages },
  props: ['rates', 'changeRateId', 'rate', 'form'],
  data() {
    return {
      formRenderModalEditCoverages: 0,
      schema: [],
      rows: [],
      actions: [],
      isCurrent: true,
      date: '',
      sortRates: [],
      ratesTable: 0,
      selected: [],
      selectedRate: '',
      dataModalCoverages: {},
      keyModalHistory: 0,
      keyModalCoverages: 0,
      loading: {
        deleteRate: false
      },
      openModalCoverage: false
    }
  },
  watch: {
    rates() {
      this.setRates()
      this.setInitialData()
    }
  },
  computed: {
    ...mapGetters({
      deleteRate: 'deleteRate',
      generalLoading: 'getLoading'
    })
  },
  methods: {
    setInitialData() {
      this.schema = [
        {label: 'Numero', key: 'number'},
        {label: 'Fecha modificación', key: 'date'},
        {label: 'Usuario', key: 'user', class: ['text-center']},
        {label: 'Rango de Vigencia', key: 'range'},
        {label: '', key: 'status'},
        {label: '', key: 'actions'}
      ]
    },
    setRates() {
      let allRates = [...this.rates]
      allRates = allRates.sort((a, b) => new Date(b.validity_date).getTime() - new Date(a.validity_date).getTime())
      this.rows = allRates.map((el, index) => {
        const formatValidityDate =  `${this.$options.filters.moment(el.validity_date, 'DD/MM/YYYY')}`
        let rowVariantRate = 'none'
        let ranges = this.$options.filters.moment(el.validity_date, 'DD/MM/YYYY')
        let statusRate = ''
        if (this.rate?.id === el.id) rowVariantRate = 'primary'
        if (el.type === 'current') statusRate = 'Versión actual'
        if (el.type === 'future') statusRate = 'Programada'
        if (el.type === 'expired') statusRate = 'Expirada'
        if (index >= 1) ranges = `${this.$options.filters.moment(el.validity_date, 'DD/MM/YYYY')} - ${this.$options.filters.moment(allRates[index - 1]?.validity_date, 'DD/MM/YYYY')}`
        return {
          ...el,
          date: formatValidityDate,
          number: (allRates.length - index).toFixed(1),
          user: el.last_editor_email,
          range: ranges,
          status: statusRate,
          _rowVariant: rowVariantRate
        }
      })
    },
    onRowSelected(item) {
      if (this.rate?.id !== item[0].id) {
        this.selected = item
        this.rows.forEach(el => el.rowVariant = 'none')
        this.$emit('changeRateId', item[0])
      }
      this.$bvModal.hide('ModalManageVersions')
    },
    openModalHistory(item) {
      this.keyModalHistory++
      this.selectedRate = item
      this.$bvModal.show('ModalHistory')
      this.$bvModal.hide('ModalManageVersions')
    },
    openModalCoverages(item, isCopy = false) {
      this.openModalCoverage = true
      this.dataModalCoverages.item = item
      if (isCopy) {
        this.dataModalCoverages.item.validity_date = null
        this.dataModalCoverages.item.estimated_expiration_date = null
      }
      this.keyModalCoverages++
      this.dataModalCoverages.country = this.form.country
      this.dataModalCoverages.carrier = this.form.carrier
      this.dataModalCoverages.service = this.form.service
      this.dataModalCoverages.company = this.form.idSeller
      this.dataModalCoverages.isCopy = isCopy
      this.$bvModal.show('ModalCoverages')
      this.$bvModal.hide('ModalManageVersions')
    },
    confirmDeleteAlert(item) {
      this.$confirm(
        this.$t('¿Está seguro que desea eliminar tarifa programada?'),
        () => this.deleteTariff(item),
        'Eliminar tarifa',
        'Confirmar',
        'Cancelar'
      )
    },
    deleteTariff(item) {
      let serviceName = 'deleteRate'
      this.loading.deleteRate = true
      const params = {
        carrier_id: this.form.carrier.id,
        service_id: this.form.service.id,
        rate_id: item.id
      }
      if (this.form.idSeller) params.company_id = this.form.idSeller.id
      if (item.rate_type === 'int') serviceName = 'deleteInternationalRate'
      this.$store.dispatch('fetchService', { name: serviceName, params, onSuccess: this.deleteComplete, onError: () => this.loading.deleteRate = false })
    },
    deleteComplete() {
      this.loading.deleteRate = false
      const currentRate = this.rates.filter(el => el.type === 'current')
      this.$bvModal.hide('ModalManageVersions')
      this.$emit('changeRateId', currentRate[0])
    },
    hideModal() {
      this.openModalCoverage = false
    }
  }
}
</script>
