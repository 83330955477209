<template>
  <div>
    <form-render :form="form" :fields="fields" ref="formRenderInternationalTariff" :key="keyFormRender">
      <template #msg="scope"><ul class="modal-msj mt-2 mb-2"><li>{{ scope.field.msg }}</li></ul></template>
    </form-render>
    <div class="footer-modal-coverage">
      <b-button class="float-right mt-1 mb-1" variant="outline-success" @click="ok" :disabled="loading.form">
        <span v-if="!loading.form" class="mb-1">Crear Tarifa</span>
        <span class="mt-1 p-2" v-if="loading.form"><b-spinner small /> </span>
      </b-button>
    </div>
  </div>
</template>
<script>
import BaseServices from '@/store/services/index'
import { mapGetters } from 'vuex'

export default {
  name: 'FormRenderInternationalTariff',
  data() {
    return {
      baseService: new BaseServices(this),
      form: {
        tariffType :{ id: 'general', text: 'General' },
        validityType: { id: 'base', text: 'Base' }
      },
      fields: {},
      keyFormRender: 0,
      loading: {
        form: false
      }
    }
  },
  mounted() {
    this.setInitialData()
    this.$store.dispatch('fetchService', { name: 'getCarriersNY', queryParams: {is_international: true} })
  },
  computed: {
    ...mapGetters({
      carriers: 'getCarriersNY',
      services: 'getServicesOrigins'
    })
  },
  watch: {
    carriers() {
      this.setOptions('carrier', this.carriers.rows)
    },
    services() {
      this.setOptions('service', this.services.rows)
    }
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'carrier', label: 'Nombre ó Código Courier', validation: 'required', containerClass: 'container-info col-sm-6 col-md-6', sort: true, change: this.changeCarrier },
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'service', label: 'Servicio', validation: 'required', containerClass: 'container-info col-sm-6 col-md-6', dependency: 'carrier', sort: true, change: this.changeService },
        { name: 'msg', msg: '¿Qué tipo de tarifa le gustaría crear? A continuación elija una opción...', useLabel: false, useSlot: true, containerClass: 'col-sm-12 col-md-12 col-lg-12' },
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'tariffType', label: 'Tipo de Tarifa', validation: 'required', containerClass: 'container-info col-sm-6 col-md-6', dependency: 'service', options: [{ id: 'general', text: 'General' }], sort: true, disabled: true },
        { fieldType: 'FieldSelect', useLabel: true, name: 'validityType', label: 'Vigencia de Tarifa', validation: 'required', containerClass: 'container-info col-sm-6 col-md-6', change: this.changeValidityType, dependency: 'tariffType', sort: true, disabled: true }
      ]

    },
    setOptions(name, options) {
      this.fields[this.m_getElementIndex(this.fields, 'name', name)].options = options
      this.m_setSkeletonField(this.fields, 'name', name, false)
    },
    changeCarrier(name, value) {
      this.m_setSkeletonField(this.fields, 'name', 'service', true)
      const params = {
        country_id: value.countryId,
        carrier_id: value.id
      }
      this.$store.dispatch('fetchService', { name: 'getServicesOrigins', params, queryParams: {is_international: true} })
    },
    changeService(name, value) {
      this.fields.splice(this.m_getElementIndex(this.fields, 'name', 'validityType') + 1)
      if (value.id) {
        this.fields.push({ name: 'msg', msg: 'Subir archivos de Tarifa', useLabel: false, useSlot: true, containerClass: 'col-12' })
        this.fields.push({ fieldType: 'FieldFileDropArea', name:'fileCoverage', labelFormFile: 'Subir Tarifa', containerClass: 'col-12 container-info', accept: '.csv', classField:'hide-button-browse', clearable: true, validation: 'required'})
        this.fields.push({ name: 'msg', msg: 'Indique la fecha de inicio de la tarifa y una fecha de fin aproximada.', useLabel: false, useSlot: true, containerClass: 'col-12' })
        this.fields.push({ fieldType: 'FieldDatepicker', name: 'startDate', label: 'Fecha de inicio', containerClass: 'container-info col-6', clearable: true, change: this.changeDate, validation: 'required', propsVCalendar: { 'min-date': new Date(new Date().setDate(new Date().getDate() + 1)).toISOString() } })
        this.fields.push({ fieldType: 'FieldDatepicker', name: 'estimatedExpirationDate', label: 'Fecha de fin aproximada', containerClass: 'container-info col-6', clearable: true, description: 'Fecha referencial, la tarifa expirará sólo al subir una nueva tarifa', dependency: 'startDate' })
        this.fields.push({ fieldType: 'FieldCheckbox', name: 'upDate', containerClass: 'col-12 container-info', options: [{ id: 1, text: 'Actualizar ahora' }], change: this.changeCheckboxUpdate, multiple: true })
      }
    },
    changeDate(name, value) {
      value ? delete this.fields[this.m_getElementIndex(this.fields, 'name', name)].validation : this.fields[this.m_getElementIndex(this.fields, 'name', name)].validation = 'required'
      delete this.form.estimatedExpirationDate
      if (value) this.fields[this.m_getElementIndex(this.fields, 'name', 'estimatedExpirationDate')].propsVCalendar = { 'min-date': new Date(value.setDate(value.getDate() + 1)).toISOString() }
    },
    changeCheckboxUpdate(name, value) {
      if (value?.length && this.m_getElementIndex(this.fields, 'name', 'startDate') !== -1) {
        delete this.form.startDate
        delete this.fields[this.m_getElementIndex(this.fields, 'name', 'startDate')].validation
        this.fields[this.m_getElementIndex(this.fields, 'name', 'startDate')].disabled = true
        delete this.fields[this.m_getElementIndex(this.fields, 'name', 'estimatedExpirationDate')]?.dependency
        this.fields[this.m_getElementIndex(this.fields, 'name', 'estimatedExpirationDate')].propsVCalendar = { 'min-date': new Date(new Date().setDate(new Date().getDate() + 1)).toISOString() }
      } else {
        delete this.form.startDate
        if (this.m_getElementIndex(this.fields, 'name', 'startDate') !== -1) this.fields[this.m_getElementIndex(this.fields, 'name', 'startDate')].disabled = false
        if (this.m_getElementIndex(this.fields, 'name', 'startDate') !== -1) this.fields[this.m_getElementIndex(this.fields, 'name', 'startDate')].validation = 'required'
        this.fields[this.m_getElementIndex(this.fields, 'name', 'estimatedExpirationDate')].dependency = 'startDate'
      }
    },
    async ok() {
      const isCheck = await this.$refs.formRenderInternationalTariff.onlyCheckForm()
      if (isCheck) {
        const params = {
          carrier_id: this.form.carrier.id,
          service_id: this.form.service.id
        }
        const dataForm = {
          file: this.form.fileCoverage,
          validity_type: this.form.validityType.id
        }
        dataForm.validity_date = this.form.startDate ? this.$options.filters.moment(this.form.startDate, 'YYYY-MM-DD') : this.$options.filters.moment(new Date(), 'YYYY-MM-DD')
        if (this.form.upDate) dataForm.update_now = true
        if (this.form.estimatedExpirationDate) dataForm.estimated_expiration_date = this.$options.filters.moment(this.form.estimatedExpirationDate, 'YYYY-MM-DD')
        const data = {
          params,
          data: dataForm,
          user_id: this.$session.get('cas_user').id
        }
        this.sendForm(data)
      }
    },
    sendForm(data) {
      this.loading.form = true
      this.baseService
        .callUploadFileCustomParams('postInternationalCoverage', data.data, data.params, data.user_id)
        .then(() => {
          this.$newSuccess('¡Procesando nueva tarifa internacional!', 'Al final le notificaremos al correo.', 'small')
        })
        .catch((err) => {
          this.$newError('Ocurrio un error.', err.message, 'large')
          this.loading.form = false
        })
        .finally(() => {
          this.loading.form = false
          this.$bvModal.hide('ModalNewImport')
        })
    }
  }
}
</script>
