<template>
  <div>
    <div>
      <b-table
        responsive="sm"
        :key="keyTableRender"
        :items="rows"
        :fields="schema"
        borderless="borderless"
        tbody-tr-class="comparatorTableBody"
        show-empty
        class="comparatorTable"
      >
        <template #empty="">
          <span class="text-center w-full push-left">{{ 'No se encontraron datos para mostrar' }}</span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['dataList'],
  data() {
    return {
      rows: [],
      schema: [],
      keyTableRender: 0
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    'dataList.rows'() {
      this.setRows()
    }
  },
  methods: {
    setInitialData() {
      this.setSchema()
      this.setRows()
    },
    setSchema() {
      this.schema = [
        { key: 'companyId', label: 'ID Cliente' },
        { key: 'companyName', label: 'Nombre Cliente' },
        { key: 'carrierName', label: 'Courier' },
        { key: 'serviceName', label: 'Servicio' },
        { key: 'clientFlow', label: 'Determinación Precios' },
        { key: 'validityRange', label: 'Rango vigencia' }
      ]
    },
    setRows() {
      this.rows = this.dataList.rows.map((item) => {
        return {
          ...item,
          validityRange: `${this.formatDate(item.validityDate)}${item.expirationDate ? ` - ${  this.formatDate(item.expirationDate)}` : ''}`
        }
      })
    },
    formatDate(date) {
      return date ? this.$options.filters.dbDateToFormat(date, 'dd-LL-y') : ''
    }
  }
}
</script>
