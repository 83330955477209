<template>
  <div>
    <div class="mt-1 mb-2 p-0">
      <b-table
        :fields="schema"
        :items="rows"
        ref="tableOriginsDestinations"
      >
        <template #cell(showCheckbox)="data">
          <b-form-checkbox :id="`${data.item.name}`" :name="`${data.item.name}`" :value="data.item.id" v-model="selectedRows" @change="changeSelected" />
        </template>
        <template #head(showCheckbox)>
          <b-form-checkbox v-model="selectedAll" @change="() => selectAllCheck(selectedAll)"></b-form-checkbox>
        </template>
        <template #cell(country)="data">
          <img class="mr-1 fieldSelect--img" :src="require(`@/assets/images/flags/${data.item.country.code.toLowerCase()}.png`)" :alt="data.item.country.name" />
        </template>
        <template #cell(actionsRows)="row">
          <div class="d-flex justify-content-center">
            <b-button :disabled="loading.notLoading" variant="none" size="sm" class="px-04" v-if="!!row.item.is_hidden" @click="showOrHideRow(row.item.id, row.item.is_hidden)">
              <feather-icon v-if="!loading.notLoading || !arraySelectedIds.includes(row.item.id)" icon="EyeOffIcon" class="text-primary" v-b-tooltip.hover title="Visualizar" />
              <feather-icon v-if="loading.notLoading && arraySelectedIds.includes(row.item.id)" icon="LoaderIcon" class="spinner" />
            </b-button>
            <b-button :disabled="loading.notLoading" variant="none" size="sm" class="px-04" v-if="!row.item.is_hidden" @click="showOrHideRow(row.item.id, row.item.is_hidden)" >
              <feather-icon v-if="!loading.notLoading || !arraySelectedIds.includes(row.item.id)" icon="EyeIcon" class="text-primary" v-b-tooltip.hover title="Ocultar" />
              <feather-icon v-if="loading.notLoading && arraySelectedIds.includes(row.item.id)" icon="LoaderIcon" class="spinner" />
            </b-button>
            <b-button :disabled="loading.notLoading" variant="none" size="sm" class="px-04" @click="openModalDuplicate(row.item.id)" v-if="form.country && form.country.code !== 'MX'" >
              <feather-icon icon="CopyIcon" class="text-primary" v-b-tooltip.hover title="Duplicar Tarifa para otro origen" />
            </b-button>
            <b-button :disabled="loading.notLoading" variant="none" size="sm" class="px-04" @click="openModalEditOriginsDestinations(row.item.id)" v-if="!isExpress" >
              <feather-icon icon="FileTextIcon" class="text-primary" v-b-tooltip.hover :title="tabIndex === 0 ? 'Editar destinos' : 'Editar orígenes'" />
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
    <ModalDuplicateCoverage :data="dataDuplicateCoverage" :keyModalDuplicateCoverages="keyModalDuplicateCoverages" />
    <ModalEditOriginsDestinations :selection="selectedRow" :tabIndex="tabIndex" :rate="rate" :form="form" @getRows="getRows" />
  </div>
</template>
<script>
import BaseServices from '@/store/services/index'
import ModalDuplicateCoverage from './ModalDuplicateCoverage.vue'
import ModalEditOriginsDestinations from './ModalEditOriginsDestinations.vue'

export default {
  components: { ModalDuplicateCoverage, ModalEditOriginsDestinations },
  name: 'tableOriginsDestinations',
  props: ['rows', 'form', 'loading', 'isExpress', 'tabIndex', 'rate', 'changeTabs', 'selectAll', 'changeSelectedRows', 'rowSelected', 'allSelection'],
  data() {
    return {
      baseService: new BaseServices(this),
      schema: [],
      arraySelectedIds: [],
      selectedAll: false,
      selectedRows: [],
      buttons: [],
      dataDuplicateCoverage: {},
      isShow: 'show',
      selectedRow: {},
      keyModalDuplicateCoverages: 0,
      schemaActions: {label: 'Acciones', key: 'actionsRows', class: 'text-right', style: { width: '10%'}, useSlot: true},
      schemaCountry: {label: 'País', key: 'country', class: 'text-left', useSlot: true}
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    rowSelected() {
      if (!this.rowSelected.length) {
        this.selectedRows = []
      }
    },
    allSelection() {
      if (!this.allSelection) {
        this.selectedAll = false
      }
    }
  },
  methods: {
    setInitialData() {
      this.schema = [
        {label: '', key: 'showCheckbox', class: 'text-left'},
        {label: 'Nombre', key: 'name', class: 'text-left w-100'}
      ]
      if (this.rate.rate_type !== 'int' && this.m_getElementIndex(this.schema, 'key', 'actions') === -1) {
        this.schema.push(this.schemaActions)
      } else if (this.rate.rate_type === 'int') {
        if (this.m_getElementIndex(this.schema, 'key', 'country') === -1) this.schema.splice(1, 0, this.schemaCountry)
        if (this.m_getElementIndex(this.schema, 'key', 'actions') !== -1) this.schema.splice(this.m_getElementIndex(this.schema, 'key', 'actions'), 1)
      }
    },
    changeSelected() {
      this.$emit('changeSelectedRows', this.selectedRows)
    },
    selectAllCheck (value) {
      this.selectedAll = value
      this.selectedRows = value === true ? this.rows.map(({id}) => id) : []
      this.$emit('selectAll', value)
    },
    showOrHideRow(id, isHidden) {
      this.selectedAll = false
      this.arraySelectedIds = [id]
      if (!!isHidden) this.isShow = 'show'
      if (!isHidden) this.isShow = 'hide'
      this.showOrHide()
    },
    show(isShow) {
      if (this.buttons[isShow ? 0 : 1]?.icon) this.buttons[0].icon = 'LoaderIcon'
      this.buttons.forEach(el => el.disabled = true)
      this.isShow = isShow
      this.showOrHide()
    },
    showOrHide() {
      this.loading.notLoading = true
      let queryParams = {
        origin_ids: this.arraySelectedIds,
        all: this.selectedAll
      }
      const params = {
        country_id: this.form.country.id,
        carrier_id: this.form.carrier.id,
        service_id: this.form.service.id,
        rate_id: this.rate?.id,
        originsOrDestinations: 'origins'
      }
      if (this.isShow === 'show') params.showOrHide = 'show'
      if (this.isShow === 'hide') params.showOrHide = 'hide'
      if (this.tabIndex === 1) {
        params.originsOrDestinations = 'destinations'
        queryParams = {
          all: this.selectedAll,
          destination_ids: this.arraySelectedIds
        }
      }
      this.baseService.callService('putShowOrHidden', queryParams, params)
        .then(() => {
          this.$newSuccess('¡Cambio realizado con éxito!')
        })
        .catch(err => {
          this.$alert('Error al realizar lo solicitud', {code: err})
        })
        .finally(() => {
          this.$emit('changeTabs')
          this.selectedRows = []
          this.selectedAll = false
          this.buttons.forEach(el => el.disabled = false)
          if (this.buttons[0]?.icon) this.buttons[0].icon = 'EyeIcon'
          if (this.buttons[1]?.icon) this.buttons[1].icon = 'EyeOffIcon'
        })
    },
    getRows() {
      this.$emit('changeTabs')
    },
    openModalDuplicate(value) {
      this.keyModalDuplicateCoverages++
      const place = this.rows.find(el => el.id === value)
      this.dataDuplicateCoverage = {
        placesWitchTariff: this.form.origins?.rows,
        row: value,
        country: this.form.country,
        origin: place,
        carrier: this.form.carrier,
        service: this.form.service,
        rateId: this.rate?.id
      }
      this.$bvModal.show('ModalDuplicateCoverage')
    },
    openModalEditOriginsDestinations(id) {
      this.rateId = this.rate.id
      this.selectedRow = this.rows.find(el => el.id === id)
      this.$bvModal.show('ModalEditOriginsDestinations')
    }
  }
}
</script>
