<template>
  <div class="d-grid">
      <div :class="$session.get('cas_user').permissions_v2['superadmin-manager'] ? 'dropdown-activity-multi' : 'dropdown-activity-single'">
        <span class="align-content-center ml-1" v-if="$session.get('cas_user').permissions_v2['superadmin-manager']">
          <b-badge variant="warning">
          {{data.numbersPendingActivities.quantity > 99 ? '99+' : data.numbersPendingActivities.quantity}}</b-badge>
          <a @click="filterPending" class="lnk lnk-primary">  Pendiente Validación CM</a>
        </span>
        <b-dropdown right variant="link">
          <template #button-content><feather-icon icon="ColumnsIcon" /> Columnas</template>
          <b-dropdown-form class="dropdown-width">
            <b-form-checkbox-group
              id="checkbox-columns"
              v-model="selectedColumns"
              :options="optionsColumns"
              value-field="key"
              text-field="label"
              name="checkboxColumns"
              stacked
              checked
              class="dropdown-additional-charges"
            >
            </b-form-checkbox-group>
          </b-dropdown-form>
        </b-dropdown>
      </div>
      <b-table
        responsive
        id="tableActivity"
        tbody-tr-class=""
        :items="data.rows"
        :fields="schema"
        striped
        table-variant="none"
        show-empty
        :empty-html="empty"
        :key="activityKey"
      >
        <template #empty="" v-if="!empty">
          <span class="text-center w-full push-left">{{ 'No se encontraron cargos para mostrar' }}</span>
        </template>
        <template v-slot:cell(actionsRows)="data">
          <div class="d-flex justify-content-center actions-rows" v-if="$session.get('cas_user').permissions_v2['superadmin-manager'] && data.item.status === 'Pendiente Aprobación'">
            <b-button :disabled="loading.approveRejectRateActivities" variant="outline-danger" class="separate-buttons" size="sm" @click="declineCharge(data)">
              <span v-if="(rowSelected === data.item.id && approveOrDecline === 'approve') || rowSelected !== data.item.id">Rechazar</span>
              <span v-if="rowSelected === data.item.id && approveOrDecline === 'decline'" class="d-flex"><feather-icon icon="LoaderIcon" class="spinner"/> Rechazar</span>
            </b-button>
            <b-button :disabled="loading.approveRejectRateActivities" variant="outline-success" size="sm" @click="acceptCharge(data)">
              <span v-if="(rowSelected === data.item.id && approveOrDecline === 'decline') || rowSelected !== data.item.id">Aprobar</span>
              <span v-if="rowSelected === data.item.id && approveOrDecline === 'approve'" class="d-flex"><feather-icon icon="LoaderIcon" class="spinner"/> Aprobar</span>
            </b-button>
          </div>
          <span v-else class="text-muted">--</span>
        </template>
        <template v-for="(currentSlot, index) in selectedColumns" v-slot:[`cell(${currentSlot})`]="data">
          <div :class="currentSlot === 'status' ? 'status-charge' : ''" :key="`div_${index}`">
            <span
              v-if="currentSlot === 'status'"
              :class="`table_dot--state ${$options.filters.getStatusClasses()[getNumberStatus(data.item[currentSlot])]}`"
              :key="`first_span_${index}`"
            >
            </span>
            <span :key="`second_span_${index}` ">{{ `${data.item[currentSlot]}` }}</span>
          </div>
        </template>
        <template v-slot:cell(files)="data">
          <a v-if="data.item.observations_file" :href="data.item.observations_file" target="_blank" class="text-primary"><feather-icon icon="FileTextIcon"/></a>
          <span v-else class="text-muted">--</span>
        </template>
      </b-table>
  </div>
</template>
<script>
import BaseServices from '@/store/services/index'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { mapGetters } from 'vuex'

export default {
  components: { FeatherIcon },
  props: ['data', 'getList'],
  data() {
    return {
      baseService: new BaseServices(this),
      schema: [],
      activityKey: 0,
      optionsColumns: [
        { key: 'carrierName', label: 'Courier'},
        { key: 'serviceName', label: 'Servicio' },
        { key: 'updatedAt', label: 'Fecha de modificación' },
        { key: 'user', label: 'Usuario' },
        { key: 'rangeDate', label: 'Rango vigencia', useSlot: true },
        { key: 'tariffType', label: 'Tipo tarifa', useSlot: true },
        { key: 'clientId', label: 'ID Cliente' },
        { key: 'version', label: 'Versión' },
        { key: 'status', label: 'Estado carga' },
        { key: 'observations', label: 'Observaciones' },
        { key: 'files', label: 'Archivos', useSlot: true, class: 'text-center'}
      ],
      originalIndex: ['carrierName', 'serviceName', 'updatedAt', 'user', 'rangeDate', 'tariffType', 'clientId', 'version', 'status', 'observations', 'files', 'actionsRows'],
      columns: [],
      selectedColumns: [],
      empty: '',
      loading: {
        additionalCharges: false,
        approveRejectRateActivities: false
      },
      rowSelected: null,
      approveOrDecline: null
    }
  },
  computed: {
    ...mapGetters({
      approveRejectRateActivities: 'putApproveRejectRateActivities'
    })
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    selectedColumns() {
      this.setSchema()
      localStorage.setItem('activitySchema', JSON.stringify(this.selectedColumns))
    }
  },
  methods: {
    setInitialData() {
      this.setSchema()
      this.getAdditionalChargesSchema()
      this.columns = []
    },
    setSchema() {
      const columns = []
      this.selectedColumns.forEach((column) => {
        const columnFound = this.optionsColumns.find((col) => col.key === column)
        if (columnFound) {
          columns.push(columnFound)
          columns.sort((a, b) => this.originalIndex.indexOf(a.key) - this.originalIndex.indexOf(b.key))
        }
      })
      if (this.$session.get('cas_user')?.permissions_v2['superadmin-manager']) columns.push({ key: 'actionsRows', stickyColumn: true, label: 'Acciones', useSlot: true, class: 'text-center'})
      this.schema = columns
      this.empty = this.data.rows?.length ? 'true' : ''
      this.activityKey++
    },
    getAdditionalChargesSchema() {
      if (localStorage.getItem('activitySchema')) {
        this.selectedColumns = JSON.parse(localStorage.getItem('activitySchema'))
      } else {
        let defaulColumns = ['carrierName', 'serviceName', 'updatedAt', 'rangeDate', 'tariffType', 'version', 'status', 'observations', 'files']
        if (!!this.$session.get('cas_user').permissions_v2['superadmin-manager']) defaulColumns = defaulColumns.filter((el) => el !== 'version')
        this.selectedColumns = defaulColumns
      }
    },
    declineCharge(val) {
      this.$confirm('¿Está seguro que desea rechazar tarifa?', (result) => this.declineTariff(val, result), 'Rechazar tarifa', 'Sí', 'No', '', '', '', 'textarea', 'Observaciones', 'Escriba aquí sus comentarios')
    },
    acceptCharge(val) {
      this.$confirm('¿Está seguro que desea aprobar la tarifa?', () => this.approveTariff(val), 'Aprobar tarifa', 'Sí', 'No')
    },
    declineTariff(val, result) {
      this.approveOrDecline = 'decline'
      this.rowSelected = val.item.id
      const queryParams = {
        approved: false,
        observations: result
      }
      this.callPostApproveRejectRateActivities(val.item.id, queryParams, '¡Estado actualizado con éxito!', 'La carga de tarifa ha sido rechazada y ha pasado al estado “Rechazada”.')
    },
    approveTariff(val) {
      this.approveOrDecline = 'approve'
      this.rowSelected = val.item.id
      const queryParams = {
        approved: true
      }
      this.callPostApproveRejectRateActivities(val.item.id, queryParams, '¡Estado actualizado con éxito!', 'La carga de tarifa ha sido aprobada y ha pasado al estado “Cargada con éxito”.')
    },
    callPostApproveRejectRateActivities(id, data, msg) {
      const params = {charged_rate_activiyty_id: id}
      this.loading.approveRejectRateActivities = true
      this.baseService.callService('putApproveRejectRateActivities', data, params)
        .then(() => {
          this.$newSuccess(msg)
          setTimeout(() => {
            this.$emit('getList')
          }, 500)
        })
        .catch((err) => {
          this.$alert('Error al realizar lo solicitud', {code: err})
        })
        .finally(() => {
          this.loading.approveRejectRateActivities = false
          this.rowSelected = null
          this.approveOrDecline = null
        })
    },
    filterPending() {
      this.$emit('getList', 'filterPending')
    },
    getNumberStatus(status) {
      switch (status) {
      case 'Procesando carga':
        return 1
      case 'Error en la carga':
        return 12
      case 'Pendiente Aprobación':
        return 14
      case 'Cargada con éxito':
        return 10
      case 'Rechazada':
        return 4
      }
    }
  }
}
</script>
<style>
  .status-charge {
    display: flex;
  }
  .status-charge :first-child {
      margin-right: 0.5rem;
      padding: 0.5rem;
  }
  .separate-buttons {
    margin-right: 0.5rem;
  }
  .dropdown-activity-multi {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dropdown-activity-single {
    text-align: right;
  }
</style>
