<template>
  <div>
    <b-modal 
      centered 
      id="ModalDuplicateCoverage" 
      size="sm"
      modal-class="custom-dialog dialog-800"
      hide-footer
      :title="$t('Duplicar tarifa')"
      @close="close"
      no-close-on-esc
      no-close-on-backdrop
    >
      <b-table
        :items="rows"
        :fields="schema"
        responsive="sm"
        ref="tableModalDuplicateCoverage"
        class="table_modal_duplicate_coverage"
      >
      </b-table>
      <form-render
        :form.sync="form"
        :fields="fields"
        ref="formRenderCovaerages"
        containerButtonsClass="col-sm-4 mt-2"
        :key="keyFormRender"
      >
      </form-render>
      <div class="footer-modal-coverage">
        <b-button class="float-right mt-1 mb-1" variant="warning" @click="confirm" :disabled="loading.replicateCoverages || !(form.origins && form.origins.length)">
          <span class="mb-1" v-if="!loading.replicateCoverages">Confirmar</span>
          <span class="mt-1 p-2" v-if="loading.replicateCoverages"><b-spinner small  /> </span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BaseServices from '@/store/services/index'
import { mapGetters } from 'vuex'

export default {
  name: 'ModalHistory',
  props: ['data', 'keyModalDuplicateCoverages'],
  data() {
    return {
      baseService: new BaseServices(this),
      rows:[],
      schema: [],
      form: {
        origins: []
      },
      fields: [],
      keyFormRender: 0,
      loading: {
        replicateCoverages: false
      }
    }
  },
  computed: {
    ...mapGetters({
    
    })
  },
  watch: {
    'keyModalDuplicateCoverages'() {
      this.setInitialData()
    }
  },
  methods: {
    setInitialData() {
      this.rows = [{
        country: this.capitalizeWords(this.data.country.text),
        origin: this.capitalizeWords(this.data.origin.text),
        courier: this.capitalizeWords(this.data.carrier.name),
        service: this.capitalizeWords(this.data.service.name)
      }]
      this.schema = [
        {label: 'País', key: 'country', class: 'text-center'},
        {label: 'Origen', key: 'origin', class: 'text-center'},
        {label: 'Courier', key: 'courier', class: 'text-center'},
        {label: 'Servicio', key: 'service', class: 'text-center'}
      ]
      this.fields = [
        {
          fieldType: 'FieldSelect',
          useLabel: true,
          name: 'origins',
          label: 'Orígenes a los que aplica',
          validation: 'required',
          containerClass: 'col-12 col-md-12 col-lg-12 container-info',
          multiple: true,
          max: 6,
          placeholder: 'Ingrese hasta 6 orígenes ',
          clearable: true,
          searchOnType: {
            fx: this.setPlaces,
            nChars: 3,
            debounce: 600,
            allowSearchAsValue: false,
            persistSearch: true
          }
        }
      ]
    },
    setPlaces(value) {
      const params = {
        country_id: this.data.country.id
      }
      const queryParams = {
        search: value
      }
      return this.baseService
        .callService('getPlacesRatesQuoter', queryParams, params)
        .then((resp) => {
          const data = resp.data.places.map((el) => {
            let name = el.full_name
            if (this.data.country.code === 'CO') name = `${el.name} - ${el.code}`
            if (this.data.country.code === 'PE') name = `${el.name} - ${el.code}`
            return {
              ...el,
              value: el.id,
              text: name
            }
          })
          return data
        })
        .catch((err) => {
          return err
        })
    },
    capitalizeWords(str) {
      const words = str.split(' ')
      for (let i = 0; i < words.length; i++) {
        let word = words[i]
        word = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        words[i] = word
      }
      return words.join(' ')
    },
    confirm() {
      const html = '<div style="font-size: 19px; font-weight: 400;"><p>Esta acción solo creará tarifas en los orígenes que no tengan una tarifa cargada actualmente. Si es que el origen ya tiene tarifa, se mantendrá la tarifa actual.</p><p>¿Está seguro que de realizar esta acción?</p></div>'
      this.$confirm(html, () => this.ok(), '¡Advertencia!', 'Confirmar', '', '', '', '600px')
    },
    ok() {
      const params = {
        carrier_id: this.data.carrier.id,
        service_id: this.data.service.id,
        rate_id: this.data.rateId,
        origin_id: this.data.origin.id
      }
      const queryParams = {
        data: this.form.origins.map(el => el.id)
      }
      this.loading.replicateCoverages = true
      this.baseService.callService('putReplicateCoverages', queryParams, params)
        .then((resp) => {
          this.$newSuccess('¡Duplicación de tarifas Ok!')
        })
        .catch(err => {
          this.loading.replicateCoverages = false
          this.$alert('Error al realizar lo solicitud', {code: err})
        })
        .finally(() => {
          this.loading.replicateCoverages = false
          this.close()
        })
    },
    close() {
      this.form = {}
      this.$bvModal.hide('ModalDuplicateCoverage')
    }
  }
}
</script>