<template>
	<div class="d-grid">
		<div class="columns-right" v-if="!loading.additionalCharges">
			<b-dropdown right variant="link" class="">
				<template #button-content><feather-icon icon="ColumnsIcon" /> Columnas</template>
				<b-dropdown-form class="dropdown-width">
					<b-form-checkbox-group
						id="checkbox-columns"
						v-model="selectedColumns"
						:options="optionsColumns"
						value-field="key"
						text-field="label"
						name="checkboxColumns"
						stacked
						checked
						class="dropdown-additional-charges"
					>
					</b-form-checkbox-group>
				</b-dropdown-form>
			</b-dropdown>
		</div>
		<b-table
			responsive
			id="tableAdditionalCharges"
			tbody-tr-class=""
			:items="rows"
			:fields="schema"
			class="additional-charges-table"
			striped
			table-variant="none"
			show-empty
			:empty-html="empty"
      :key="keyTableRender"
			v-if="!loading.additionalCharges"
		>
			<template #empty="" v-if="!empty">
				<span class="text-center w-full push-left">{{ 'No se encontraron cargos para mostrar' }}</span>
			</template>
			<template v-slot:cell(actionsRows)="data">
				<div v-if="editingRow && editingRow.index === data.index" class="d-flex justify-content-center actions-rows">
					<b-button variant="none" size="sm" @click="saveEdit(data)">
						<feather-icon icon="CheckIcon" class="text-success" v-b-tooltip.hover title="Guardar" />
					</b-button>
					<b-button variant="none" size="sm" @click="cancelEdit(data)">
						<feather-icon icon="XIcon" class="text-danger" v-b-tooltip.hover title="Cancelar" />
					</b-button>
				</div>
				<div v-else class="d-flex justify-content-center actions-rows">
					<b-button variant="none" size="sm" @click="editRow(data)" :disabled="!!editingRow">
						<feather-icon icon="Edit2Icon" class="text-primary" v-b-tooltip.hover title="Editar" />
					</b-button>
				</div>
			</template>
			<template v-for="(currentSlot, index) in selectedColumns" v-slot:[`cell(${currentSlot})`]="data">
				<b-form-select
					v-if="editingRow && editingRow.index === data.index"
					class="text-nowrap column-select-additional-charges"
					:id="`select-reason-${index}`"
					:key="index"
					v-model="data.item[currentSlot][currentSlot]"
					:options="['Si', 'No']"
          @change="changeSelect(currentSlot, data.item[currentSlot][currentSlot], data.index)"
				></b-form-select>
				<span v-else :key="index ">{{ `${data.item[currentSlot][currentSlot]}` }}</span>
			</template>

			<template v-for="amountSlot, index in detailsColumnsAmount" v-slot:[`cell(${amountSlot.key})`]="data">
				<div class="d-flex w-100" :key="index + 2">
					<b-form-input
						:key="index"
						v-if="editingRow && editingRow.index === data.index"
						v-model="data.item[amountSlot.key.split('.')[0]][`${amountSlot.key.split('.')[1]}_not_format`]"
            :disabled="data.item[amountSlot.key.split('.')[0]].disabled"
						class="text-nowrap column-amount-additional-charges"
					>
					</b-form-input>
					<b-form-select
						v-if="editingRow && editingRow.index === data.index"
						class="text-nowrap column-select-amount-additional-charges"
						:id="`select-isPercentage-${index}`"
						:key="index + 1"
						v-model="data.item[amountSlot.key.split('.')[0]].is_percentage"
            :disabled="data.item[amountSlot.key.split('.')[0]].disabled"
						:options="['%', currencySimbol[form.country.code]]"
					></b-form-select>
					<span v-else :key="index ">{{  data.item[amountSlot.key.split('.')[0]][amountSlot.key.split('.')[1]] || '--' }}</span>
				</div>
			</template>
			<template v-for="(descriptionSlot, index) in detailsColumnsDescription" v-slot:[`cell(${descriptionSlot.key})`]="data">
				<b-form-textarea
					:key="index"
					v-if="editingRow && editingRow.index === data.index"
					v-model="data.item[descriptionSlot.key.split('.')[0]][descriptionSlot.key.split('.')[1]]"
          :disabled="data.item[descriptionSlot.key.split('.')[0]].disabled"
					class="column-description-additional-charges"
				></b-form-textarea>
				<span v-else :key="index ">{{  data.item[descriptionSlot.key.split('.')[0]][descriptionSlot.key.split('.')[1]] || '--' }}</span>
			</template>
			<template v-slot:cell(space)>
				<div class="space-additional-charges sticky-column-additional-charges"></div>
			</template>
		</b-table>
		<div v-if="loading.additionalCharges">
			<b-skeleton height="40px" width="`calc(100vh - 2.5rem)`" class="mt-1 mb-1" />
			<div class="table-skeleton-tableQuoter">
				<b-skeleton-table :rows="5" :columns="6" :table-props="{ }" />
			</div>
		</div>
	</div>
</template>
<script>
import BaseServices from '@/store/services/index'
export default {
  props: ['rows', 'form', 'activeLoading', 'getList'],
  data() {
    return {
      baseService: new BaseServices(this),
      schema: [],
      keyTableRender: 0,
      optionsColumns: [
        { key: 'returning', label: 'Devolución'},
        { key: 'reshipments', label: 'Reexpediciones' },
        { key: 'failed_pickup', label: 'Retiro Fallido' },
        { key: 'others', label: 'Otros' },
        { key: 'packaging', label: 'Empaque' },
        { key: 'extended_delivery_hours', label: 'Jornada Extendida' },
        { key: 'documents_returning', label: 'Retorno documento' },
        { key: 'address_correction', label: 'Correccion de direccion' },
        { key: 'extra_large_package', label: 'Paquete Extragrande' },
        { key: 'freight_collect', label: 'Flete por cobrar' },
        { key: 'return_label', label: 'Etiqueta Retorno' },
        { key: 'special_handling', label: 'Manejo Especial' },
        { key: 'weight', label: 'Peso' },
        { key: 'fuel', label: 'Cargo por combustible' }
      ],
      columns: [],
      editingRow: null,
      editedRow: null,
      selectedColumns: [],
      detailsColumnsAmount: [],
      detailsColumnsDescription: [],
      empty: '',
      currency: '',
      currencySimbol: {
        CL: '$',
        CO: '$',
        PE: 'S/',
        MX: '$'
      },
      loading: {
        additionalCharges: false
      },
      inputAmount: 0
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    selectedColumns() {
      this.setSchema()
      localStorage.setItem('additionalChargesSchema', JSON.stringify(this.selectedColumns))
    }
  },
  methods: {
    setInitialData() {
      this.setSchema()
      this.getAdditionalChargesSchema()
      this.columns = [
        { key: 'returning', label: 'Devolución', useSlot: true},
        { key: 'returning.amount', label: 'Cobro Devolución' },
        { key: 'returning.description', label: 'Detalle Devolución' },
        { key: 'reshipments', label: 'Reexpediciones', useSlot: true },
        { key: 'reshipments.amount', label: 'Cobro Reexpediciones' },
        { key: 'reshipments.description', label: 'Detalle Reexpediciones' },
        { key: 'failed_pickup', label: 'Retiro Fallido', useSlot: true },
        { key: 'failed_pickup.amount', label: 'Cobro Retiro Fallido' },
        { key: 'failed_pickup.description', label: 'Detalle Retiro Fallido' },
        { key: 'others', label: 'Otros', useSlot: true },
        { key: 'others.amount', label: 'Cobro Otros' },
        { key: 'others.description', label: 'Detalle Otros' },
        { key: 'packaging', label: 'Empaque', useSlot: true },
        { key: 'packaging.amount', label: 'Cobro Empaque' },
        { key: 'packaging.description', label: 'Detalle Empaque' },
        { key: 'extended_delivery_hours', label: 'Jornada Extendida', useSlot: true },
        { key: 'extended_delivery_hours.amount', label: 'Cobro Jornada Extendida' },
        { key: 'extended_delivery_hours.description', label: 'Detalle Jornada Extendida' },
        { key: 'documents_returning', label: 'Retorno documento', useSlot: true },
        { key: 'documents_returning.amount', label: 'Cobro Retorno documento' },
        { key: 'documents_returning.description', label: 'Detalle Retorno documento' },
        { key: 'address_correction', label: 'Correccion de direccion', useSlot: true },
        { key: 'address_correction.amount', label: 'Cobro Correccion de direccion' },
        { key: 'address_correction.description', label: 'Detalle Correccion de direccion' },
        { key: 'extra_large_package', label: 'Paquete Extragrande', useSlot: true },
        { key: 'extra_large_package.amount', label: 'Cobro Paquete Extragrande' },
        { key: 'extra_large_package.description', label: 'Detalle Paquete Extragrande' },
        { key: 'freight_collect', label: 'Flete por cobrar', useSlot: true },
        { key: 'freight_collect.amount', label: 'Cobro Flete por cobrar' },
        { key: 'freight_collect.description', label: 'Detalle Flete por cobrar' },
        { key: 'return_label', label: 'Etiqueta Retorno', useSlot: true },
        { key: 'return_label.amount', label: 'Cobro Etiqueta Retorno' },
        { key: 'return_label.description', label: 'Detalle Etiqueta Retorno' },
        { key: 'special_handling', label: 'Manejo Especial', useSlot: true },
        { key: 'special_handling.amount', label: 'Cobro Manejo Especial' },
        { key: 'special_handling.description', label: 'Detalle Manejo Especial' },
        { key: 'weight', label: 'Peso', useSlot: true },
        { key: 'weight.amount', label: 'Cobro Peso' },
        { key: 'weight.description', label: 'Detalle Peso' },
        { key: 'fuel', label: 'Cargo por combustible', useSlot: true },
        { key: 'fuel.amount', label: 'Cobro Cargo por combustible' },
        { key: 'fuel.description', label: 'Detalle Cargo por combustible' }
      ]
    },
    setSchema() {
      const columns = []
      this.detailsColumnsAmount = this.columns.filter((column) => column.key.includes('.amount'))
      this.detailsColumnsDescription = this.columns.filter((column) => column.key.includes('.description'))
      this.selectedColumns.forEach((column) => {
        const columnFound = this.columns.find((col) => col.key === column)
        if (columnFound) {
          columns.push(columnFound)
          columns.push(this.columns.find((col) => col.key === `${column}.amount`))
          columns.push(this.columns.find((col) => col.key === `${column}.description`))
        }
      })
      columns.splice(0, 0, { key: 'carrier_name', label: 'Operador', disabled: true})
      columns.splice(1, 0, { key: 'service_name', label: 'Servicio', disabled: true })
      columns.push({ key: 'space', label: ' ', class: 'space-additional-charges sticky-column-additional-charges', useSlot: true})
      columns.push({ key: 'actionsRows', stickyColumn: true, label: 'Acciones', useSlot: true, class: 'sticky-column-additional-charges text-center'})
      this.schema = columns
      this.empty = this.rows.length ? 'true' : ''
    },
    changeSelect(name, value, index) {
      if (value === 'No') {
        this.rows[index][name]['amount_not_format'] = null
        this.rows[index][name]['description'] = null
        this.rows[index][name]['disabled'] = true
      } else {
        this.rows[index][name]['disabled'] = false
      }
      this.keyTableRender++
    },
    getAdditionalChargesSchema() {
      if (localStorage.getItem('additionalChargesSchema')) {
        this.selectedColumns = JSON.parse(localStorage.getItem('additionalChargesSchema'))
      } else {
        localStorage.setItem('additionalChargesSchema', JSON.stringify(this.selectedColumns))
      }
    },
    editRow(data) {
      this.editingRow = data
      this.editedRow = { ...data } // Guarda una copia de los datos originales
    },
    cancelEdit() {
      this.editingRow = null
      this.editedRow = null
    },
    saveEdit(val) {
      const newData = JSON.parse(JSON.stringify(val.item))
      Object.keys(newData).map(key => {
        if (typeof (newData[key]) === 'object') {
          newData[key].is_percentage = !!(newData[key].is_percentage === '%')
          newData[key].is_active = !!(newData[key][key] === 'Si')
          newData[key].amount = newData[key].amount_not_format ? (parseFloat(((newData[key].amount_not_format?.toString())?.replace(/[^0-9.-]/g, '')))) : null
          delete newData[key][key]
          delete newData[key].amount_not_format
          delete newData[key].disabled
        }
      })
      const sendData = {}
      Object.keys(newData).forEach(key => {
        if (typeof newData[key] === 'object') sendData[key] = newData[key]
      })
      this.editingRow = null
      this.editedRow = null
      this.putAdditionalCharges(sendData)
    },
    putAdditionalCharges(data) {
      this.loading.additionalCharges = true
      this.baseService.callService('putAdditionalCharges', data)
        .then(() => {
          this.$emit('activeLoading')
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err.message}))
          this.$emit('getList')
        })
        .finally(() => {
          this.loading.additionalCharges = false
        })
    }
  }
}
</script>
<style>
.d-grid {
  display: grid;
}
.dropdown-width {
  min-width: max-content;
}
.dropdown-additional-charges > .custom-checkbox {
  margin: 1rem 0;
}
.sticky-column-additional-charges {
  background-color: inherit !important;
  position: sticky;
  z-index: 9;
  right: 0;
}
.actions-rows {
  padding: 0;
  background: inherit !important;
  z-index: 9;
}
tr > .space-additional-charges {
  right: 7.2rem;
  background-color: #FBFBFB !important;
  max-width: 1rem;
  width: 1rem;
  padding: 0 0.5rem !important;
  border: none;
  z-index: 9;
}
.additional-charges-table {
  background: #ffffff !important;
}
.additional-charges-table tbody > tr > td {
    background: #ffffff !important;
}
.table-headers-additional-charges {
  z-index: 0;
}
.columns-right {
  justify-content: flex-end;
  right: 8rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
}
.column-select-additional-charges {
  min-width: 70px;
}
.column-select-amount-additional-charges {
  min-width: 60px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.column-description-additional-charges {
  min-width: 150px;
}
.column-amount-additional-charges {
  min-width: 70px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
</style>
