<template>
    <table-render 
      :schema.sync="schema" 
      :rows="rows"
      :stickyHeader="`calc(100vh - 2.5rem)`"
      id="rates-costs-table"
      :tableClass="'with-scroll'"
    >
    </table-render>
</template>

<script >
export default {
  props: ['rows'],
  data() {
    return {
      schema: []
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.schema = [
        {label: 'Código trayecto operador', key: 'code', class: ['text-left']},
        {label: 'Nombre trayecto operador', key: 'name', class: ['text-left']},
        {label: 'Código trayecto enviame', key: 'codeEnviame', class: ['text-left']},
        {label: 'Nombre trayecto enviame', key: 'nameEnviame', class: ['text-left']},
        {label: '% Flete manejo', key: 'formatPercentageCost', class: ['text-left']},
        {label: 'Mínimo flete manejo', key: 'minimumCost', class: ['text-left']},
        {label: 'Mínimo Para Aplicar Flete', key: 'minCostToApplyFreight', class: ['text-left']}
      ]
    }
  }
}
</script>