<template>
  <div>
    <b-card class="quoterCard">
      <form-render
        class="fieldQuoter"
        :fields="fields"
        :form.sync="form"
        @send="sendForm"
        :buttonSend="buttonSend"
        containerButtonsClass="col-sm-8 col-md-3 col-lg-4 mt-2 mb-4 pl-0 pr-0"
      >
        <template #buttons>
          <b-button  variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1 mr-1" @click="cleanFilter">
            <feather-icon icon="RefreshCwIcon"/>
          </b-button>
          <b-button variant="link" class="assign--buttons--link" @click="openModalAdvanceFilters" v-b-modal.ModalAdvanceFilters >Búsqueda avanzada</b-button>
        </template>
      </form-render>
      <ModalAdvanceFilters v-if="firstChargeModalAdvanceFilters" ref="ModalAdvanceFilters" @getList="getList" :data="dataModalAdvanceFilters"></ModalAdvanceFilters>
      <div v-if="loading.activityRates && showTable">
        <b-skeleton height="40px" width="`calc(100vh - 2.5rem)`" class="mt-1 mb-1" />
        <div class="table-skeleton-tableQuoter">
          <b-skeleton-table :rows="10" :columns="10" :table-props="{ }" />
        </div>
      </div>
      <activity-list v-if="!loading.activityRates && showTable" :data="dataActivityList" @getList="getList"></activity-list>
      <pagination v-if="!loading.activityRates && showTable" :pagination="pagination" :noDigits="true"/>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalAdvanceFilters from '../general-components/AdvanceFilters.vue'
import ActivityList from './components/ActivityList.vue'

export default {
  name: 'ActivityClient',
  props: ['dataUrl'],
  components: {ModalAdvanceFilters, ActivityList},
  data () {
    return {
      form: {},
      fields: [],
      buttonSend: { icon: 'SearchIcon', title: 'Buscar', color: 'warning', disabled: false },
      loading: {
        activityRates: false
      },
      showTable: false,
      firstChargeModalAdvanceFilters: false,
      dataModalAdvanceFilters: {},
      dataActivityList: {numbersPendingActivities: {quantity: 0}},
      isFirstCharge: true,
      pagination: {
        page: 1,
        pageSize: 20
      }
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountriesOriginsModal',
      carriers: 'getCarriersForCountry',
      generalLoading: 'getLoading',
      pendingActivities: 'getPendingActivities',
      activityRates: 'getActivityRates'
    })
  },
  watch: {
    countries() {
      this.setCountries()
    },
    carriers() {
      this.setCarriers()
    },
    pendingActivities() {
      this.setPendingActivities()
    },
    activityRates() {
      this.dataActivityList.rows = this.activityRates.rows.map(el => {
        let versionProp = ''
        switch (el.type) {
        case 'future':
          versionProp = 'Programada'
          break
        case 'current':
          versionProp = 'Actual'
          break
        case 'expired':
          versionProp = 'Expirada'
          break
        }
        return {
          ...el,
          updatedAt: this.$options.filters.dbDateToFormat(el.updatedAt, 'dd/LL/y'),
          rangeDate: `${this.$options.filters.dbDateToFormat(el.validityDate, 'dd/LL/yyyy')}${el.expirationDate ? ' - ' : ''}${el.expirationDate ? this.$options.filters.dbDateToFormat(el.expirationDate, 'dd/LL/yyyy') : ''}`,
          tariffType: `${el.rateType} ${el.rateValidityType}`,
          version: versionProp,
          observations: el.observations || '--'
        }
      })
    },
    generalLoading: {
      handler () {
        this.loading.activityRates = this.generalLoading.getActivityRates
        this.buttonSend.disabled = this.generalLoading.getActivityRates
      },
      deep: true
    },
    'pagination.page'() {
      this.getList()
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fields = [
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'country', disabled: false, label: 'País', validation: 'required', containerClass: 'col-sm-8 col-md-3 col-lg-2 fieldSelect', change: this.changeCountry, addFlags: true, sort: true},
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'carrier', label: 'Courier', containerClass: 'col-sm-8 col-md-3 col-lg-2', change: this.changeCarrier, dependency: 'country', sort: true},
        { fieldType: 'FieldInput', useSkeleton: false, useLabel: true, name: 'company_id', disabled: false, label: 'ID Cliente', containerClass: 'col-sm-8 col-md-3 col-lg-2 fieldSelect', dependency: 'country'},
        { fieldType: 'FieldInput', type: 'email', useSkeleton: false, useLabel: true, name: 'enviame_user', label: 'Usuario', containerClass: 'col-sm-8 col-md-3 col-lg-2', dependency: 'country'}
      ]
      this.$store.dispatch('fetchService', { name: 'getCountriesOriginsModal' })
    },
    setCountries() {
      const index = this.fields.findIndex(el => el.name === 'country')
      this.fields[index].options = this.countries.rows
      const checkDataUrl = ['country_id', 'carrier_id', 'status', 'tab', 'user_email']
      const results = this.checkElementsExistence(checkDataUrl, this.dataUrl)
      if (results && this.isFirstCharge) this.setCountryForUrl()
      this.setSkeletonField('country', false)
    },
    checkElementsExistence (checkDataUrl, dataArray) {
      return checkDataUrl.map(element => ({element, exists: element in dataArray}))
    },
    setCarriers() {
      const index = this.fields.findIndex(el => el.name === 'carrier')
      if (index !== -1) this.fields[index].options = this.carriers.rows
      if (this.isFirstCharge) this.setCarrierUrl()
      this.fields.forEach(el => { if (el.name !== 'country') {
        this.setSkeletonField(el.name, false)
      } })
    },
    setPendingActivities() {
      if (this.pendingActivities.rows.filter(el => el.country === this.form.country.code)[0]) {
        this.dataActivityList.numbersPendingActivities = this.pendingActivities.rows.filter(el => el.country === this.form.country.code)[0]
      } else {
        this.dataActivityList.numbersPendingActivities = {quantity: 0}
      }
    },
    setCountryForUrl() {
      this.form.country = this.countries.rows.find(el => el.id === parseInt(this.dataUrl.country_id))
      this.changeCountry('country', this.countries.rows.find(el => el.id === parseInt(this.dataUrl.country_id)))
    },
    setCarrierUrl() {
      this.form.carrier = this.carriers.rows.find(el => el.id === parseInt(this.dataUrl.carrier_id))
      this.changeCarrier('carrier', this.carriers.rows.find(el => el.id === parseInt(this.dataUrl.carrier_id)))
      this.chargeDataUrl()
      this.isFirstCharge = false
    },
    chargeDataUrl() { 
      if (this.dataUrl.status === 'Pendiente Aprobación') this.form.status = {id: '4', text: 'Pendiente Aprobación'}
      this.form.enviame_user = this.dataUrl.user_email
      this.getList()
    },
    setSkeletonField (name, bool) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].useSlot = bool
      this.fields[index].useSkeleton = bool
      this.fields[index].useLabel = bool
    },
    changeCountry(name, value) {
      if (value) {
        this.setSkeletonField('carrier', true)
        this.$store.dispatch('fetchService', { name: 'getCarriersForCountry', params: { country_id: value.id } })
      }
      if (!this.isFirstCharge) this.showTable = false
      this.changeCarrier('carrier', null)
    },
    changeCarrier(name, value) {
      if (!value) delete this.form.carrier
      this.showTable = false
    },
    sendForm() {
      delete this.form.isAdvanceFilters
      this.getList()
    },
    getList(val) {
      if (val === 'filterPending') this.form.status = {id: '4', text: 'Pendiente Aprobación'}
      this.getActivity(this.getQueryParams())
    },
    getActivity(queryParams) {
      this.$store.dispatch('fetchService', { name: 'getActivityRates', params: {country_id: this.form.country.id}, queryParams })
      this.dataActivityList.form = this.form
      if (this.$session.get('cas_user')?.permissions_v2['superadmin-manager']) this.$store.dispatch('fetchService', { name: 'getPendingActivities' })
      this.showTable = true
    },
    getQueryParams() {
      const queryParams = {}
      if (this.form?.carrier) queryParams.carrier = this.form.carrier.id
      if (this.form?.company_id) queryParams.company_id = this.form.company_id
      if (this.form?.enviame_user) queryParams.enviame_user = this.form.enviame_user
      if (this.form?.status) queryParams.status = this.form.status.text
      if (this.form?.start_time) queryParams.start_time = this.form.start_time
      if (this.form?.end_time) queryParams.end_time = this.form.end_time
      queryParams.page = this.pagination.page
      return queryParams
    },
    cleanFilter() {
      this.showTable = false
      this.form = {}
    },
    openModalAdvanceFilters() {
      this.dataModalAdvanceFilters = {
        isActivity: true,
        countries: this.countries.rows,
        form: this.form
      }
      this.firstChargeModalAdvanceFilters = true
    }
  }
}
</script>
<style scoped>
.quoterCard {
  padding-right: 0;  
  padding-left: 0;  
}
.assign--buttons--link {
  padding-left: 0;
  padding-right: 0;
}
</style>