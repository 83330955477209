<template>
  <div>
    <div>
      <b-table
        responsive="sm"
        :key="dataList.keyTableRender"
        :items="rows"
        :fields="schema"
        borderless="borderless"
        show-empty
        striped
      >
        <template #empty="">
          <span class="text-center w-full push-left">{{ 'No se encontraron datos para mostrar' }}</span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['dataList'],
  data() {
    return {
      rows: [],
      schema: []
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    'dataList.rows'() {
      this.setInitialData()
    },
    'dataList.keyTableRender'() {
      this.setInitialData()
    }
  },
  methods: {
    setInitialData() {
      this.setSchema()
      this.setRows()
    },
    setSchema() {
      this.schema = [
        { key: 'carrier_name', label: 'Operador'},
        { key: 'min_val', label: 'min. COD', class:'text-left'},
        { key: 'max_val', label: 'Máx. COD', class:'text-left'},
        { key: 'percentage_cash', label: 'COD- Efectivo', class:'text-left'},
        { key: 'cash', label: 'min. pago-Efectivo', class:'text-left' },
        { key: 'percentage_instant_payment', label: 'COD- Tarjeta Debito / Prepago / PSE', class:'text-left'},
        { key: 'instant_payment', label: 'min. pago-Tarjeta Debito / Prepago / PSE', class:'text-left' },
        { key: 'percentage_credit_card', label: 'COD- Tarjeta Crédito', class:'text-left' },
        { key: 'credit_card', label: 'min. pago-Tarjeta Crédito', class:'text-left' }
      ]
    },
    setRows() {
      this.rows = this.dataList.rows.map((item) => {
        return {
          ...item,
          countryName: this.dataList.country
        }
      })
    }
  }
}
</script>
