<template>
  <div>
    <b-modal 
      centered 
      id="ModalHistory" 
      size="lg"
      modal-class="custom-dialog dialog-1000"
      hide-footer
      :title="$t(isCurrent ? 'Historial Versión Actual' : 'Historial Versión Programada')"
      @close="close"
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="card">
        <template>
          <div class="d-flex justify-content-end pt-2 pb-2">
            <b-button :disabled="loading.donwloadAll" variant="success" size="md" class="align-right" v-if="selectedRows.length" @click="donwloadAll">
              <feather-icon icon="DownloadIcon" v-if="!loading.donwloadAll"/>
              <b-spinner v-if="loading.donwloadAll" small class="align-middle"></b-spinner>
              <span class="align-middle pl-1">Descargar</span>
            </b-button>
          </div>
          <b-table
            :key="keyTableRender"
            :items="rows"
            :fields="schema"
            borderless="borderless"
            show-empty
            showCheckboxes="true"
            :selectedRows.sync="selectedRows"
            v-if="!loading.historyFileRates"
          >
            <template #cell(showCheckbox)="data">
              <b-form-checkbox :id="`${data.item.id}`" :name="`${data.item.id}`" :value="data.item.id" v-model="selectedRows"></b-form-checkbox>
            </template>
            <template #head(showCheckbox)>
              <b-form-checkbox v-model="selectedAll" @change="() => selectAll(selectedAll)"></b-form-checkbox>
            </template>
            <template #cell(actions)="row">
              <div class="d-flex justify-content-center">
                <a :href="row.item.url" v-b-tooltip.hover.top="row.item.url ? 'Descargar archivo' : 'El archivo no se puede descargar, fue creado por aumento/descuento'" class="text-primary'">
                  <feather-icon icon="DownloadIcon" size="15"></feather-icon> 
                </a>
              </div>
            </template>
          </b-table>
          <pagination :pagination="pagination" v-if="!loading.historyFileRates"/>
          <div class="table-render-skeleton"  v-if="loading.historyFileRates">
            <b-skeleton-table :rows="5" :columns="(schema.length || 5)" />
          </div>
        </template>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ModalHistory',
  props: ['keyModalHistory', 'isCurrent', 'selectedRate', 'form'],
  data() {
    return {
      rows:[],
      schema: [],
      selectedRows: [],
      actions: [],
      selectedAll: false,
      loading: {
        historyFileRates: false,
        donwloadAll: false
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 20
      },
      keyTableRender: 0,
      pageNumber: 1
    }
  },
  computed: {
    ...mapGetters({
      historyFileRates: 'getHistoryFileRates',
      historyFileInternationalRates: 'getHistoryFileInternationalRates',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    generalLoading: {
      handler () {
        this.loading.historyFileRates = !!this.generalLoading.getHistoryFileRates
      }
    },
    keyModalHistory() {
      this.getfilesHistory()
    },
    historyFileRates() {
      this.setHistoryFileRates(this.historyFileRates.rows)
    },
    historyFileInternationalRates() {
      this.setHistoryFileRates(this.historyFileInternationalRates.rows)
    },
    'pagination.page' (val) {
      this.pageNumber = val
      this.getfilesHistory()
    }
  },
  methods: {
    setInitialData() {
      this.schema = [
        {label: '', key: 'showCheckbox', class: 'text-left'},
        {label: 'Archivo', key: 'file', sortable: true, class: ['text-left']},
        {label: 'Fecha de Subida', key: 'uploadDate', sortable: true, class: ['text-center']},
        {label: 'Usuario', key: 'user', sortable: true, class: ['text-left']},
        {label: 'Acciones', key: 'actions', sortable: true, class: ['text-center']}
      ]
      this.actions = [
        { action: id => this.downloadFile(id), icon: 'DownloadIcon', text: 'Descargar' }
      ]
    },
    setHistoryFileRates(rows) {
      this.rows = rows?.map((el, index) => {
        const regex = /(\d{4}[^\d]\d{2}[^\d]\d{2})[^\d](\d{2}[^\d]\d{2}[^\d]\d{2})/
        const matches = el.file_url?.match(regex)
        const date = matches ? matches[1] : ''
        const time = matches ? matches[2].replaceAll('-', ':') : ''
        let completeDate = ''
        if (date && time) {
          completeDate = date.concat('T', time)
        }
        const newDate = completeDate ? new Date(completeDate) : ''
        const newUploadDate = newDate ? this.$options.filters.dbDateUTCToGMTlocal(newDate, 'DD/MM/YYYY HH:mm') : 'Sin info'
        const mail = el.user_email?.split('@')
        const name = mail[0]?.replace('.', ' ')
        return {
          file: !!el.file_url ? el.file_name : 'Tarifa con aumento/descuento',
          uploadDate: newUploadDate,
          user: name,
          url: el.file_url,
          id: el.id
        }
      })
      if (this.rows.length === 20) {
        this.pagination.total = (this.pageNumber * 20) + 1
      }
      this.setInitialData()
      this.keyTableRender++
    },
    getfilesHistory() {
      let serviceName = 'getHistoryFileRates'
      const params = {
        carrier_id: this.form.carrier.id,
        service_id: this.form.service.id,
        rate_id: this.selectedRate.id
      }
      const queryParams = {
        page: this.pagination.page
      }
      if (this.selectedRate.rate_type === 'int') serviceName = 'getHistoryFileInternationalRates'
      this.$store.dispatch('fetchService', { name: serviceName, params, queryParams })
    },
    close() {
      this.rows = []
      this.pagination = {
        page: 1,
        total: 1,
        limit: 20
      }
      this.selectedRows = []
      this.selectedAll = false
      this.$bvModal.hide('ModalHistory')
    },
    downloadFile(url, name) {
      if (url) {
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
      } else {
        this.$bvToast.toast('El archivo no se puede descargar, fue creado por aumento/descuento', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }
    },
    selectAll (value) {
      this.selectedAll = value
      this.selectedRows = value === true ? this.rows.map(({id}) => id) : []
    },
    donwloadAll() {
      this.loading.donwloadAll = true
      let nIntervId = null
      let count = 0
      const NumbersFiles = this.selectedRows.length
      if (NumbersFiles !== count) {
        nIntervId = setInterval(() => {
          this.downloadFile(this.rows[count].url, this.rows[count].file)
          count++
          if (NumbersFiles === count) {
            setTimeout(() => {
              this.loading.donwloadAll = false
            }, 1000)
            clearInterval(nIntervId)
          }
        }, 1000)
      }
    }
  }
}
</script>