<template>
  <div>
    <b-card class="quoterCard">
      <form-render
        ref="formRenderPreferredClients"
        :key="keyPreferredClients"
        class="fieldQuoter"
        :fields="fields"
        :form.sync="form"
        @send="getList"
        :buttonSend="buttonSend"
        containerButtonsClass="col-sm-12 col-md-12 col-lg-3 mt-2 mb-4 pl-0 pr-0"
      >
        <template #buttons>
          <b-button  variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1 mr-1" @click="cleanFilter">
            <feather-icon icon="RefreshCwIcon"/>
          </b-button>
          <b-button variant="link" class="assign--buttons--link" v-b-modal.ModalPreferredClients >Búsqueda avanzada</b-button>
        </template>
      </form-render>
      <ModalPreferredClients ref="ModalPreferredClients" :countries="countries" @getList="getList"></ModalPreferredClients>
      <div v-if="loading.preferentialRates && showTable">
        <b-skeleton height="40px" width="`calc(100vh - 2.5rem)`" class="mt-1 mb-1" />
        <div class="table-skeleton-tableQuoter">
          <b-skeleton-table :rows="5" :columns="6" :table-props="{ }" />
        </div>
      </div>
      <preferred-clients-list :dataList="dataList" v-if="!loading.preferentialRates && showTable"></preferred-clients-list>
      <pagination v-if="!loading.preferentialRates && showTable" :pagination="pagination" />
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PreferredClientsList from './components/PreferredClientsList.vue'
import ModalPreferredClients from './components/ModalFilterPreferredClients.vue'

export default {
  components: { ModalPreferredClients, PreferredClientsList },
  name: 'PreferredClient',
  data () {
    return {
      keyPreferredClients: 0,
      form: {},
      fields: [],
      buttonSend: { icon: 'SearchIcon', title: 'Buscar', color: 'warning', disabled: false },
      loading: {
        countries: true,
        carriers: true,
        preferentialRates: true
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 20
      },
      paramPage: 1,
      dataList: {},
      showTable: false
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountriesOriginsModal',
      carriers: 'getCarriersForCountry',
      preferentialRates: 'getPreferentialRates',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    countries() {
      this.setCountries()
    },
    carriers() {
      this.setCarriers()
    },
    preferentialRates() {
      this.setPreferentialRates()
    },
    generalLoading: {
      handler () {
        this.loading.countries = !!this.generalLoading.getCountriesOrigins
        this.loading.carriers = !!this.generalLoading.getCarriersForCountry
        this.loading.preferentialRates = !!this.generalLoading.getPreferentialRates
      },
      deep: true
    },
    'pagination.page'() {
      this.paramPage = this.pagination.page
      this.getList()
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fields = [
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'country', disabled: false, label: 'País', validation: 'required', containerClass: 'col-sm-8 col-md-3 col-lg-3 fieldSelect', change: this.changeCountry, addFlags: true, sort: true},
        { fieldType: 'FieldInput', useSkeleton: false, useLabel: true, name: 'idSeller', disabled: false, label: 'ID Usuario', containerClass: 'col-sm-8 col-md-3 col-lg-3 fieldSelect', change: this.changeIdSeller},
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'carriers', label: 'Courier', containerClass: 'col-sm-8 col-md-3 col-lg-3', change: this.changeCarriers, dependency: 'country', sort: true}
      ]
      this.$store.dispatch('fetchService', { name: 'getCountriesOriginsModal' })
    },
    setCountries() {
      const index = this.fields.findIndex(el => el.name === 'country')
      this.fields[index].options = this.countries.rows.map(value => ({id: value.id, code: value.code, text: value.name}))
      this.fields.forEach(el => this.setSkeletonField(el.name, false))
    },
    setCarriers() {
      const index = this.fields.findIndex(el => el.name === 'carriers')
      if (index !== -1) this.fields[index].options = this.carriers.rows
      this.fields.forEach(el => { if (el.name !== 'country') this.setSkeletonField(el.name, false) })
    },
    setSkeletonField (name, bool) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].useSlot = bool
      this.fields[index].useSkeleton = bool
      this.fields[index].useLabel = bool
    },
    setPreferentialRates() {
      this.dataList = {
        loading: {preferentialRates: this.loading.preferentialRates},
        rows: this.preferentialRates.rows,
        country: this.form.country.text
      }
    },
    changeCountry(name, value) {
      const indexCarrier = this.fields.findIndex(el => el.name === 'carriers')
      if (value) {
        this.fields[0].containerClass = 'col-sm-8 col-md-3 col-lg-3 fieldSelect'
        this.fields[indexCarrier].containerClass = 'col-sm-8 col-md-3 col-lg-3 fieldSelect'
        this.setSkeletonField('carriers', true)
        this.$store.dispatch('fetchService', { name: 'getCarriersForCountry', params: { country_id: value.id } })
      } else {
        this.fields[indexCarrier].containerClass = 'col-sm-8 col-md-3 col-lg-3'
      }
      this.showTable = false
      this.changeCarriers('carriers', null)
    },
    changeCarriers(name, value) {
      if (!value) {
        this.form.carriers = null
      }
      this.showTable = false
    },
    changeIdSeller() {
      this.showTable = false
    },
    getList(allParams) {
      if (allParams?.params) {
        this.fields[0].validation = ''
        this.form.country = allParams?.params?.country
        this.form.carriers = allParams?.queryParams?.carrier
        this.form.idSeller = allParams?.queryParams?.company_id
      } else {
        this.fields[0].validation = 'required'
      }
      this.showTable = true
      const params = {
        country_id: this.form.country.id
      }
      let queryParams = {
        page: this.paramPage
      }
      if (allParams?.queryParams) {
        this.form.carriers = allParams?.queryParams?.carrier
        this.form.idSeller = allParams?.queryParams?.company_id
      }
      queryParams = allParams?.queryParams ? { ...queryParams, ...allParams.queryParams} : queryParams
      if (this.form.carriers) queryParams.carrier = this.form.carriers.id
      if (this.form.idSeller) queryParams.company_id = this.form.idSeller
      this.$store.dispatch('fetchService', { name: 'getPreferentialRates', params, queryParams })
    },
    cleanFilter() {
      this.showTable = false
      this.form = {}
      this.fields[2].containerClass = 'col-sm-8 col-md-3 col-lg-3'
      this.fields[0].containerClass = 'col-sm-8 col-md-3 col-lg-3'
    }
  }
}
</script>
<style scoped>
.quoterCard {
  padding-right: 0;  
  padding-left: 0;  
}
.assign--buttons--link {
  padding-left: 0;
  padding-right: 0;
}
</style>