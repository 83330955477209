<template>
  <div>
    <form-render :form="form" :fields="fields" ref="formRenderNationalTariff" :key="keyFormRender">
      <template #msg="scope"><ul class="modal-msj mt-2 mb-2"><li>{{ scope.field.msg }}</li></ul></template>
      <template #applyDiscountOrIncrease>
        <div class="d-flex mt-1">
          <field-input
            name="applyDiscountOrIncrease"
            :value.sync="form.applyDiscountOrIncrease"
            placeholder="Ingrese porciento"
            type="number"
            class="col-3 inputDiscountOrIncrease"
            :change="changeApplyDiscountOrIncrease"
            :msgError="msgError"
          />
          <field-select class="col-3 selectDiscountOrIncrease" :options="optionsDiscountOrIncrease" :value.sync="form.increaseOrDiscount" :clearable="false"/>
        </div>
      </template>
    </form-render>
    <div class="footer-modal-coverage">
      <b-button class="float-right mt-1 mb-1" variant="outline-success" @click="confirm" :disabled="loading.form">
        <span v-if="!loading.form" class="mb-1">Crear Tarifa</span>
        <span class="mt-1 p-2" v-if="loading.form"><b-spinner small /> </span>
      </b-button>
    </div>
  </div>
</template>
<script>
import FieldInput from '@/views/components/custom/form-render/fields/FieldInput.vue'
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'

export default {
  components: { FieldInput },
  name: 'formRenderNationalTariff',
  data() {
    return {
      form: {increaseOrDiscount: {id: 'discount', text: 'Descuento'}},
      fields: {},
      keyFormRender: 0,
      loading: {
        form: false
      },
      baseService: new BaseServices(this),
      optionsTariffType: [{ id: 'general', text: 'General' }, { id: 'differential', text: 'Diferencial' }],
      optionsUploadOptions: [{ id: 'discountOrIncrease', text: 'Aplicar Descuento ó Aumento', class: 'col-6' }, { id: 'uploadTariffFile', text: 'Subir Archivo de Tarifa', class: 'col-6' }],
      optionsDiscountOrIncrease: [{id: 'increase', text: 'Aumento'}, {id: 'discount', text: 'Descuento'}],
      fieldApplyDiscountOrIncrease: { name: 'applyDiscountOrIncrease', useLabel: false, useSlot: true, containerClass: 'container-info col-12'},
      fileCoverage: { fieldType: 'FieldFileDropArea', name:'fileCoverage', labelFormFile: 'Subir Tarifa', containerClass: 'col-12 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true, validation: 'required' },
      fileAdditionalInfo: { fieldType: 'FieldFileDropArea', name:'fileAdditionalInfo', labelFormFile: 'Subir Información Adicional', containerClass: 'col-12 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true },
      fileFreight: { fieldType: 'FieldFileDropArea', name:'fileFreight', labelFormFile: 'Subir Fletes', containerClass: 'col-6 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true, validation: 'required' },
      fileShippingZone: { fieldType: 'FieldFileDropArea', name:'fileShippingZone', labelFormFile: 'Subir Zona de Envío', containerClass: 'col-6 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true, validation: 'required' },
      fileCPSets: { fieldType: 'FieldFileDropArea', name:'fileCPSets', labelFormFile: 'Subir Conjuntos CP', containerClass: 'col-6 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true, validation: 'required' },
      fileTransitDays: { fieldType: 'FieldFileDropArea', name:'fileTransitDays', labelFormFile: 'Subir Días de Transito por Grupos', containerClass: 'col-6 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true },
      fileLimitCoverage: { fieldType: 'FieldFileDropArea', name:'fileLimitCoverage', labelFormFile: 'Subir CP Cobertura Límitada', containerClass: 'col-6 container-info', accept: '.zip, .csv', classField:'hide-button-browse', clearable: true },
      fieldMsgDate: { name: 'msg', msg: 'Indique si prefiere actualizar la tarifa de inmediato o si desea programarla para un momento específico.', useLabel: false, useSlot: true, containerClass: 'col-12' },
      fieldStartDate: { fieldType: 'FieldDatepicker', name: 'startDate', label: 'Fecha de inicio', containerClass: 'container-info col-6', clearable: true, change: this.changeDate, validation: 'required', propsVCalendar: { 'min-date': new Date(new Date().setDate(new Date().getDate() + 1)).toISOString() } },
      fieldExpirationDate: { fieldType: 'FieldDatepicker', name: 'expirationDate', label: 'Fecha de fin real', containerClass: 'container-info col-6', clearable: true, description: 'Cumplida la fecha, la tarifa expirará y quedará vigente la tarifa base del courier', validation: 'required', change: this.changeDate, dependency: 'startDate' },
      fieldEstimatedExpirationDate: { fieldType: 'FieldDatepicker', name: 'estimatedExpirationDate', label: 'Fecha de fin aproximada', containerClass: 'container-info col-6', clearable: true, description: 'Fecha referencial, la tarifa expirará sólo al subir una nueva tarifa', dependency: 'startDate' },
      fieldUpDate: { fieldType: 'FieldCheckbox', name: 'upDate', containerClass: 'col-12 container-info', options: [{ id: 1, text: 'Actualizar ahora' }], change: this.changeCheckboxUpdate, multiple: true },
      fieldSeller: { fieldType: 'FieldSelect', useLabel: true, useSkeleton: false, name: 'idSeller', label: 'ID seller', containerClass: 'container-info col-12', validation: 'required', dependency: 'countries', clearable: true, searchOnType: { fx: this.setSeller, nChars: 2, debounce: 600, allowSearchAsValue: false, persistSearch: true } },
      timeZones: {
        CL: 'America/Santiago',
        MX: 'America/Bahia_Banderas',
        CO: 'America/Bogota',
        PE: 'America/Lima',
        AR: 'America/Argentina/Buenos_Aires',
        EC: 'America/Guayaquil'
      },
      msgError: null
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountriesOriginsModal',
      carriers: 'getCarriersOriginsModal',
      services: 'getServicesOrigins'
    })
  },
  watch: {
    countries() {
      this.setOptions('countries')
    },
    carriers() {
      this.setOptions('carriers')
    },
    services() {
      this.setOptions('services')
    }
  },
  mounted() {
    this.setInitialData()
    this.$store.dispatch('fetchService', { name: 'getCountriesOriginsModal'})
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'countries', label: 'País', validation: 'required', containerClass: 'container-info col-12', change: this.changeCountry, addFlags: true, sort: true },
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'carriers', label: 'Nombre ó Código Courier', validation: 'required', containerClass: 'container-info col-sm-6 col-md-6', sort: true, dependency: 'countries', change: this.changeCarrier},
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'services', label: 'Servicio', validation: 'required', containerClass: 'container-info col-sm-6 col-md-6', dependency: 'carriers', sort: true, change: this.changeService },
        { name: 'msg', msg: '¿Qué tipo de tarifa le gustaría crear? A continuación elija una opción...', useLabel: false, useSlot: true, containerClass: 'col-12' },
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'tariffType', label: 'Tipo de Tarifa', validation: 'required', containerClass: 'container-info col-sm-6 col-md-6', change: this.changetTariffType, dependency: 'services', options: this.optionsTariffType, sort: true },
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'validityType', label: 'Vigencia de Tarifa', validation: 'required', containerClass: 'container-info col-sm-6 col-md-6', change: this.changeValidityType, dependency: 'tariffType', sort: true }
      ]
    },
    setOptions(name) {
      this.fields[this.m_getElementIndex(this.fields, 'name', name)].options = this[name].rows
      this.m_setSkeletonField(this.fields, 'name', name, false)
    },
    async setSeller(value) {
      const params = {
        country_id: this.form.countries.id
      }
      const queryParams = {
        search: value
      }
      return await this.baseService
        .callService('getSeller', queryParams, params)
        .then((resp) => {
          return resp.data.organizations.map((el) => ({
            ...el,
            text: el.fullname,
            valid: true
          }))
        })
        .catch((err) => {
          return err
        })
    },
    setFieldsAllCountries(fields) {
      if (this.form.countries.code.toLowerCase() === 'mx') {
        this.fileCoverage.containerClass = 'col-6 container-info'
        this.fileAdditionalInfo.containerClass = 'col-6 container-info'
      } else if (this.form.countries.code.toLowerCase() === 'co') {
        if (this.form.services.type === 'express') this.fileCoverage.containerClass = 'col-12 container-info'
        else this.fileCoverage.containerClass = 'col-6 container-info'
        this.fileAdditionalInfo.containerClass = 'col-12 container-info'
      } else {
        this.fileCoverage.containerClass = 'col-12 container-info'
        this.fileAdditionalInfo.containerClass = 'col-12 container-info'
      }
      fields.forEach(el => this.fields.push(el))
    },
    changeCountry(name, value) {
      delete this.form.carriers
      this.changeCarrier()
      if (value?.id) {
        this.m_setSkeletonField(this.fields, 'name', 'carriers', true)
        this.$store.dispatch('fetchService', { name: 'getCarriersOriginsModal', params: { country_id: value.id }})
      }
    },
    changeCarrier(name, value) {
      delete this.form.services
      this.changeService()
      if (value?.id) {
        const params = { country_id: this.form.countries.id, carrier_id: value.id}
        this.m_setSkeletonField(this.fields, 'name', 'services', true)
        this.$store.dispatch('fetchService', { name: 'getServicesOrigins', params})
      }
    },
    changeService(name, value) {
      delete this.form.tariffType
      this.changetTariffType()
      if (value?.id) {
        this.isExpress = !!value.type === 'express'
        const params = {
          carrier_id: this.form.carriers.id,
          service_id: value.id
        }
      }
    },
    changetTariffType(name, value) {
      delete this.form.validityType
      this.changeValidityType()
      switch (value?.id) {
      case 'general':
        this.fields[this.m_getElementIndex(this.fields, 'name', 'validityType')].options = [
          { id: 'base', text: 'Base' }
        ]
        break
      case 'differential':
        this.fields[this.m_getElementIndex(this.fields, 'name', 'validityType')].options = [
          { id: 'indeterminate', text: 'Indefinida' }
        ]
        break
      }
      if (value) this.fields[this.m_getElementIndex(this.fields, 'name', 'validityType')].options.push({ id: 'temporary', text: 'Temporal' })
    },
    changeValidityType(name, value) {
      this.cleanForm(['uploadOptions', 'applyDiscountOrIncrease', 'increaseOrDiscount', 'startDate', 'expirationDate', 'estimatedExpirationDate', 'upDate'])
      this.changeCheckboxUpdate()
      this.fields.splice(this.m_getElementIndex(this.fields, 'name', 'validityType') + 1)
      if (value) (
        setTimeout(() => {
          if (this.m_getElementIndex(this.fields, 'name', 'uploadOptions') === -1) this.fields.push(
            { name: 'msg', msg: 'Seleccione la acción que desea tomar respecto a la tarifa previamente elegida:', useLabel: false, useSlot: true, containerClass: 'col-12' },
            { fieldType: 'FieldRadio', name: 'uploadOptions', containerClass: 'col-12 container-info', align: 'h', useSkeleton: false, customRadio:true, change: this.changeUploadOptions, options: this.optionsUploadOptions, validation: 'required' }
          )
        }, 5)
      )
    },
    changeUploadOptions(name, value) {
      if (value.id) this.fields.splice(this.m_getElementIndex(this.fields, 'name', 'uploadOptions') + 1)
      switch (value.id) {
      case 'discountOrIncrease':
        if (this.m_getElementIndex(this.fields, 'name', 'applyDiscountOrIncrease') === -1) {
          this.addFieldsDiscountOrIncrease([this.fieldApplyDiscountOrIncrease])
          this.form.increaseOrDiscount = {id: 'discount', text: 'Descuento'}
        }
        break
      case 'uploadTariffFile':
        if (this.m_getElementIndex(this.fields, 'name', 'uploadTariffFile') === -1) {
          switch (this.form.countries.code.toLowerCase()) {
          case 'cl':
          case 'pe':
            this.setFieldsAllCountries([this.fileCoverage, this.fileAdditionalInfo])
            break
          case 'co':
            if (this.form.services.type === 'express') this.setFieldsAllCountries([this.fileCoverage, this.fileAdditionalInfo])
            else this.setFieldsAllCountries([this.fileCoverage, this.fileFreight, this.fileAdditionalInfo])
            break
          case 'mx':
            this.setFieldsAllCountries([this.fileCoverage, this.fileShippingZone, this.fileCPSets, this.fileTransitDays, this.fileLimitCoverage, this.fileAdditionalInfo])
            break
          }
        }
        break
      }
      if (value.id) {
        const arrayNewFields = [this.fieldMsgDate, this.fieldStartDate, this.fieldUpDate]
        if (this.form.tariffType.id === 'differential') arrayNewFields.splice(0, 0, this.fieldSeller)
        if (this.form.validityType.id === 'indeterminate' || this.form.validityType.id === 'base') arrayNewFields.splice(this.m_getElementIndex(arrayNewFields, 'name', 'startDate') + 1, 0, this.fieldEstimatedExpirationDate)
        if (this.form.validityType.id === 'temporary') arrayNewFields.splice(this.m_getElementIndex(arrayNewFields, 'name', 'startDate') + 1, 0, this.fieldExpirationDate)
        this.addFieldsDiscountOrIncrease(arrayNewFields)
      }
    },
    changeDate(name, value) {
      if (value) {
        delete this.fields[this.m_getElementIndex(this.fields, 'name', name)].validation
      } else {
        this.fields[this.m_getElementIndex(this.fields, 'name', name)].validation = 'required'
      }
      if (name === 'startDate') {
        delete this.form.expirationDate
        delete this.form.estimatedExpirationDate
        if (this.m_getElementIndex(this.fields, 'name', 'expirationDate') !== -1) this.fields[this.m_getElementIndex(this.fields, 'name', 'expirationDate')].propsVCalendar = { 'min-date': value }
        if (this.m_getElementIndex(this.fields, 'name', 'estimatedExpirationDate') !== -1) this.fields[this.m_getElementIndex(this.fields, 'name', 'estimatedExpirationDate')].propsVCalendar = { 'min-date': value }
      }
    },
    changeCheckboxUpdate(name, value) {
      if (value?.length && this.m_getElementIndex(this.fields, 'name', 'startDate') !== -1) {
        delete this.form.startDate
        delete this.fields[this.m_getElementIndex(this.fields, 'name', 'startDate')].validation
        this.fields[this.m_getElementIndex(this.fields, 'name', 'startDate')].disabled = true
        if (this.m_getElementIndex(this.fields, 'name', 'expirationDate') !== -1) delete this.fields[this.m_getElementIndex(this.fields, 'name', 'expirationDate')]?.dependency
        if (this.m_getElementIndex(this.fields, 'name', 'estimatedExpirationDate') !== -1) delete this.fields[this.m_getElementIndex(this.fields, 'name', 'estimatedExpirationDate')]?.dependency
      } else {
        delete this.form.startDate
        if (this.m_getElementIndex(this.fields, 'name', 'startDate') !== -1) this.fields[this.m_getElementIndex(this.fields, 'name', 'startDate')].disabled = false
        if (this.m_getElementIndex(this.fields, 'name', 'startDate') !== -1) this.fields[this.m_getElementIndex(this.fields, 'name', 'startDate')].validation = 'required'
        if (this.m_getElementIndex(this.fields, 'name', 'expirationDate') !== -1) this.fields[this.m_getElementIndex(this.fields, 'name', 'expirationDate')].dependency = 'startDate'
        if (this.m_getElementIndex(this.fields, 'name', 'estimatedExpirationDate') !== -1) this.fields[this.m_getElementIndex(this.fields, 'name', 'estimatedExpirationDate')].dependency = 'startDate'
      }
    },
    changeApplyDiscountOrIncrease() {
      if (this.form.applyDiscountOrIncrease?.length) {
        this.msgError = null
        return true
      } else {
        this.msgError = 'Este campo es requerido y debe ser númerico, ejemplo 3.5%'
        return false
      }
    },
    cleanForm(arrayFields) {
      arrayFields.forEach(element => {
        delete this.form[element]
      })
    },
    addFieldsDiscountOrIncrease(arrayFields) {
      arrayFields.forEach(element => {
        this.fields.push(element)
      })
    },
    async confirm() {
      const isCheck = await this.$refs.formRenderNationalTariff.onlyCheckForm()
      const checkApplyDiscountOrIncrease = this.fields[this.m_getElementIndex(this.fields, 'name', 'applyDiscountOrIncrease')] ? this.changeApplyDiscountOrIncrease() : true
      if (isCheck && checkApplyDiscountOrIncrease) {
        let html = ''
        let title = ''
        const isDiferential = this.form.tariffType.id === 'differential'
        const isTemporal = this.form.validityType.id === 'temporary'
        const isPercentaje = this.form.uploadOptions.id === 'discountOrIncrease'
        const isUpdate = this.form.upDate?.length
        if (isUpdate && !isDiferential && !isTemporal && isPercentaje) {
          html = '<p>Esta acción <span style="font-size: 19px; font-weight: 700;">actualizará la tarifa actual.</span></p><div style="font-size: 19px; font-weight: 400;"><p>¿Está seguro que desea modificar tarifa base?</p></div>'
          title = '<h1 style="font-size: 33px; font-weight: 600; color: #595959;">Crear tarifa</h1>'
          return this.$confirm(html, () => this.ok(), title, 'Confirmar', '', '', '', '560px')
        } else if (isUpdate && !isDiferential && !isTemporal && !isPercentaje) {
          html = '<p>Esta acción <span style="font-size: 19px; font-weight: 700;">expirará la tarifa actual.</span></p><div style="font-size: 19px; font-weight: 400;"><p>¿Está seguro que desea crear tarifa base?</p></div>'
          title = '<h1 style="font-size: 33px; font-weight: 600; color: #595959;">Crear tarifa</h1>'
          return this.$confirm(html, () => this.ok(), title, 'Confirmar', '', '', '', '560px')
        } else if (isUpdate && isDiferential && !isTemporal) {
          html = '<p>Esta acción <span style="font-size: 19px; font-weight: 700;">creará una tarifa diferencial nueva</span> 0 <span style="font-size: 19px; font-weight: 700;">expirará la actual si existe.</span></p><div style="font-size: 19px; font-weight: 400;"><p>¿Está seguro que desea crear tarifa diferencial con vigencia indefinida?</p></div>'
          title = '<h1 style="font-size: 33px; font-weight: 600; color: #595959;">Crear tarifa</h1>'
          return this.$confirm(html, () => this.ok(), title, 'Confirmar', '', '', '', '560px')
        } else if (isTemporal) {
          html = `<div style="font-size: 19px; font-weight: 400;"><p>Esta tarifa entrará en vigencia desde el <p><span style="font-size: 19px; font-weight: 700;">${isUpdate ? this.$options.filters.moment(new Date(), 'DD-MM-YYYY') : this.$options.filters.moment(new Date(this.form.startDate), 'DD-MM-YYYY')}</span> hasta el <span style="font-size: 19px; font-weight: 700;">${this.$options.filters.moment(new Date(this.form.expirationDate), 'DD-MM-YYYY')}</span>.</p></p><p>¿Está seguro que desea crearla?</p></div>`
          title = '<h1 style="font-size: 33px; font-weight: 600; color: #595959;">Crear tarifa temporal</h1>'
          return this.$confirm(html, () => this.ok(), title, 'Confirmar', '', '', '', '560px')
        } else if (!isUpdate) {
          html = `<div style="font-size: 19px; font-weight: 400;"><p>Esta tarifa entrará en vigencia el <p><span style="font-size: 19px; font-weight: 700;">${this.$options.filters.moment(new Date(this.form.startDate), 'DD-MM-YYYY')}</span>.</p></p><p>¿Está seguro que desea crearla?</p></div>`
          title = '<h1 style="font-size: 33px; font-weight: 600; color: #595959;">Crear tarifa</h1>'
          return this.$confirm(html, () => this.ok(), title, 'Confirmar', '', '', '', '560px')
        }
        this.ok()
      }
    },
    ok() {
      let name = ''
      const params = {
        country_id: this.form.countries.id,
        carrier_id: this.form.carriers.id,
        service_id: this.form.services.id
      }
      this.data = { user_id: this.$session.get('cas_user').id }
      if (this.form.idSeller && this.form.services.type !== 'express') params.company_id = this.form.idSeller.id
      if (this.form.idSeller && this.form.services.type === 'express') this.data.company_id = this.form.idSeller.id
      if (this.form.fileCoverage) this.data.file = this.form.fileCoverage
      if (this.form.fileFreight && !this.form.idSeller?.id) {
        this.data.freight_type = 'general'
        this.data.freight_file = this.form.fileFreight
      } else if (this.form.fileFreight && this.form.idSeller?.id) {
        this.data.freight_type = 'client'
        this.data.freight_file = this.form.fileFreight
      }
      if (this.form.carriers.type === 'big' && this.form.services.type !== 'express' && this.form.fileCPSets && this.form.fileShippingZone && this.form.fileCoverage) {
        this.data = {
          ['file-cp-coverages']: this.form.fileCoverage,
          ['file-cp-ranges']: this.form.fileCPSets,
          ['file-cp-zones']: this.form.fileShippingZone
        }
        if (this.form.fileTransitDays)  this.data['file-transit-days-group'] = this.form.fileTransitDays
        if (this.form.fileLimitCoverage) this.data['file-cp-limit-coverage'] = this.form.fileLimitCoverage
      }
      if (this.form.startDate) this.data.validity_date = this.$options.filters.moment(this.form.startDate, 'YYYY-MM-DD')
      if (this.form.estimatedExpirationDate) this.data.estimated_expiration_date = this.$options.filters.moment(this.form.estimatedExpirationDate, 'YYYY-MM-DD')
      if (this.form.expirationDate) this.data.expiration_date = this.$options.filters.moment(this.form.expirationDate, 'YYYY-MM-DD')
      if (this.form.validityType) this.data.validity_type = this.form.validityType.id
      if (this.form.upDate?.length) this.data.update_now = !!this.form.upDate.length
      if (this.form.fileAdditionalInfo) this.data.additional_info_file = this.form.fileAdditionalInfo
      if (this.form.applyDiscountOrIncrease && this.form.increaseOrDiscount.text === 'Aumento') {
        this.data.percentage_increase = this.form.applyDiscountOrIncrease
      } else if (this.form.applyDiscountOrIncrease) {
        this.data.percentage_discount = this.form.applyDiscountOrIncrease
      }
      name = this.checkUseService()
      const data = {
        params,
        data: this.data,
        name
      }
      this.sendData(data)
    },
    checkUseService() {
      if (this.form.services.type === 'express') {
        return 'postCoverageKm'
      } else if (this.form.carriers.type === 'big' && !this.form.idSeller?.id) {
        return 'postBigCarriers'
      } else if (this.form.carriers.type === 'big' && this.form.idSeller?.id) {
        return 'postBigCarriersPreferential'
      } else if (this.form.carriers.type !== 'big' && this.form.idSeller?.id) {
        return 'postCoveragePreferential'
      } else if (this.form.carriers.type !== 'big' && !this.form.idSeller?.id) {
        return 'postCoverage'
      }
    },
    sendData(data) {
      this.loading.form = true
      this.baseService
        .callUploadFileCustomParams(data.name, data.data, data.params, data.user_id)
        .then(() => {
          this.$newSuccess('¡Procesando nueva tarifa!', 'Al final le notificaremos al correo.', 'small')
        })
        .catch((err) => {
          this.$newError('Ocurrio un error.', err.message, 'small')
          this.loading.form = false
        })
        .finally(() => {
          this.loading.form = false
          this.$bvModal.hide('ModalNewImport')
        })
    }
  }
}
</script>
<style lang="scss">
  .inputDiscountOrIncrease, .selectDiscountOrIncrease {
    padding: 0px;
    z-index: 9;
  }
  .selectDiscountOrIncrease div{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: hidden ;
  }
</style>
