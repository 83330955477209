<template>
  <div>
    <b-modal
      centered
      id="ModalShowHideBulk"
      size="md"
      modal-class="custom-dialog dialog-500"
      hide-footer
      title="Mostrar/Ocultar Masivo"
      @close="close"
    >
      <form-render
        :form.sync="form"
        :fields="fields"
        ref="formModalShowHideBulk"
        containerButtonsClass="col-sm-4 mt-2"
        :key="keyFormRender"
      >
        <template #labelFile>
          <label>Archivo de importación</label>
          <span class="text-danger"> *</span>
          <b-button size="sm" :href="url" variant="link" target="_blank"
            >( Descargar Formato )
          </b-button>
        </template>
      </form-render>
      <b-button variant="outline-primary" class="quoterButton mt-2 mb-2" @click="validateForm" :disabled="loading.massiveAction">
        <span v-if="loading.massiveAction" class="mt-0 pl-2 pr-2"><b-spinner small  /> </span>
        <span v-if="!loading.massiveAction" class="mb-1">Confirmar</span>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'

export default {
  name: 'ModalShowHideBulk',
  props: ['search', 'rate', 'tabIndex', 'isExpress'],
  data() {
    return {
      baseService: new BaseServices(this),
      form: {},
      fields: [],
      keyFormRender: 0,
      url: 'https://storage.googleapis.com/formato-archivos-subida-akatsuki/origenes_destinos%20template.csv',
      optionsType: [
        { id: 'origins/destinations', text: 'Orígenes/Destinos' },
        { id: 'routes', text: 'Rutas' }
      ],
      loading: {
        massiveAction: false
      }
    }
  },
  computed: {
    ...mapGetters({
    })
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      if (this.isExpress) this.optionsType = [{ id: 'origins/destinations', text: 'Orígenes/Destinos' }]
      this.fields = [
        {fieldType: 'FieldSelect', name: 'action', label: 'Acción', containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', options: [{id: 'hide', text: 'Ocultar', code: 'hide'}, {id: 'show', text: 'Mostrar', code: 'show'}], sort: true},
        {fieldType: 'FieldRadio', name: 'typeFile', type: 'type', label: 'Tipo de archivo', align: 'h', options: this.optionsType, change: this.changeType, containerClass: 'col-12 container-info mt-2', validation: 'required' },
        { name: 'labelFile', useSlot: true, containerClass: 'col-12 mt-2' },
        { fieldType: 'FieldFileDropArea', name: 'file', validation: 'required', containerClass: 'col-sm-12 col-md-12 col-lg-12', accept: '.csv', classField:'hide-button-browse', labelFormFile: 'Seleccionar archivo', styleSmall: true}
      ]
      this.form = { action: {id: 'hide', text: 'Ocultar', code: 'hide'} }
    },
    async validateForm () {
      this.$refs.formModalShowHideBulk.checkForm()
      const isCheck = await this.$refs.formModalShowHideBulk.onlyCheckForm()
      if (isCheck) this.sendForm()
    },
    sendForm() {
      this.loading.massiveAction = true
      const params = {
        country_id: this.search.country.id,
        carrier_id: this.search.carrier.id,
        service_id: this.search.service.id,
        rate_id: this.rate?.id,
        type: this.form.typeFile.id,
        action: this.form.action.id
      }
      const data = {file: this.form.file}
      this.baseService.callUploadFileCustomParams('putShowOrHiddenMassive', data, params)
        .then(() => {
          this.$newSuccess(`¡${this.form.action.text} ${this.tabIndex ? 'destinos' : 'orígenes'} masivo Ok!`)
          if (this.tabIndex === 0) {
            this.$emit('getRows', 'getOriginsNY')
          } else if (this.tabIndex === 1) {
            this.$emit('getRows', 'getDestinationsNY')
          }
        })
        .catch((err) => {
          this.loading.massiveAction = false
          this.$alert(err.message, { code: err.code })
        })
        .finally(() => {
          this.loading.massiveAction = false
          this.close()
        })
    },
    close() {
      this.form = {action: {id: 'hide', text: 'Ocultar', code: 'hide'}}
      this.$bvModal.hide('ModalShowHideBulk')
    }
  }
}
</script>
