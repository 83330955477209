<template>
    <b-modal 
      centered 
      id="ModalCostSimulator" 
      size="sm"
      modal-class="dialog-600 modal-cost-simulator"
      hide-footer
      @close="close"
    >
      <h2 class="text-center">Simulador de costos</h2>
      <h6 class="text-center mb-4">Complete los datos de cada paso para generar el archivo.</h6>

      <div v-for="(container, index) of containers" class="container-quoter-rows ml-3 mr-3" :key="`container${index}`">
        <div class="container-quoter-number">{{ index + 1 }}</div>
        <form-render
          :ref="`formRenderCostSimulator${index}`"
          :key="keyForm[container.keyFormRender]"
          class="fieldQuoter"
          :fields="container.field"
          :form.sync="form"
        >
          <template #couriersTags>
            <p>{{ keyForm[container.keyFormRender] }}</p>
            <b-badge class="badge__cnt" v-for="(data, index) in valueCourierTags" :key="`${index}_valueCourierTags`">
              {{data.text}}
              <b-button variant="outline" class="border-0" size="sm" @click="removeCouriersTags(index)">
                <feather-icon icon="XIcon" size="20" class="warning color_icon" />
              </b-button>
            </b-badge>
          </template>
          <template #classificationTags>
            <p>{{ keyForm[container.keyFormRender] }}</p>
            <b-badge class="badge__cnt" v-for="(data, index) in valueClassificationTags" :key="`${index}_valueClassificationTags`">
              {{data.text}}
              <b-button variant="outline" class="border-0" size="sm" @click="removeClassificationTags(index)">
                <feather-icon icon="XIcon" size="20" class="warning color_icon" />
              </b-button>
            </b-badge>
          </template>
          <template #labelfile>
            <label>Archivo operación</label>
            <span class="text-danger"> *</span>
            <b-button size="sm" v-if="form.country && url" :href="url" variant="link" target="_blank">( Descargar Formato ) </b-button>
          </template>
        </form-render>
      </div>
      <div class="footer-modal-simulator-cost justify-content-center">
        <b-button class="" variant="success" @click="validateForm" :disabled="loading.simulatorCostFile">
          <span v-if="!loading.simulatorCostFile" class="mb-1">Generar archivo</span>
          <span class="mt-1 p-2" v-if="loading.simulatorCostFile"><b-spinner small  /> </span>
        </b-button>
      </div>
    </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'

export default {
  name: 'ModalCostSimulator',
  props: ['formTariffs', 'countries'],
  data() {
    return {
      baseService: new BaseServices(this),
      keyModalCostSimulator: 0,
      form: {},
      fields: [],
      url: '',
      valueCourierTags: [],
      containers: [],
      keyForm: { 3: 0 },
      valueClassificationTags: [],
      fieldObj: {
        stepOne: [],
        stepTwo: [],
        stepThree: [],
        stepFour:[]
      },
      loading: {
        simulatorCostFile: false
      }
    }
  },
  computed: {
    ...mapGetters({
      couriers: 'getCarriersOriginsModal',
      servicesTypes: 'getServiceTypesQuoter'
    })
  },
  watch: {
    couriers() {
      this.setCouriers()
    },
    servicesTypes() {
      this.setServicesTypes()
    },
    valueCourierTags() {
      if (this.valueCourierTags.length || this.form.checkboxAllCouriers) {
        this.fieldObj.stepTwo[this.m_getElementIndex(this.fieldObj.stepTwo, 'name', 'courier')].validation = ''
      } else {
        this.fieldObj.stepTwo[this.m_getElementIndex(this.fieldObj.stepTwo, 'name', 'courier')].validation = 'required'
      }
    },
    valueClassificationTags() {
      if (this.valueClassificationTags.length) {
        this.fieldObj.stepThree[this.m_getElementIndex(this.fieldObj.stepThree, 'name', 'classification')].validation = ''
      } else {
        this.fieldObj.stepThree[this.m_getElementIndex(this.fieldObj.stepThree, 'name', 'classification')].validation = 'required'
      }
    }
  },
  methods: {
    setInitialData() {
      this.fieldObj.stepOne = [
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'country', disabled: false, label: 'País', validation: 'required', containerClass: 'col-12 mb-1 fieldSelect', change: this.changeCountry, addFlags: true, sort: true, options: this.countries.rows }
      ]
      this.fieldObj.stepTwo = [
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'courier', label: 'Courier', containerClass: 'col-12 required-not-background', clearable: true, dependency: 'country', change: this.changeCouriers, sort: true, placeholder: 'Seleccione hasta 5 couriers'},
        { name: 'couriersTags', useSlot: true, containerClass: 'col-12' },
        { fieldType: 'FieldCheckbox', name: 'checkboxAllCouriers', containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info', align: 'h', options: [{id: 1, text: 'Simular con todos los couriers disponibles'}], change: this.changeCheckboxAllCouriers, dependency: 'country'},
        { name: 'simulatorMultiCourier', containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info', useSlot: true }
      ]
      this.fieldObj.stepThree = [
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'classification', label: 'Clasificación servicio', validation: 'required', disabled: false, containerClass: 'col-12', clearAfterSelect: true, dependency: 'country', placeholder: 'Seleccione hasta 5 categorías', change: this.changeClassification, sort: true, clearable: false},
        { name: 'classificationTags', useSlot: true, containerClass: 'col-12' }
      ]
      this.fieldObj.stepFour = [
        { name: 'labelfile', useSlot: true, containerClass: 'col-12' },
        { fieldType: 'FieldFileDropArea', name: 'file', validation: 'required', containerClass: 'col-12 mb-1', accept: '.xlsx, .xls', classField:'hide-button-browse', labelFormFile: 'Seleccionar archivo', styleSmall: true}
      ]
      this.containers = [
        {field: this.fieldObj.stepOne, keyFormRender: 0},
        {field: this.fieldObj.stepTwo, keyFormRender: 1},
        {field: this.fieldObj.stepThree, keyFormRender: 2},
        {field: this.fieldObj.stepFour, keyFormRender: 3}
      ]
    },
    setCouriers() {
      if (this.m_getElementIndex(this.fieldObj.stepTwo, 'name', 'courier') !== -1) {
        this.fieldObj.stepTwo[this.m_getElementIndex(this.fieldObj.stepTwo, 'name', 'courier')].options = this.couriers.rows
        this.m_setSkeletonField(this.fieldObj.stepTwo, 'name', 'courier', false)
      }
    },
    setFieldsProperties (fields, name, propertyField, value) {
      if (this.m_getElementIndex(fields, 'name', name) !== -1) {
        fields[this.m_getElementIndex(fields, 'name', name)][propertyField] = value
      }
    },
    setServicesTypes() {
      this.fieldObj.stepThree[this.m_getElementIndex(this.fieldObj.stepThree, 'name', 'classification')].options = this.servicesTypes.rows.filter(el => el.type !== 'express' && el.type !== 'international')
      this.m_setSkeletonField(this.fieldObj.stepThree, 'name', 'classification', false)
    },
    checkValidation (fields, name, validationName, placeholderName) {
      const field = fields[this.m_getElementIndex(fields, 'name', name)]
      // Eliminar o agregar el valor requerido en el input de destino para que al momento de enviar el formulario no interrumpa el proceso debido a como se muestra en la UI los tags de destinos, llos valores se toman del fieldSelect pero se agregan a un array "valueTags"
      if (validationName.length >= 1 && field.validation === 'required') this.setFieldsProperties(fields, name, 'validation', '')
      if (validationName.length === 0 && field.validation === '') this.setFieldsProperties(fields, name, 'validation', 'required')
      //No permite agregar mas de 5 categorías
      if (validationName.length >= 5) {
        this.setFieldsProperties(fields, name, 'disabled', true)
        this.setFieldsProperties(fields, name, 'placeholder', `Ingresaste el máximo de 5 ${placeholderName}`)
      } else if (field.disabled) {
        this.setFieldsProperties(fields, name, 'disabled', false)
        this.setFieldsProperties(fields, name, 'placeholder', `Seleccione hasta 5 ${placeholderName}`)
      }
    },
    changeCountry(name, value) {
      this.containers[1].field[2].value = true
      this.m_setSkeletonField(this.fieldObj.stepTwo, 'name', 'courier', true)
      this.m_setSkeletonField(this.fieldObj.stepThree, 'name', 'classification', true)
      this.clearForm()
      if (value) {
        this.getCouriersAndServicesTypes()
        this.getFormat(value)
      } else {
        this.resetField('stepTwo', 'courier')
        this.resetField('stepThree', 'classification')
      }
    },
    changeCouriers(name, value) {
      this.m_setSkeletonField(this.fieldObj.stepTwo, 'name', 'courier', true)
      this.form[name] = undefined
      if (this.valueCourierTags.some(ele => ele?.id === value?.id) || !value) {
        this.resetField('stepTwo', 'courier')
        return
      }
      this.valueCourierTags.push(value)
      this.resetField('stepTwo', 'courier')
      this.checkValidation(this.fieldObj.stepTwo, 'courier', this.valueCourierTags, 'couriers')
    },
    changeClassification(name, value) {
      this.m_setSkeletonField(this.fieldObj.stepThree, 'name', 'classification', true)
      if (!(this.valueClassificationTags.some(ele => ele?.id === value?.id)) || !value) {
        this.valueClassificationTags.push(value)
      }
      this.form[name] = undefined
      this.resetField('stepThree', 'classification')
      this.checkValidation(this.fieldObj.stepThree, 'classification', this.valueClassificationTags, 'categorías')
    },
    changeCheckboxAllCouriers(name, value) {
      if (value) {
        this.valueCourierTags = []
        this.containers[1].field[0].validation = ''
        this.setFieldsProperties(this.containers[1].field, 'courier', 'disabled', true)
      } else {
        this.setFieldsProperties(this.containers[1].field, 'courier', 'disabled', false)
        this.containers[1].field[0].validation = 'required'
      }
    },
    resetField(array, name) {
      setTimeout(() => {
        this.m_setSkeletonField(this.fieldObj[array], 'name', name, false)
      }, 100)
    },
    getFormat(value) {
      switch (value.code.toLowerCase()) {
      case 'cl':
        this.url = 'https://storage.cloud.google.com/formato-archivos-subida-akatsuki/simulator/t8-1486/simulator-formato-subida-CL.xlsx'
        break
      case 'co':
        this.url = 'https://storage.cloud.google.com/formato-archivos-subida-akatsuki/simulator/t8-1486/simulator-formato-subida-CO.xlsx'
        break
      case 'mx':
        this.url = 'https://storage.cloud.google.com/formato-archivos-subida-akatsuki/simulator/t8-1486/simulator-formato-subida-MX.xlsx'
        break
      case 'pe':
        this.url = 'https://storage.cloud.google.com/formato-archivos-subida-akatsuki/simulator/t8-1486/simulator-formato-subida-PE.xlsx'
        break
      }
    },
    getCouriersAndServicesTypes () {
      const params = {
        country_id: this.form.country.id
      }
      this.$store.dispatch('fetchService', { name: 'getCarriersOriginsModal', params })
      this.$store.dispatch('fetchService', { name: 'getServiceTypesQuoter', params })
    },
    removeCouriersTags (index) {
      this.valueCourierTags.splice(index, 1)
      this.checkValidation(this.fieldObj.stepTwo, 'courier', this.valueCourierTags, 'couriers')
    },
    removeClassificationTags (index) {
      this.valueClassificationTags.splice(index, 1)
      this.checkValidation(this.fieldObj.stepThree, 'classification', this.valueClassificationTags, 'categorías')
    },
    clearForm () {
      this.valueCourierTags = []
      this.valueClassificationTags = []
      this.changeCheckboxAllCouriers()
    },
    async validateForm (e) {
      e.preventDefault()
      const value1 = await this.$refs.formRenderCostSimulator0[0].onlyCheckForm()
      const value2 = await this.$refs.formRenderCostSimulator1[0].onlyCheckForm()
      const value3 = await this.$refs.formRenderCostSimulator2[0].onlyCheckForm()
      const value4 = await this.$refs.formRenderCostSimulator3[0].onlyCheckForm()
      const allValues = [value1 && value2 && value3 && value4]
      const isCheck = allValues.every(el => el === true)
      if (isCheck) this.sendForm()
    },
    sendForm() {
      const formData = new FormData()
      if (this.form.file) formData.append('file', this.form.file)
      if (this.form.country.code) formData.append('country_code', this.form.country.code)
      if (!this.form.checkboxAllCouriers) {
        this.valueCourierTags.forEach(el => {
          formData.append('carrier_ids[]', el.id)
        })
      }
      this.valueClassificationTags.forEach(el => {
        formData.append('service_type_ids[]', el.id)
      })
      this.loading.simulatorCostFile = true
      this.baseService.callUploadFileFormData('getCostSimulatorFile', formData, {})
        .then(() => {
          this.$newSuccess('¡Envío de Simulación de costos Ok!', 'Revise la bandeja del correo asociado a su usuario envíame.', 'medium')
          this.close()
        })
        .catch((err) => {
          this.$alert(err.message, { code: err.code })
        })
        .finally(() => {
          this.loading.simulatorCostFile = false

        })
    },
    close() {
      this.form = {}
      this.valueCourierTags = []
      this.valueClassificationTags = []
      this.$bvModal.hide('ModalCostSimulator')
    }
  }
}
</script>
<style lang="scss">
  .badge__cnt{
    margin: 5px;
    background: #58bbe4;
    padding: 2px 5px;
  }
  .color_icon{ //color para el icono X de los tags
    stroke: white;
  }
  .required-not-background .invalid-feedback {
    background: #F0F7FA !important;
    padding-top: 0.25rem;
    margin-top: 0;
  }
  .separation {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    margin-bottom: 1rem;
    margin-right: 2rem;
  }
  .modal-cost-simulator {
    footer{
      justify-content: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    header {
      background-color: inherit !important;
    }
  }
  .footer-modal-simulator-cost {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
</style>