<template>
	<div class="table-toast table-toast-margins border-primary">
		<div>
			<div class="d-flex justify-content-end table-toast--close">
				<feather-icon icon="XIcon" size="1.5x" @click="closeTable" />
			</div>
			<div class="mt-1 mb-2 p-0">
				<b-table
					class="table-manage-versions-margins p-0"
					id="tableManageVersionsMmargins"
					:sortByCustom="orderBy"
					:borderless="true"
					:hover="true"
					:no-border-collapse="true"
					:items="rows"
					:fields="schema"
					selectable
					selected-variant="primary"
					:select-mode="'single'"
					@row-clicked="onRowSelected"
					ref="tableManageVersionsMargins"
					:key="tableManageVersionsMargins"
					bordered
				>
					<template v-slot:cell(validity)="data">
						<span v-if="data.item.type === ''" class="font-weight-bold">{{ data.item.validity }} <feather-icon :icon="data.item.icon" /></span>
						<span v-else>{{ data.item.validity }}</span>
					</template>
					<template v-slot:cell(type)="data">
						<span v-if="data.item.type !== '' && data.item.user_email === ''" class="font-weight-bold">{{ data.item.type }} <feather-icon :icon="data.item.icon || 'none'"/></span>
						<span v-else>{{ data.item.type }}</span>
					</template>
				</b-table>
			</div>
		</div>
	</div>
</template>
<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
export default {
  components: { FeatherIcon },
  name: 'tableManageVersionsMargins',
  props: ['rowsHistory', 'form', 'getListById'],
  data() {
    return {
      schema: [],
      rows: [],
      sortProps: null,
      selected: [],
      columns: ['type', 'validity_date', 'user_email'],
      tableManageVersionsMargins: 0
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.schema = [
        {label: 'Versión', key: 'validity', useSlot: true, class: 'text-left'},
        {label: 'Nivel', key: 'type', useSlot: true, class: 'text-left'},
        {label: 'Operador/Servicio', key: 'courierService', useSlot: true, class: 'text-left'},
        {label: 'Fecha modificación', key: 'validity_date', useSlot: true, class: 'text-left'},
        {label: 'Usuario', key: 'user_email', useSlot: true, class: 'text-left'}
      ]
      this.rows = this.rowsHistory.filter(row => !row.hideRows)
    },
    orderBy (sortAs, type) {
      if (sortAs === 'user') this.sortProps = { order_by: 'user_email'}
      else this.sortProps = { order_by: 'updated_at'}
      if (!!type) this.sortProps = {...this.sortProps, order_direction: type}
      else this.sortProps = {}
    },
    closeTable() {
      this.$emit('close')
    },
    showRows(item) {
      this.rowsHistory.forEach(row => {
        if (row.validity === item.id && (row.courierService === 'Todos/Todos' || row.icon === 'ChevronRightIcon' || row[`${row.validity_type}/${row.margin_type}`] === 1)) row.hideRows = false
        if (item.id === `${row.validity_type}/${row.margin_type}`) row.hideRows = false
      })
      this.rows = this.rowsHistory.filter(row => !row.hideRows)
    },
    hideRows(item) {
      this.rowsHistory.forEach(row => {
        if (row.validity === item.id && row.type !== '') {
          row.hideRows = true
          if (row?.icon === 'ChevronDownIcon') row.icon = 'ChevronRightIcon'
        }
        if ((item.id === `${row.validity_type}/${row.margin_type}` && row.courierService !== '')) row.hideRows = true
      })
      this.rows = this.rowsHistory.filter(row => !row.hideRows)
    },
    onRowSelected(item) {
      if (item?.icon === 'ChevronDownIcon' || item?.icon === 'ChevronRightIcon') {
        if (item.icon === 'ChevronRightIcon') {
          item.icon = 'ChevronDownIcon'
          this.showRows(item)
        } else {
          item.icon = 'ChevronRightIcon'
          this.hideRows(item)
        }
      } else if (item) {
        this.rowsHistory.forEach(row => row._rowVariant = 'none')
        this.rowsHistory.filter(el => el.id === item.id)[0]._rowVariant = 'primary'
        this.selected.push(item)
        const data = {
          page: 1,
          limit: 20,
          id: item.id
        }
        this.$emit('getListById', data)
        this.closeTable()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table-toast{
  background-color: #ffffff;
  position: absolute;
  top: 3.5rem;
  width: 150%;
  right: 0;
  border-radius: 0.5rem;
  font-size: 12px;
  box-shadow: 0px 4px 4px 0px rgb(74 74 74 / 33%);
  .table-toast--close{
    top: 0.5rem;
    position: absolute;
    right: 1.5rem;
    z-index: 3;
    svg {
      cursor: pointer;
    }
  }
  .table-manage-versions-margins{
    margin: 0;
    overflow-x: hidden;
    margin-top: 2rem;
    #tableManageVersionsMmargins div {
      padding: 0 1px;
      table > thead {
          background-color: #ffffff !important;
        > tr > th{
          background-color: #ffffff !important;
          span {
            font-size: 13px;
          }
        }
      }
    }
  }
}
.table-toast-margins {
  width: max-content;
}
.table-toast-margins {
  .table-render-skeleton{
    overflow-x: hidden;
    td {
      padding: 0;
    }
  }
}
@media (max-width: 648px) {
  .table-toast {
    width: 90%;
  }
}
.table-manage-versions-margins {
  padding: 16px;
  > table > thead > tr {
    background-color: #fff !important;
  }
}
</style>