<template>
  <div>
    <b-modal
      centered
      id="ModalNewImport"
      size="lg"
      modal-class="custom-dialog dialog-800"
      hide-footer
      :title="'Nueva tarifa'"
      @close="close"
      no-close-on-esc
      no-close-on-backdrop
    >
      <ul class="modal-msj"><li>Por favor, proporcione detalles especificos sobre la nueva tarifa que desea crear.</li></ul>
      <template>
        <form-render :form="formTariffScope" :fields="fieldsTariffScope"/>
        <FormImportNationalTariff v-if="formTariffScope.tariffScope.id === 'national'"/>
        <FormImportInternationalTariff v-if="formTariffScope.tariffScope.id === 'international'"/>
      </template>
    </b-modal>
  </div>
</template>
<script>
import FormImportInternationalTariff from './FormImportInternationalTariff.vue'
import FormImportNationalTariff from './FormImportNationalTariff.vue'

export default {
  name: 'ModalNewImport',
  components: { FormImportInternationalTariff, FormImportNationalTariff },
  data() {
    return {
      formTariffScope: {tariffScope: {id: 'national', text:  this.$t('Nacional')}},
      fieldsTariffScope: [],
      optionsTariffScope: [{id: 'national', text:  this.$t('Nacional')}, {id: 'international', text:  this.$t('Internacional')}]
    }
  },
  mounted() {
    this.fieldsTariffScope = [{ fieldType: 'FieldRadio', name: 'tariffScope', containerClass: 'container-info col-12 mb-2', align: 'h', options: this.optionsTariffScope}]
  },
  methods: {
    close() {
      this.$bvModal.hide('ModalNewImport')
    }
  }
}
</script>
