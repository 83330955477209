<template>
  <div>
    <b-modal 
      centered 
      id="ModalPreferredClients" 
      size="md"
      modal-class="custom-dialog dialog-600"
      title="Búsqueda Avanzada"
      @ok="ok"
      ok-variant="warning"
      :ok-title="$t('Buscar')"
      ok-only
    >
      <template>
        <form-render :form.sync="form" :fields="fields" :key="keyFormRender" >
        </form-render>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'modal-filter-preferred-clients',
  props: ['countries', 'getList'], 
  data() {
    return {
      form: {},
      fields: [],
      keyFormRender: 0,
      optionsDeterminationPrices: [
        {
          id: 'default',
          text: 'Por defecto'
        },
        {
          id: 'semi-preferential',
          text: 'Mixto'
        },
        {
          id: 'full-preferential',
          text: 'Preferencial'
        }
      ]
    }
  },
  mounted() {
    this.setInitialData()
  },
  computed: {
    ...mapGetters({
      carriers: 'getCarriersForCountry',
      services: 'getServiceTypesQuoter'
    })
  },
  watch: {
    countries() {
      this.setCountries()
    },
    carriers() {
      this.setCarriers()
    },
    services() {
      this.setServices()
    }
  },
  methods: {
    setInitialData() {
      this.fields = [
        {
          fieldType: 'FieldSelect',
          useSkeleton: true,
          useLabel: true,
          name: 'country',
          label: 'País',
          validation: 'required',
          containerClass: 'container-info col-sm-12 col-md-12 col-lg-12',
          change: this.changeCountry,
          addFlags: true,
          sort: true
        },
        {
          fieldType: 'FieldSelect',
          useSkeleton: true,
          useLabel: true,
          name: 'carrier',
          label: 'Courier',
          containerClass: 'container-info col-sm-12 col-md-12 col-lg-12',
          dependency: 'country',
          change: this.changeCarriers,
          sort: true
        },
        {
          fieldType: 'FieldSelect',
          useSkeleton: true,
          useLabel: true,
          name: 'serviceType',
          label: 'Categoría de Servicio',
          containerClass: 'container-info col-sm-12 col-md-12 col-lg-12',
          dependency: 'country',
          change: this.changeServices,
          sort: true
        },
        {
          fieldType: 'FieldInput',
          useSkeleton: true,
          useLabel: true,
          name: 'company_id',
          label: 'ID Usuario',
          containerClass: 'container-info col-sm-12 col-md-12 col-lg-12'
        },
        {
          fieldType: 'FieldSelect',
          useSkeleton: true,
          useLabel: true,
          name: 'flow_type',
          label: 'Determinación Precios',
          containerClass: 'container-info col-sm-12 col-md-12 col-lg-12',
          options: this.optionsDeterminationPrices,
          sort: true
        },
        { fieldType: 'FieldDatepicker',
          useSkeleton: true,
          useLabel: true,
          name: 'dateRange',
          label: this.$t('Rango de vigencia'),
          range: true,
          containerClass: 'col-sm-12',
          clearable: true
        }
      ]
    },
    setCountries() {
      const index = this.fields.findIndex(el => el.name === 'country')
      this.fields[index].options = this.countries.rows.map(value => ({id: value.id, code: value.code, text: value.name}))
      this.fields.forEach(el => this.setSkeletonField(el.name, false))
    },
    setCarriers() {
      const index = this.fields.findIndex(el => el.name === 'carrier')
      if (index !== -1) this.fields[index].options = this.carriers.rows
      this.setSkeletonField('carrier', false)
    },
    setServices() {
      const index = this.fields.findIndex(el => el.name === 'serviceType')
      if (index !== -1) this.fields[index].options = this.services.rows.map(value => ({id: value.id, code: value.code, text: value.name}))
      this.setSkeletonField('serviceType', false)
    },
    setSkeletonField (name, bool) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].useSkeleton = bool
      this.fields[index].useLabel = bool
    },
    changeCountry(name, value) {
      if (value) {
        this.setSkeletonField('carrier', true)
        this.setSkeletonField('serviceType', true)
        this.$store.dispatch('fetchService', { name: 'getCarriersForCountry', params: { country_id: value.id } })
        this.$store.dispatch('fetchService', { name: 'getServiceTypesQuoter', params: { country_id: value.id } })
      }
      this.changeCarriers('carrier', null)
    },
    changeCarriers(name, value) {
      if (!value) {
        this.form.carrier = ''
      }
    },
    changeServices(name, value) {
      if (!value) {
        this.form.serviceType = ''
      }
    },
    formatDate(date) {
      return date ? this.$options.filters.dbDateUTCToGMTlocal(date, 'YYYY/MM/DD') : ''
    },
    ok() {
      const allParams = {
        params: {
          'country': this.form.country
        },
        queryParams: {}
      }
      if (this.form?.carrier?.id) allParams.queryParams.carrier = this.form.carrier
      if (this.form?.serviceType?.id) allParams.queryParams.service_type = this.form.serviceType?.id
      if (this.form?.company_id) allParams.queryParams.company_id = this.form.company_id
      if (this.form?.flow_type?.id) allParams.queryParams.flow = this.form.flow_type?.id
      if (this.form?.dateRange?.start) allParams.queryParams.start_time = this.formatDate(this.form.dateRange?.start)
      if (this.form?.dateRange?.end) allParams.queryParams.end_time = this.formatDate(this.form.dateRange?.end)
      this.$emit('getList', allParams)
    }
  }
}
</script>