<template>
  <div>
    <b-modal 
      centered 
      id="ModalAdvanceFilters" 
      size="md"
      modal-class="custom-dialog dialog-600"
      title="Búsqueda Avanzada"
      @ok="ok"
      ok-variant="warning"
      :ok-title="$t('Buscar')"
      ok-only
      
    >
      <template>
        <form-render :form.sync="data.form" :fields="fields" :key="keyFormRender" ref="formAdvanceFilters" @send="sendForm">
        </form-render>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'modal-advance-filters',
  props: ['getList', 'data'], 
  data() {
    return {
      fields: [],
      keyFormRender: 0,
      optionsDeterminationPrices: [
        { id: 'default', text: 'Por defecto' },
        { id: 'semi-preferential', text: 'Mixto' },
        { id: 'full-preferential', text: 'Preferencial' }
      ],
      optionsStatus: [
        {id: '1', text: 'Procesando carga'},
        {id: '2', text: 'Error en la carga'},
        {id: '3', text: 'Cargada con éxito'},
        {id: '4', text: 'Pendiente Aprobación'},
        {id: '5', text: 'Rechazada'}
      ],
      serviceType: { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'serviceType', label: 'Categoría de Servicio', containerClass: 'container-info col-sm-12 col-md-12 col-lg-12', dependency: 'country', change: this.changeServices, sort: true },
      company_id: { fieldType: 'FieldInput', useSkeleton: true, useLabel: true, name: 'company_id', label: 'ID Cliente', containerClass: 'container-info col-sm-12 col-md-12 col-lg-12' },
      flow_type: { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'flow_type', label: 'Determinación Precios', containerClass: 'container-info col-sm-12 col-md-12 col-lg-12', options: this.optionsDeterminationPrices, sort: true },
      dateRange: { fieldType: 'FieldDatepicker', useSkeleton: true, useLabel: true, name: 'dateRange', label: this.$t('Rango de vigencia'), range: true, containerClass: 'col-sm-12', clearable: true },
      user: { fieldType: 'FieldInput', type: 'email', useSkeleton: true, useLabel: true, name: 'enviame_user', label: 'Usuario', containerClass: 'container-info col-sm-12 col-md-12 col-lg-12'},
      status: { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'status', label: 'Estado', containerClass: 'container-info col-sm-12 col-md-12 col-lg-12', sort: true },
      modificationDate: { fieldType: 'FieldDatepicker', useSkeleton: true, useLabel: true, name: 'modification_date', label: this.$t('Fecha de modificación'), range: false, containerClass: 'col-sm-12', clearable: true }
    }
  },
  mounted() {
    this.setInitialData()
  },
  computed: {
    ...mapGetters({
      carriers: 'getCarriersForCountry',
      services: 'getServiceTypesQuoter'
    })
  },
  watch: {
    carriers() {
      this.setCarriers()
    },
    services() {
      this.setServices()
    }
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'country', label: 'País', validation: 'required', containerClass: 'container-info col-sm-12 col-md-12 col-lg-12', change: this.changeCountry, addFlags: true, sort: true },
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'carrier', label: 'Courier', containerClass: 'container-info col-sm-12 col-md-12 col-lg-12', dependency: 'country', change: this.changeCarriers, sort: true }
      ]
      if (this.data.isActivity) {
        this.status.options = this.optionsStatus
        this.fields.push(this.company_id, this.user, this.status, this.dateRange)
      }
      this.setCountries()
    },
    setCountries() {
      const index = this.fields.findIndex(el => el.name === 'country')
      this.fields[index].options = this.data.countries
      this.fields.forEach(el => this.setSkeletonField(el.name, false))
    },
    setCarriers() {
      const index = this.fields.findIndex(el => el.name === 'carrier')
      if (index !== -1) this.fields[index].options = this.carriers.rows
      this.setSkeletonField('carrier', false)
    },
    setServices() {
      const index = this.fields.findIndex(el => el.name === 'serviceType')
      if (index !== -1) this.fields[index].options = this.services.rows.map(value => ({id: value.id, code: value.code, text: value.name}))
      this.setSkeletonField('serviceType', false)
    },
    setSkeletonField (name, bool) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].useSkeleton = bool
      this.fields[index].useLabel = bool
    },
    changeCountry(name, value) {
      if (value) {
        this.setSkeletonField('carrier', true)
        this.$store.dispatch('fetchService', { name: 'getCarriersForCountry', params: { country_id: value.id } })
        if (this.data.isPreferredClients) {
          this.setSkeletonField('serviceType', true)
          this.$store.dispatch('fetchService', { name: 'getServiceTypesQuoter', params: { country_id: value.id } })
        }
      }
      this.changeCarriers('carrier', null)
    },
    changeCarriers(name, value) {
      if (!value) {
        this.data.form.carrier = ''
      }
    },
    changeServices(name, value) {
      if (!value) {
        this.data.form.serviceType = ''
      }
    },
    formatDate(date) {
      return date ? this.$options.filters.dbDateUTCToGMTlocal(date, 'YYYY/MM/DD') : ''
    },
    async ok (e) {
      e.preventDefault()
      this.$refs.formAdvanceFilters.checkForm()
    },
    sendForm() {
      if (this.data.form.dateRange) {
        this.data.form.start_time = this.formatDate(this.data.form.dateRange?.start)
        this.data.form.end_time = this.formatDate(this.data.form.dateRange?.end)
      }
      this.data.form.isAdvanceFilters = true
      this.$emit('getList', this.data.form)
      this.$bvModal.hide('ModalAdvanceFilters')
    }
  }
}
</script>