<template>
	<div class="d-grid">
		<b-table
			id="tableMargins"
			:items="rows"
			:fields="schema"
			class="margins-table"
			striped
			hover
			table-variant="none"
			show-empty
			:empty-html="empty"
			v-if="!loading.margins"
		>
			<template #empty="" v-if="!empty">
				<span class="text-center w-full push-left">{{ 'No se encontraron cargos para mostrar' }}</span>
			</template>
			<template v-slot:cell(actionsRows)="data">
				<div v-if="editingRow && editingRow.index === data.index" class="d-flex justify-content-center actions-rows">
					<b-button variant="none" size="sm" @click="saveEdit(data)">
						<feather-icon v-if="!loading.editMargin" icon="CheckIcon" class="text-success" v-b-tooltip.hover title="Guardar" />
						<b-spinner v-if="loading.editMargin" small />
					</b-button>
					<b-button variant="none" size="sm" @click="cancelEdit(data)">
						<feather-icon icon="XIcon" class="text-danger" v-b-tooltip.hover title="Cancelar" />
					</b-button>
				</div>
				<div v-else class="d-flex justify-content-center actions-rows">
					<b-button variant="none" size="sm" @click="editRow(data)" :disabled="!!editingRow">
						<feather-icon icon="Edit2Icon" class="text-primary" v-b-tooltip.hover title="Editar" />
					</b-button>
					<b-button variant="none" size="sm" @click="confirmDelete(data)" :disabled="!!editingRow">
						<feather-icon
							v-if="!loading.deleteMargin || (removingRow && removingRow.index !== data.index)"
							icon="TrashIcon"
							class="text-danger"
							v-b-tooltip.hover
							title="Editar"
						/>
						<b-spinner v-if="loading.deleteMargin && removingRow && removingRow.index === data.index" small />
					</b-button>
				</div>
			</template>
			<template v-slot:cell(margin)="data">
				<div class="d-flex w-100">
					<b-input-group class="column-margins" append="%" v-if="editingRow && editingRow.index === data.index">
						<b-form-input v-model="data.item.margin" type="number"></b-form-input>
					</b-input-group>
					<span v-else>{{ data.item.formatted_margin }}</span>
				</div>
			</template>
			<template v-slot:cell(upper_limit)="data">
				<div class="d-flex upper_limit">
					<b-input-group
						:prepend="data.item.upper_limit_label"
						v-if="!data.item.formatted_upper_limit.includes('Sobre') && editingRow && editingRow.index === data.index"
					>
						<b-form-input
							v-model="data.item.upper_limit"
							:class="[data.item.upper_limit === 'Rango maximo' ? 'column-upper_limit_label' : '', 'column-upper_limit']"
							:disabled="data.item.upper_limit === 'Rango maximo' && editingRow && editingRow.index === data.index"
						></b-form-input>
					</b-input-group>
					<span v-else>{{ data.item.formatted_upper_limit }}</span>
				</div>
			</template>
			<template v-slot:cell(space)>
				<div class="space-additional-charges sticky-column-additional-charges"></div>
			</template>
			<template #head(carrier_name)>
				<span>Operador</span>
				<b-dropdown toggle-class="color-personalize-btn-filter" variant="link" no-caret v-if="!rowVersionId">
					<template #button-content>
						<feather-icon icon="FilterIcon" size="20" />
					</template>
					<b-dropdown-form>
						<b-form-checkbox-group
							id="checkbox-columns"
							v-model="computedSelectedCh"
							:options="optionsCheckbox"
							value-field="key"
							text-field="label"
							name="checkboxMargins"
							stacked
							checked
							class="dropdown-margins"
						>
						</b-form-checkbox-group>
					</b-dropdown-form>
				</b-dropdown>
			</template>
		</b-table>
		<div v-if="loading.margins">
			<b-skeleton height="40px" width="`calc(100vh - 2.5rem)`" class="mt-1 mb-1" />
			<div class="table-skeleton-tableQuoter">
				<b-skeleton-table :rows="5" :columns="6" :table-props="{ }" />
			</div>
		</div>
	</div>
</template>
<script>
import BaseServices from '@/store/services/index'
export default {
  props: ['rows', 'form', 'activeLoading', 'getList', 'selectedCheckbox', 'rowVersionId'],
  components: {  },
  data() {
    return {
      baseService: new BaseServices(this),
      schema: [],
      keyTableRender: 0,
      editingRow: null,
      removingRow: null,
      empty: '',
      loading: {
        margins: false,
        editMargin: false,
        deleteMargin: false
      },
      optionsCheckbox: [
        { label: 'País', key: 'country_flag', class: 'mb-2' },
        { label: 'Courier', key: 'carrier_flag', class: 'mb-2' },
        { label: 'Servicio', key: 'service_flag', class: 'mb-2' }
      ],
      showTableManageVersions: false,
      keyTableManageVersions: 0,
      selectedCheckboxOpt: this.selectedCheckbox
    }
  },
  mounted() {
    this.setInitialData()
  },
  computed: {
    computedSelectedCh: {
      get() {
        return this.selectedCheckbox
      },
      set(newValue) {
        this.$emit('update:selectedCheckbox', newValue)
        this.$emit('getList')
      }
    }
  },
  methods: {
    setInitialData() {
      this.setSchema()
    },
    setSchema() {
      this.schema = [
        {label: 'Operador', sortable: true, key: 'carrier_name', useSlot: true},
        {label: 'Servicio', sortable: true, key: 'service_name'},
        {label: 'Categoría', sortable: true, key: 'service_type_name'},
        {label: 'Rango de Kg/Km', sortable: true, key: 'upper_limit', useSlot: true},
        {label: 'Margen', sortable: true, key: 'margin', useSlot: true},
        {label: 'Acciones', sortable: true, key: 'actionsRows', useSlot: true, class: 'text-center'}
      ]
      this.empty = this.rows.length ? 'true' : ''
    },
    changeUpperLimitSelect(val, data) {
      if (val === 'Sobre') {
        data.item.upper_limit_original_value = data.item.upper_limit
        data.item.upper_limit = 'Rango maximo'
      } else {
        data.item.upper_limit = data.item.upper_limit_original_value
      }
    },
    editRow(data) {
      this.editingRow = data
    },
    cancelEdit() {
      this.editingRow = null
    },
    saveEdit(val) {
      this.putAdditionalCharges(val)
    },
    confirmDelete(val) {
      this.$yesno(this.$t('¿Estás seguro de eliminar este margen?'), () => this.deleteRow(val))
    },
    deleteRow(val) {
      this.removingRow = val
      const props = {
        data: {},
        titleService: 'deleteMargins',
        queryParams: {margin_id: val.item.id},
        titleSuccess: '¡Margen eliminado correctamente!',
        loading: 'deleteMargin'
      }
      this.callSevices(props)
    },
    putAdditionalCharges(val) {
      const props = {
        data: {
          upper_limit: val.item.upper_limit === 'Rango maximo' ? null : val.item.upper_limit,
          margin: val.item.margin
        },
        titleService: 'putMargins',
        queryParams: {margin_id: val.item.id},
        titleSuccess: '¡Cambio realizado con éxito!',
        loading: 'editMargin'
      }
      this.callSevices(props)
    },
    openModal(modal) {
      if (modal === 'ModalManageVersionsMargins') {
        this.showTable(true)
        this.keyTableManageVersions++
      }
    },
    showTable(val) {
      this.showTableManageVersions = val
    },
    callSevices(props) {
      this.loading[props.loading] = true
      this.baseService.callService(props.titleService, props.data, props.queryParams)
        .then((resp) => {
          this.$newSuccess(resp.message || this.$t(props.titleSuccess))
          this.$emit('getList')
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err.message}))
        })
        .finally(() => {
          this.loading[props.loading] = false
          this.cancelEdit()
          this.removingRow = null
        })
    }
  }
}
</script>
<style>
.upper_limit{
  width: max-content;
}
.column-upper_limit {
  min-width: 70px !important;
  max-width: min-content;
}
.column-upper_limit_label {
  max-width: 140px;
}
.column-select-upper_limit {
  min-width: 60px;
  max-width: 90px;
}
.column-margins {
  min-width: 70px !important;
  max-width: 100px;
}
.icon-upper-limit {
  align-content: center;
  margin-left: 4px;
}
.dropdown-margins > div{
  margin-bottom: 1rem;
}
</style>
